import {
  Button,
  Container,
  FormGroup,
  FormLabel,
  Grid,
  TextField,
} from "@mui/material";
import React, { useState } from "react";
import "./ContactPage.css";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import CallIcon from "@mui/icons-material/Call";
import { LoadingButton } from "@mui/lab";
import { TextareaAutosize } from "@mui/base";
import validator from "validator";
import { CONTACT_SEND } from "../../../utils/api-constant";
import axios from "axios";
import Alert from "../../Alert/Alert";
import GoogleMapReact from "google-map-react";
import RefreshIcon from "@mui/icons-material/Refresh";
import marker from "../../../assets/Img/gmark.png";
import { useTranslation } from "react-i18next";

const AnyReactComponent = ({ text }) => (
  <div>
    <img src={text} alt="marker" />
  </div>
);
const ContactPage = () => {
  const { t } = useTranslation();
  const defaultProps = {
    center: {
      lat: -13.973739761101772,
      lng: 33.77116596826574,
    },
    zoom: 5,
  };
  const exceptThisSymbols = ["e", "E", "+", "-", "."];
  const [btnLoading, setBtnloading] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [alert, setAlert] = useState({
    msg: "",
    type: false,
  });
  const [email, setEmail] = useState({
    value: "",
    error: false,
    isNotEmail: false,
  });
  const [name, setName] = useState({
    value: "",
    error: false,
  });
  const [mobile, setMobile] = useState({
    value: "",
    error: false,
  });
  const [message, setMessage] = useState({
    value: "",
    error: false,
  });
  const [captcha, setCaptcha] = useState({
    value: "",
    error: false,
    isCorrect: false,
  });
  const rendomString = Math.random().toString(36).slice(8);
  const [CaptchaText, setCaptchaText] = useState(rendomString);
  const refreshString = () => {
    setCaptchaText(Math.random().toString(36).slice(8));
    setCaptcha({
      value: "",
      error: false,
      isCorrect: false,
    });
  };

  const handleCaptchaChange = (value) => {
    if (value) {
      if (value === CaptchaText) {
        setCaptcha((prev) => ({
          ...prev,
          value,
          error: false,
          isCorrect: false,
        }));
      } else {
        setCaptcha((prev) => ({
          ...prev,
          value,
          error: false,
          isCorrect: true,
        }));
      }
    } else {
      setCaptcha((prev) => ({
        ...prev,
        value,
        error: true,
        isCorrect: false,
      }));
    }
  };
  const handleEmailChange = (value) => {
    setEmail((prev) => ({
      ...prev,
      value,
    }));
    if (value) {
      if (validator.isEmail(value)) {
        setEmail((prev) => ({
          ...prev,
          value,
          error: false,
          isNotEmail: false,
        }));
      } else {
        setEmail((prev) => ({
          ...prev,
          value,
          error: false,
          isNotEmail: true,
        }));
      }
    } else {
      setEmail((prev) => ({
        ...prev,
        value,
        error: true,
        isNotEmail: false,
      }));
    }
  };
  const handleNameChange = (value) => {
    if (value) {
      setName({
        value,
        error: false,
      });
    } else {
      setName({
        value,
        error: true,
      });
    }
  };
  const handleMobileChange = (value) => {
    if (value) {
      setMobile({
        value,
        error: false,
      });
    } else {
      setMobile({
        value,
        error: true,
      });
    }
  };
  const handleMessageChange = (value) => {
    if (value) {
      setMessage({
        value,
        error: false,
      });
    } else {
      setMessage({
        value,
        error: true,
      });
    }
  };
  const resetInput = () => {
    setEmail({
      value: "",
      error: false,
      isNotEmail: false,
    });
    setMessage({
      value: "",
      error: false,
    });
    setMobile({
      value: "",
      error: false,
    });
    setName({
      value: "",
      error: false,
    });
    setCaptcha({
      value: "",
      error: false,
      isCorrect: false,
    });
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    setBtnloading(true);
    let valid = true;

    if (!email.value) {
      setEmail((prev) => ({
        ...prev,
        error: true,
      }));
      valid = false;
    } else if (!validator.isEmail(email.value)) {
      setEmail((prev) => ({
        ...prev,
        isNotEmail: true,
      }));
      valid = false;
    }

    if (!captcha.value) {
      setCaptcha((prev) => ({
        ...prev,
        error: true,
      }));
      valid = false;
    } else if (captcha.value !== CaptchaText) {
      setCaptcha((prev) => ({
        ...prev,
        isCorrect: true,
      }));
      valid = false;
    }

    if (!name.value) {
      setName((prev) => ({
        ...prev,
        error: true,
      }));
    }

    if (!message.value) {
      setMessage((prev) => ({
        ...prev,
        error: true,
      }));
    }

    if (!mobile.value) {
      setMobile((prev) => ({
        ...prev,
        error: true,
      }));
    }

    if (valid) {
      const token = JSON.parse(localStorage.getItem("apiToken"));
      const params = {
        headers: {
          "Content-Type": "application/json",
          needJson: 1,
          apiToken: token,
        },
      };
      await axios
        .post(
          process.env.REACT_APP_HOST_URL + CONTACT_SEND,
          {
            email: email.value,
            name: name.value,
            phone: mobile.value,
            message: message.value,
          },
          params
        )
        .then(function (response) {
          setAlert({
            msg: response.data.message,
            type: response.data.success,
          });

          setShowAlert(true);
          resetInput();
        })
        .catch(function (error) {
          setAlert({
            msg: error.message,
            type: false,
          });
          resetInput();
        });
    }
    setBtnloading(false);
  }; 

  document.body.onkeydown = function (e) {
    if (e.key === "Enter") {
      handleSubmit(e);
    }
  };
  return (
    <div className="contact-page">
      <div className="service-header">
        <Container>
          <div className="service-header-inner">
            <div className="left-div">
              <h6>{t("contact title")}</h6>
              <div className="desc">
               {t("contact description")}
              </div>
            </div>
          </div>
        </Container>
      </div>
      <section className="Services-Section viewallList">
        <Container>
          <div className="service-page-detail border-box overflow-hidden">
            <Grid container spacing={{ sm: 0, md: 3 }}>
              <Grid item sm={12} md={5}>
                <div className="left-contact">
                  <div className="adr-box">
                    <div className="icon">
                      <LocationOnIcon />
                    </div>
                    <div className="text">
                      {t("ADDRESS")} <br />
                      {t("footer addr")}
                    </div>
                  </div>
                  <div className="adr-box">
                    <div className="icon">
                      <CallIcon />
                    </div>
                    <div className="text">
                      {t("CONTACT US")} <br />
                      {t("footer contact")} <br />
                      {t("Email")}
                    </div>
                  </div>
                  <div className="adr-box notes">
                    <div className="text">
                      {t("Contact Note")}
                    </div>
                  </div>
                </div>
              </Grid>
              <Grid item sm={12} md={7}>
                <div className="account-formSection">
                  <Alert
                    setShow={setShowAlert}
                    show={showAlert}
                    msg={alert.msg}
                    type={alert.msg}
                  />
                  <form method="post" name="contactus">
                    <h3 className="head-h3">{t("contact title")}</h3>
                    <Grid container columnSpacing={{ xs: 0, sm: 4 }}>
                      <Grid item xs={12} sm={6} md={6}>
                        <FormGroup className="form-group">
                          <FormLabel required>{t("name label")}</FormLabel>
                          <TextField
                            className="form-control"
                            type="text"
                            size="small"
                            value={name.value}
                            onChange={(e) => handleNameChange(e.target.value)}
                          />
                          {name.error && (
                            <div className="errorMsg">{t("required alert")}</div>
                          )}
                        </FormGroup>
                      </Grid>
                      <Grid item xs={12} sm={6} md={6}>
                        <FormGroup className="form-group">
                          <FormLabel required>{t("email label")}</FormLabel>
                          <TextField
                            className="form-control"
                            type="text"
                            size="small"
                            value={email.value}
                            onChange={(e) => handleEmailChange(e.target.value)}
                          />
                          {email.error && (
                            <div className="errorMsg">{t("required alert")}</div>
                          )}
                          {email.isNotEmail && (
                            <div className="errorMsg">
                             {t("email valid alert")}
                            </div>
                          )}
                        </FormGroup>
                      </Grid>
                      <Grid item xs={12} sm={6} md={6}>
                        <FormGroup className="form-group">
                          <FormLabel required>{t("mobile no label")}</FormLabel>
                          <TextField
                            className="form-control"
                            type="number"
                            size="small"
                            onKeyDown={(e) =>
                              exceptThisSymbols.includes(e.key) &&
                              e.preventDefault()
                            }
                            value={mobile.value}
                            onChange={(e) => handleMobileChange(e.target.value)}
                          />
                          {mobile.error && (
                            <div className="errorMsg">{t("required alert")}</div>
                          )}
                        </FormGroup>
                      </Grid>
                      <Grid item xs={12} sm={6} md={6}>
                        <FormGroup className="form-group">
                          <FormLabel required>{t("message label")}</FormLabel>
                          <TextareaAutosize
                            className="form-control"
                            aria-label="minimum height"
                            minRows={4}
                            size="small"
                            value={message.value}
                            onChange={(e) =>
                              handleMessageChange(e.target.value)
                            }
                          />
                          {message.error && (
                            <div className="errorMsg">{t("required alert")}</div>
                          )}
                        </FormGroup>
                      </Grid>
                      <Grid item xs={12} sm={6} md={6}>
                        <FormGroup className="form-group">
                          <div className="Captcha">
                            <span>{CaptchaText}</span>
                            <RefreshIcon onClick={refreshString} />
                          </div>
                          <FormLabel required>{t("captcha label")}</FormLabel>
                          <TextField
                            className="form-control"
                            type="text"
                            size="small"
                            value={captcha.value}
                            onChange={(e) =>
                              handleCaptchaChange(e.target.value)
                            }
                          />
                          {captcha.error && (
                            <div className="errorMsg">{t("required alert")}</div>
                          )}
                          {captcha.isCorrect && (
                            <div className="errorMsg">{t("valid captcha alert")}</div>
                          )}
                        </FormGroup>
                      </Grid>

                      <div className="btn-group">
                        <Button className="btn btn-cancel" onClick={resetInput}>
                          {t("cancel text")}
                        </Button>
                        <LoadingButton
                          className="btn btn-submit"
                          loadingIndicator="Loading…"
                          onClick={handleSubmit}
                          loading={btnLoading}
                        >
                          <span>{t("submit small text")}</span>
                        </LoadingButton>
                      </div>
                    </Grid>
                  </form>
                </div>
              </Grid>
            </Grid>
          </div>
        </Container>
      </section>
      <div style={{ height: "50vh", width: "100%" }}>
        <GoogleMapReact
          bootstrapURLKeys={{ key: process.env.REACT_APP_MAP_KEY }}
          defaultCenter={defaultProps.center}
          defaultZoom={defaultProps.zoom}
        >
          <AnyReactComponent lat={-13.973739761101772} lng={33.77116596826574} text={marker} />
        </GoogleMapReact>
      </div>
    </div>
  );
};

export default ContactPage;
