import React from "react";
import { Container } from "@mui/material";
import { CATEGORY_LISTALL } from "../../utils/api-constant";
import "./ServicesList.css";
import useApiFetch from "../../hooks/useApiFetch";
import ServicesBoxGrid from "../Home/ServicesBoxGrid/ServicesBoxGrid";
import LoadingPage from "../SeviceScreen/LoadingPage";
import { useTranslation } from "react-i18next";
import ErrorSection from "../Error/ErrorSection";
function ServicesList() {
  const { t } = useTranslation();
  const { data, loading, error } = useApiFetch(
    process.env.REACT_APP_HOST_URL + CATEGORY_LISTALL
  );
  if (error) return <ErrorSection />;
  if (loading) return <LoadingPage />;
  const service = data.list;

  return (
    <div>
      <div className="service-header">
        <Container>
          <div className="service-header-inner">
            <div className="left-div">
              <h6>{t("service category title")}</h6>
              <span className="desc">
                {t("frequently used sercive description")}
              </span>
            </div>
          </div>
        </Container>
      </div>
      <section className="Services-Section viewallList">
        <Container>
          <div className="service-page-detail">
            <ServicesBoxGrid
              setService={service}
              type="service"
              detail={true}
            />
          </div>
        </Container>
      </section>
    </div>
  );
}

export default ServicesList;
