export const replaceSpaceWithDash = (value) => {
  return value.replace(/\s+/g, "-").toLowerCase();
};
export function isEmail(value) {
  return String(value)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
}

export const replaceDashWithSpace = (value) => {
  return value.replace("-", " ").toLowerCase();
};

export const isNin = (value) => {
  return value.length === 9;
};

export const isMobile = () => {
  const userAgent = navigator.userAgent || navigator.vendor || window.opera;

  // Detect mobile devices
  if (/android/i.test(userAgent)) {
    return true;
  }

  if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
    return true;
  }

  return false;
};
