import {
  Container,
  FormGroup,
  FormLabel,
  InputAdornment,
  TextField,
} from "@mui/material";
import React from "react";
import useApiFetch from "../../hooks/useApiFetch";
import { SERVICE_LISTALL } from "../../utils/api-constant";
import ServicesBoxGrid from "../Home/ServicesBoxGrid/ServicesBoxGrid";
import LoadingPage from "../SeviceScreen/LoadingPage";
import SearchIcon from "@mui/icons-material/Search";
import "./Search.css";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import ErrorSection from "../Error/ErrorSection";

const SearchPage = () => {
  const { t } = useTranslation();
  const [searchValue, setSearchValue] = useState("");
  const { data, loading, error } = useApiFetch(
    process.env.REACT_APP_HOST_URL + SERVICE_LISTALL
  );
  if (error) return <ErrorSection />;
  if (loading) return <LoadingPage />;

  let listOfServices = data.list.map((item) => item.name);

  listOfServices = listOfServices
    .sort() // sort and remove duplicates
    .filter((v, i) => listOfServices.indexOf(v) === i);

  const service = data.list.filter((value) =>
    value.name.toLowerCase().includes(searchValue.toLowerCase())
  );
  return (
    <div className="search-page">
      <div className="service-header">
        <Container>
          <div className="service-header-inner">
            <div className="left-div">
              <h6>{t("search ttl")}</h6>
            </div>
          </div>
        </Container>
      </div>
      <section className="Services-Section viewallList">
        <Container>
          <div className="service-page-detail">
            <FormGroup className="form-group">
              <FormLabel>{t("Search your favorite services")}</FormLabel>
              <TextField
                className="form-control"
                type="text"
                size="small"
                placeholder={t("search service placeholder")}
                value={searchValue}
                onChange={(e) => setSearchValue(e.target.value)}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
              />
            </FormGroup>
            <ServicesBoxGrid
              setService={service}
              type="service"
              detail={false}
              search={true}
            />
          </div>
        </Container>
      </section>
    </div>
  );
};

export default SearchPage;
