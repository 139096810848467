import React from "react";
import "./Helpcenter.css";
import { Container } from "@mui/material";
import useApiFetch from "../../../hooks/useApiFetch";
import LoadingPage from "../../SeviceScreen/LoadingPage";
import { STATIC_HELP_CENTER } from "../../../utils/api-constant";

function Helpcenter() {
  const { data, loading, error } = useApiFetch(
    process.env.REACT_APP_HOST_URL + STATIC_HELP_CENTER
  );
  if (loading) return <LoadingPage />;
  if (error) return console.log(error);
  return (
    <>
      {data.list.length > 0 && (
        <section className="HelpBannerSection">
          <Container maxWidth="lg">
            <div
              className="para"
              dangerouslySetInnerHTML={{ __html: data.list[0]?.content }}
            ></div>
          </Container>
        </section>
      )}
    </>
  );
}

export default Helpcenter;
