import React, { useEffect, useState } from "react";
import axios from "axios";
import { CMS_LISTALL } from "../../../utils/api-constant";
import { useLocation } from "react-router-dom";
import ContactPage from "./ContactPage";
function CmsPage() {
  const [title, setTitle] = useState();
  const [cms, setData] = useState("");
  const location = useLocation();
  useEffect(() => {
    const currentUrl = window.location.hash.split("/");
    const page_title = currentUrl[2];
    setTitle(page_title);
  }, [location]);

  useEffect(() => {
    getCmsList();
  }, [title]);

  function getCmsList() {
    const params = {
      headers: {
        needJson: 1,
        "Content-Type": "application/json",
      },
    };
    if (title) {
      axios
        .get(
          process.env.REACT_APP_HOST_URL + CMS_LISTALL + `?page_title=${title}`,
          params
        )
        .then((response) => {
          if (response.data.list.length > 0){
            setData(response.data.list[0].content);
          }
        });
    }
  }

  return (
    <div className="cms">
      {title === "contactus" ? (
        <ContactPage />
      ) : (
        <div dangerouslySetInnerHTML={{ __html: cms }}></div>
      )}
    </div>
  );
}

export default CmsPage;
