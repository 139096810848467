import React, { useEffect, useRef } from "react";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import FormLoader from "../../Loader/FormLoader";
import useApiFetch from "../../../hooks/useApiFetch";
import "./NotificationList.css";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { useState } from "react";
import axios from "axios";
import { NOTIFY_DETAIL, NOTIFY_LIST } from "../../../utils/api-constant";
import { useContext } from "react";
import AuthContext from "../../../context/AuthContext";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { isMobile } from "../../../utils/custom";

const NotificationList = () => {
  const { t } = useTranslation();
  let apiToken = JSON.parse(localStorage.getItem("apiToken"));
  const authCtx = useContext(AuthContext);
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [popupText, setPopupText] = useState({});
  const descriptionElementRef = useRef(null);
  const [scroll, setScroll] = useState("paper");
  const itemRef = useRef(null);

  useEffect(() => {
    if (!authCtx.isLogin) {
      navigate("/");
    }
    //console.log(isMobile());
    if (isMobile()) {
      if (itemRef.current) {
        itemRef.current.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }, [authCtx.isLogin]);

  const { data, loading, error } = useApiFetch(
    process.env.REACT_APP_HOST_URL + NOTIFY_LIST
  );
  if (error) {
    return false;
  }
  const handleClickOpen = async (scrollType, id) => {
    const params = {
      headers: {
        needJson: 1,
        "Content-Type": "application/json",
        apiToken,
      },
    };
    await axios
      .get(process.env.REACT_APP_HOST_URL + NOTIFY_DETAIL + id, params)
      .then((response) => {
        if (response.data.success) {
          setPopupText(response.data);
          setOpen(true);
          setScroll(scrollType);
        }
      });
  };
  const formetDateTime = (value) => {
    var date = new Date(value);
    const yyyy = date.getFullYear();
    let mm = date.getMonth() + 1;
    let dd = date.getDate();
    let hr = date.getHours();
    let min = date.getMinutes();
    var ampm = "AM";

    if (hr >= 12) {
      ampm = "PM";
    }

    if (hr > 12) {
      hr = hr - 12;
    }

    if (dd < 10) dd = "0" + dd;
    if (mm < 10) mm = "0" + mm;
    const formattedToday =
      dd + "-" + mm + "-" + yyyy + " " + hr + ":" + min + " " + ampm;
    return formattedToday;
  };
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return new Intl.DateTimeFormat('en-GB').format(date); // Formats date to dd/mm/yyyy
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      {loading ? (
        <div ref={itemRef} className="account-formSection notify-list-page form-loading">
          <FormLoader />
        </div>
      ) : (
          <div ref={itemRef} className="account-formSection notify-list-page">
          <h3 className="head-h3">{t("my notification text")}</h3>
          <div className="notify-detail">
            {data.list.map((item) => (
              <div
                className="notify-list"
                key={item.id}
                onClick={() => handleClickOpen("paper", item.id)}
                style={item.read_or_not === 0 ? { backgroundColor: '#ecf8fe', fontWeight:'500' } : {}}
              >
                <div className="date" style={item.read_or_not === 0 ? {  fontWeight:'500', color:'#000' } : {}}>
                  <AccessTimeIcon className="clock-icon" /> {formetDateTime(item.created_at)}
                </div>
                <div className="desc">{item.notification_message}</div>
              </div>
            ))}
            {data.list.length === 0 && (
              <div className="no-data-found">{t("no data text")}</div>
            )}
          </div>
        </div>
      )}
      {open && (
        <Dialog
          open={open}
          onClose={handleClose}
          scroll={scroll}
          aria-labelledby="scroll-dialog-title"
          aria-describedby="scroll-dialog-description"
          className="notify-popup"
        >
          <DialogTitle id="scroll-dialog-title" className="head">
            {t("notification detail text")}
          </DialogTitle>
          <DialogContent dividers={scroll === "paper"}>
            <DialogContentText
              id="scroll-dialog-description"
              ref={descriptionElementRef}
              tabIndex={-1}
            >
              <div className="date">
                <AccessTimeIcon className="clock-icon" />{formatDate(popupText.data.date)}
              </div>
              <div className="desc">{popupText.data.notification_message}</div>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} className="notify-close">
              {t("close text")}
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </>
  );
};

export default NotificationList;
