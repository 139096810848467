import React from "react";
import checkImg from "../../assets/Img/check.png";
import "./Popup.css";
import CloseIcon from "@mui/icons-material/Close";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

const Popup = ({ children, sucsess }) => {

  const { t } = useTranslation();
  const navigate = useNavigate();
  return (
    <div className="custom-popup">
      <div className="popup-overlay"></div>
      <div className="popup-outer">
        <div className="popup-inner">
          <div className="popup-header">
            {sucsess ? (
              <>
                <div className="img-box">
                  <img src={checkImg} alt="img" />
                </div>
                <span className="success">{t("Success")}</span>
              </>
            ) : (
              <>
                <div className="img-box">
                  <CloseIcon className="close-icon" />
                </div>
                  <span className="error">{t("error text")}</span>
              </>
            )}
          </div>
          <div className="popup-body">{children}</div>
          <div className="popup-footer">
            <button onClick={() => navigate("/")}>{t("Go Home")}</button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Popup;
