import { Container, Grid, TextField, Button } from "@mui/material";
import { useNavigate } from "react-router-dom";

import validator from "validator";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import React, { useEffect, useState } from "react";
import axios from "axios";
import { useLocation } from "react-router-dom";
import FormGroup from "@mui/material/FormGroup";
import FormLabel from "@mui/material/FormLabel";
import LoadingButton from "@mui/lab/LoadingButton";
import {
  GET_RESETPASSWORD_EMAIL,
  UPDATE_RESETPASSWORD,
} from "../../../utils/api-constant";
import "./Resetpassword.css";
import Alert from "../../Alert/Alert";
import { useTranslation } from "react-i18next";

const ResetPassword = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [btnLoading, setBtnLoading] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [confirmPasswordError, setConfirmPasswordError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [apiToken, setApiToken] = useState("");
  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleMouseDownPassword = () => setShowPassword(!showPassword);
  const handleClickShowConfirmPassword = () =>
    setShowConfirmPassword(!showConfirmPassword);
  const handleMouseDownConfirmPassword = () =>
    setShowConfirmPassword(!showConfirmPassword);
  const [showAlert, setShowAlert] = useState(false);
  const [alertType, setAlertType] = useState(false);
  const [response, setResponse] = useState("");
  const location = useLocation();

  const validateEmail = (e) => {
    var email = e.target.value;
    setEmail(email);
    if (email !== "") {
      if (validator.isEmail(email)) {
        setEmailError("");
      } else {
        setEmailError(t("email valid alert"));
      }
    } else {
      setEmailError(t("required alert"));
    }
  };

  const validatePassword = (e) => {
    var passwordVal = e.target.value;
    setPassword(passwordVal);
    if (passwordVal !== "") {
      if (validator.isStrongPassword(passwordVal)) {
        setPasswordError("");
      } else {
        setPasswordError(t("valid password alert"));
      }
    } else {
      setPasswordError(t("required alert"));
    }
  };

  const validateConfirmPassword = (e) => {
    var confirmPasswordVal = e.target.value;
    setConfirmPassword(confirmPasswordVal);
    if (confirmPasswordVal !== "") {
      setConfirmPasswordError("");
    } else {
      setConfirmPasswordError(t("required alert"));
    }
  };

  const handleResetPassword = (e) => {
    let formIsValid = true;
    if (!email) {
      formIsValid = false;
      setEmailError(t("required alert"));
    } else if (!validator.isEmail(email)) {
      formIsValid = false;
      setEmailError(t("email valid alert"));
    } else {
      setEmailError("");
    }
    if (!password) {
      formIsValid = false;
      setPasswordError(t("required alert"));
    } else if (!validator.isStrongPassword(password)) {
      formIsValid = false;
      setPasswordError(t("valid password alert"));
    } else {
      setPasswordError("");
    }
    if (!confirmPassword) {
      formIsValid = false;
      setConfirmPasswordError(t("required alert"));
    } else if (password !== confirmPassword) {
      formIsValid = false;
      setConfirmPasswordError(t("valid confirm password"));
    } else {
      setConfirmPasswordError("");
    }
    if (formIsValid) {
      handleChangePassword(formIsValid);
      // setTimeout(() => {
      //   window.location.href = '/';
      // }, 1000);
    } else {
      setConfirmPasswordError(t("password match alert"));
    }
  };
  const handleremove = (e) => {
    setPasswordError("");
    setConfirmPasswordError("");
    setPassword("");
    setConfirmPassword("");
  };

  const urlParts = window.location.hash.split("/");
  const otpValue = urlParts.pop();
  //const otpValue = 'bjIzxcTe';

  if (!otpValue) {
    console.error("OTP value not found in the URL path");
  }

  //const dynamicApiEndpoint = `http://eservice/getDetailsBasedCode/${otpValue}`;

  function handleChangePassword(formIsValid) {
    const headers = {
      needJson: 1,
      apiToken: apiToken,
      "Content-Type": "application/json",
    };
    if (formIsValid) {
      setBtnLoading(true);
      axios
        .post(
          process.env.REACT_APP_HOST_URL +
            UPDATE_RESETPASSWORD +
            "/" +
            otpValue,
          {
            password: password,
            cpassword: confirmPassword,
          },
          {
            headers: headers,
          }
        )
        .then((response) => {
          setAlertType(response.data.success);
          setResponse(response.data.message);
          setShowAlert(true);
          setBtnLoading(false);
          if (response.data.success) {
            setPassword("");
            setConfirmPassword("");
            setTimeout(() => {
              window.location.href = "/";
            }, 1000);
          }
        });
    }
  } 

  document.body.onkeydown = function (e) {
    if (e.key === "Enter") {
      handleResetPassword(e);
    }
  };

  useEffect(() => {
    const headers = {
      needJson: 1,
      "Content-Type": "application/json",
    };
    axios
      .post(
        process.env.REACT_APP_HOST_URL +
          GET_RESETPASSWORD_EMAIL +
          "/" +
          otpValue,
        headers
      )
      .then((response) => {
        setEmail(response.data.userDetail.email);
      })
      .catch((error) => {
        if (error.response) {
          console.error("Response data:", error.response.data);
          console.error("Status code:", error.response.status);
        } else if (error.request) {
          console.error("No response received:", error.request);
        } else {
          console.error("Error:", error.message);
        }
      });
  }, []);

  return (
    <div className="outer-common-ui login-center-box">
      <Container>
        <section className="ResetUI outer-inner login-box">
          <Alert
            setShow={setShowAlert}
            show={showAlert}
            msg={response}
            type={alertType}
          />
          <Grid>
            <Grid item xs={12}>
              {/* Right side with email, password, and confirm password inputs */}
              <div className="formSection">
                <h3 className="head-h3">{t("reset password title")}</h3>

                <form method="post" name="resetPasswordForm">
                  <FormGroup className="form-group">
                    <FormLabel required>{t("email label")}</FormLabel>
                    <TextField
                      className="form-control"
                      type="text"
                      size="small"
                      value={email}
                      inputProps={{ readOnly: true }}
                      onChange={(e) => validateEmail(e)}
                    />
                    {emailError && (
                      <div className="error-message">{emailError}</div>
                    )}
                  </FormGroup>
                  <FormGroup className="form-group">
                    <FormLabel required>{t("password label")}</FormLabel>
                    <TextField
                      className="form-control"
                      size="small"
                      type={showPassword ? "text" : "password"}
                      value={password}
                      onChange={(e) => validatePassword(e)}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              className="eye-icon"
                              aria-label="toggle password visibility"
                              onClick={handleClickShowPassword}
                              onMouseDown={handleMouseDownPassword}
                              edge="end"
                            >
                              {showPassword ? (
                                <Visibility />
                              ) : (
                                <VisibilityOff />
                              )}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                    {passwordError && (
                      <div className="error-message">{passwordError}</div>
                    )}
                  </FormGroup>
                  <FormGroup className="form-group">
                    <FormLabel required>
                      {t("confirm password label")}
                    </FormLabel>
                    <TextField
                      className="form-control"
                      size="small"
                      type={showConfirmPassword ? "text" : "password"}
                      value={confirmPassword}
                      onChange={(e) => validateConfirmPassword(e)}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              className="eye-icon"
                              aria-label="toggle password visibility"
                              onClick={handleClickShowConfirmPassword}
                              onMouseDown={handleMouseDownConfirmPassword}
                              edge="end"
                            >
                              {showConfirmPassword ? (
                                <Visibility />
                              ) : (
                                <VisibilityOff />
                              )}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                    {confirmPasswordError && (
                      <div className="error-message">
                        {confirmPasswordError}
                      </div>
                    )}
                  </FormGroup>
                  <div className="form-group reset-group">
                    <LoadingButton
                      className="btn btn-primary"
                      onClick={handleResetPassword}
                      loading={btnLoading}
                      loadingIndicator="Loading…"
                    >
                      <span>{t("submit text")}</span>
                    </LoadingButton>
                    <LoadingButton
                      className="btn btn-primary1"
                      onClick={handleremove}
                      loading={btnLoading}
                      loadingIndicator="Loading…"
                    >
                      <span>{t("cancel text")}</span>
                    </LoadingButton>
                  </div>

                  <p className="reg-text">
                    {t("back to login text")}{" "}
                    <span onClick={() => navigate("/login")}>
                      {t("click here text")}
                    </span>
                  </p>
                </form>
              </div>
            </Grid>
          </Grid>
        </section>
      </Container>
    </div>
  );
};

export default ResetPassword;
