import React from "react";
import { Container } from "@mui/material";
// import {Link} from 'react-router-dom';
import { MDA_LISTALL } from "../../utils/api-constant";
import "../Home/ServiceSchemeMda/ServiceSchemeMda.css";
import LoadingPage from "../SeviceScreen/LoadingPage";
import useApiFetch from "../../hooks/useApiFetch";
import SchemeMdaBoxGrid from "../Home/SchemeMdaBoxGrid/SchemeMdaBoxGrid";
import "./SchemeList.css";
import { useTranslation } from "react-i18next";
import ErrorSection from "../Error/ErrorSection";

function ViewAllServiceSchemeMda() {
  const { t } = useTranslation();
  const { data, loading, error } = useApiFetch(
    process.env.REACT_APP_HOST_URL + MDA_LISTALL
  );
  if (error) return <ErrorSection />;
  if (loading) return <LoadingPage />;
  const mda = data;

  return (
    <div>
      <div className="service-header">
        <Container>
          <div className="service-header-inner">
            <div className="left-div">
              <h6>{t("mdas title")}</h6>
              <span className="desc">{t("mdas description")}</span>
            </div>
          </div>
        </Container>
      </div>
      <section className="Services-Scheme-Section viewallList">
        <Container>
          <div className="service-page-detail">
            <SchemeMdaBoxGrid boxGridApi={mda} type="mda" detail={true} />
          </div>
        </Container>
      </section>
    </div>
  );
}

export default ViewAllServiceSchemeMda;
