import { Button, Container } from "@mui/material";
import React from "react";
import GppMaybeIcon from "@mui/icons-material/GppMaybe";
import "./Error.css";
import { useTranslation } from "react-i18next";

const ErrorSection = () => {
  const {t} = useTranslation()
  return (
    <>
      <div className="service-header">
        <Container>
          <div className="service-header-inner"></div>
        </Container>
      </div>
      <Container>
        <div className="service-page-detail error-section">
          <GppMaybeIcon className="error-icon" />
          <h2>{t("error text")}</h2>
          <p> {t("somethig wrong text")} </p>
          <div>
            <Button
              className="btn btn-reload"
              onClick={() => window.location.reload()}
            >
              {t("reload text")}
            </Button>
          </div>
        </div>
      </Container>
    </>
  );
};

export default ErrorSection;
