import React from "react";
import NotificationsNoneIcon from "@mui/icons-material/NotificationsNone";
import { useNavigate } from "react-router-dom";
import useApiFetch from "../../hooks/useApiFetch";
import { NOTIFY_COUNT } from "../../utils/api-constant";

const NotificationIcon = () => {
  const navigate = useNavigate();
  const { data, loading, error } = useApiFetch(
    process.env.REACT_APP_HOST_URL + NOTIFY_COUNT
  );
  if (error) {
    console.log(error);
    return false;
  }
  return (
    <div
      className="head-notify"
      onClick={() => navigate("/my-account/my-notification")}
    >
      <NotificationsNoneIcon className="notify-icon" />
      {!loading && (
        <span className="notify-count-outer" style={data.notitifcation_count === 0 ? {display: "none"} : {}}>
          <span className="notify-count">{data.notitifcation_count}</span>
        </span>
      )}
    </div>
  );
};

export default NotificationIcon;
