import React, { useState, useEffect } from "react";
import axios from "axios";
import { REGISTER_OTP_URL, REGISTER_URL } from '../../../utils/api-constant';
import { Box, Container } from '@mui/material'
import { useNavigate } from "react-router-dom";
import Grid from "@mui/material/Grid";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import VisibilityOutlined from "@mui/icons-material/VisibilityOutlined";
import VisibilityOffOutlined from "@mui/icons-material/VisibilityOffOutlined";
import EventIcon from '@mui/icons-material/Event';
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import validator from "validator";
import close from '../../../assets/Img/close.png'
import africa from '../../../assets/Img/africa.png'
import ethiopia from '../../../assets/Img/ethiopia.png'
import uganda from '../../../assets/Img/uganda.png'
import right from '../../../assets/Img/right-arrow1.png'
// import leftarrow from '../../../assets/Img/arrow-left.svg'
import { countries } from "../../../Files/Country";
import PropTypes from 'prop-types';
import LoadingButton from '@mui/lab/LoadingButton';


import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Contactform from '../Form/contact'
import { MultiSelect } from "react-multi-select-component";
import Chip from "@mui/material/Chip";
import Vax from "../Form/vax";
import './Travelform.css'
import { useTranslation } from "react-i18next";

const CustomTabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          {children}
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const Travelform = () => {

  const { t } = useTranslation();
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };


  const handleTabChange = (newValue) => {
    setValue(newValue);
  };
  const [btnLoading, setBtnLoading] = useState(false);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");

  const [countryName, setCountryName] = useState("");
  const [countryList, setCountryList] = useState([]);
  const [ninNumber, setNinNumber] = useState("");
  const [passportNumber, setPassportNumber] = useState("");
  const [mobileNumber, setMobileNumber] = useState("");
  const [dob, setDob] = useState(null);

  const [eMail, setEMail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);

  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const [gender, setGender] = useState('');
  const [firstNameErr, setFirstNameErr] = useState("");
  const [lastNameErr, setLastNameErr] = useState("");
  const [mobileNumberErr, setMobileNumberErr] = useState("");
  const [dobErr, setDobErr] = useState("");
  const [genderErr, setGenderErr] = useState("");
  const [countryNameErr, setCountryNameErr] = useState("");
  const [ninNumberErr, setNinNumberErr] = useState("");
  const [passportNumberErr, setPassportNumberErr] = useState("");

  const [eMailErr, setEMailErr] = useState("");
  const [passwordErr, setPasswordErr] = useState("");
  const [confirmPasswordErr, setConfirmPasswordErr] = useState("");

  const [nationality, setNationality] = useState("");
  const [nationalityErr, setNationalityErr] = useState("");

  const [seatNumber, setSeatNumber] = useState("");
  const [airlines, setAirlines] = useState("");
  const [flightNumber, setFlightNumber] = useState("");
  const [transportMode, setTransportMode] = useState("");
  const [mwArrivalDate, setMwArrivalDate] = useState(null);
  const [seatNumberErr, setSeatNumberErr] = useState("");
  const [airlinesErr, setAirlinesErr] = useState("");
  const [flightNumberErr, setFlightNumberErr] = useState("");
  const [transportModeErr, setTransportModeErr] = useState("");
  const [mwArrivalDateErr, setMwArrivalDateErr] = useState("");
  const [selectedCountries, setSelectedCountries] = useState([]);
  const [ErrorForMultiSelect, setErrorForMultiSelect] = useState([]);

  const options = [
    { value: "uganda", label: "Uganda", iconCss: "uganda-icon" },
    { value: "africa", label: "Africa", iconCss: "africa-icon" },
    { value: "ethiopia", label: "Ethiopia", iconCss: "ethiopia-icon" },

  ];



  function validateForm() {
    let formIsValid = true;

    console.log("firstNameErr:", firstNameErr);
    console.log("lastNameErr:", lastNameErr);
    console.log("countryNameErr:", countryNameErr);
    console.log("nationalityErr:", nationalityErr);
    console.log("passportNumberErr:", passportNumberErr);
    console.log("mobileNumberErr:", mobileNumberErr);
    console.log("dobErr:", dobErr);
    console.log("genderErr:", genderErr);
    console.log("eMailErr:", eMailErr);
    console.log("passwordErr:", passwordErr);
    console.log("confirmPasswordErr:", confirmPasswordErr);
    console.log("seatNumberErr:", seatNumberErr);
    console.log("flightNumberErr:", flightNumberErr);
    console.log("transportModeErr:", transportModeErr);
    console.log("mwArrivalDateErr:", mwArrivalDateErr);
    console.log("airlinesErr:", airlinesErr);
    console.log("ErrorForMultiSelect:", ErrorForMultiSelect);

    if (!firstName) { formIsValid = false; setFirstNameErr("* Required."); } else { setFirstNameErr(""); }
    if (!lastName) { formIsValid = false; setLastNameErr("* Required."); } else { setLastNameErr(""); }
    if (!countryName) { formIsValid = false; setCountryNameErr("* Required."); } else { setCountryNameErr(""); }
    if (!nationality) { formIsValid = false; setNationalityErr("* Required."); } else { setNationalityErr(""); }
    //  if (countryName === "MALAWI") {
    //   if (!nationality) { formIsValid = false; setNationalityErr("* Required."); } else {setNationalityErr(""); }
    //  }
    //  else {
    //    if (!passportNumber) { formIsValid = false; setPassportNumberErr("* Required."); } else { setPassportNumberErr(""); }
    //  }

    //   // Passport number is required for non-Malawi countries
    // if (countryName !== "MALAWI" && !passportNumber) {
    //   formIsValid = false;
    //   setPassportNumberErr("* Required.");
    // } else { setPassportNumberErr(""); }

    if (!passportNumber) { formIsValid = false; setPassportNumberErr("* Required."); } else { setPassportNumberErr(""); }
    if (!dob) { formIsValid = false; setDobErr("* Required."); } else { setDobErr(""); }
    if (!gender) { formIsValid = false; setGenderErr("* Required."); } else { setGenderErr(""); }
    if (!seatNumber) { formIsValid = false; setSeatNumberErr("* Required."); } else { setSeatNumberErr(""); }
    if (!flightNumber) { formIsValid = false; setFlightNumberErr("* Required."); } else { setFlightNumberErr(""); }
    if (!transportMode) { formIsValid = false; setTransportModeErr("* Required."); } else { setTransportModeErr(""); }
    if (!mwArrivalDate) { formIsValid = false; setMwArrivalDateErr("* Required."); } else { setMwArrivalDateErr(""); }
    if (!airlines) { formIsValid = false; setAirlinesErr("* Required."); } else { setAirlinesErr(""); }
    if (selectedCountries.length === 0) { formIsValid = false; setErrorForMultiSelect("* Required."); } else { setErrorForMultiSelect(""); }
  //   if (formIsValid) {
  //     alert("Successfully");
  //     setTimeout(() => {
  //       window.location.href = '/';
  //     }, 1000);
  //   } else {
  //     console.error("error");
  //   }
   }

  // input validation
  const validateFirstName = (e) => {
    var firstNameVal = e.target.value;
    setFirstName(firstNameVal);
    if (firstNameVal !== "") {
      setFirstNameErr("");
    } else {
      setFirstNameErr("* Required.");
    }
  };
  const validateLastName = (e) => {
    var lastNameVal = e.target.value;
    setLastName(lastNameVal);
    if (lastNameVal !== "") {
      setLastNameErr("");
    } else {
      setLastNameErr("* Required.");
    }
  };
  const handleModelChange = (e) => {
    setCountryName(e.target.value);
    setCountryNameErr("");
  };
  const validateDob = (e) => {
    setDob(e.format("YYYY-MM-DD"));
    setDobErr("");
  };
  const exceptThisSymbols = ["e", "E", "+", "-", "."];
  const validateGender = (e) => {
    setGender(e.target.value);
    // setGenderErr("");
  };
  const handleTransportModeChange = (e) => {
    setTransportMode(e.target.value);

  }
 
  const handleAirlinesChange = (e) => {
    setAirlines(e.target.value);
  };

  const handleFlightNumberChange = (e) => {
    setFlightNumber(e.target.value);
  };

  const handleSeatNumberChange = (e) => {
    setSeatNumber(e.target.value);
  };

  const handleMwArrivalDateChange = (date) => {
    setMwArrivalDate(date);
    // setMwArrivalDate("");

  };
  const validateNationality = (e) => {
    setNationality(e.target.value);
  };

  const handleMultiSelectChange = (selectedOptions) => {
    setSelectedCountries(selectedOptions);
  };
  const removeSelectedCountry = (countryValue) => {
    setSelectedCountries(selectedCountries.filter((country) => country.value !== countryValue));
  };

  const customValueRenderer = (selected) => {
    return (
      <div className="multi-select-custom-value">
        {selected.length === 0
          ? 'Select countries...'
          : selected.map((country) => (
              <div key={country.value} className="selected-country">
                {country.label}
                <button
                  onClick={() => removeSelectedCountry(country.value)}
                  className="close-button"
                ><span className="x">x</span></button>
              </div>
            ))}
      </div>
    );
  };
  return (

    <div className='form-section'>

      <div className='con'>
        <Container maxWidth="lg">
          <div className='fill-box'>
            <div className='text'>
              <p className='para'>{t("Travel Declaration Form")}</p>
              <p className='para1'>{t("Transport | Travel Declaration Form")} </p>
            </div>
            <div className='box1'>
              <Tabs value={value} onChange={handleChange} aria-label="basic tabs example" variant="scrollable" scrollButtons="auto" >
                <Tab label=<div className='icon-box'>
                  <span className='circle'>01</span>
                  <p className='p'>{t("Personal & Travel")}</p>
                </div> />
                <Tab label=<div className='icon-box'>
                  <span className='circle'>02</span>
                  <p className='p'>{t("Contact")}</p>
                </div> />
                <Tab label=<div className='icon-box'>
                  <span className='circle'>03</span>
                  <p className='p'>{t("Vax & Symtoms")}</p>
                </div> />
              </Tabs>
            </div>
          </div>
        </Container>
      </div>

      <Container maxWidth="lg">
        <CustomTabPanel value={value} index={0} className="tab-box-body">
          <Box sx={{ flexGrow: 1 }} className="box-border">


            <div className="personal-information">
              <h2 className='title'>{t("Personal Information")}</h2>
              <div className="box-content">


                <form method="post" name="personalinformationForm" className="form-sec">
                  <Grid container spacing={3}>
                    <Grid item xs={12} sm={6} md={3} lg={3} className="grid-boxes">
                      <FormControl className="form-group">
                        <FormLabel className="label" required>{t("first name label")}</FormLabel>
                        <TextField
                          className="Name"
                          type="text"
                          size="small"
                          value={firstName}
                          onChange={(e) => validateFirstName(e)}
                          placeholder={t("Enter First Name")}
                          autoComplete="off"
                        />
                        <div className="errorMsg">{firstNameErr}</div>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={6} md={3} lg={3} className="grid-boxes">
                      <FormControl className="form-group">
                        <FormLabel className="label" required>{t("last name label")}</FormLabel>
                        <TextField
                          className="Name"
                          type="text"
                          size="small"
                          value={lastName}
                          onChange={(e) => validateLastName(e)}
                          placeholder={t("Enter Last Name")}
                          autoComplete="off"
                        />
                        <div className="errorMsg">{lastNameErr}</div>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={6} md={3} lg={3} className="grid-boxes">
                      <FormControl className="form-group">
                        <FormLabel className="label" required>{t("passport no label")}</FormLabel>
                        <TextField
                          className="Name"
                          type="text"
                          size="small"
                          value={passportNumber}
                          onKeyDown={(e) =>
                            exceptThisSymbols.includes(e.key) &&
                            e.preventDefault()
                          }
                          onChange={(event) =>
                            setPassportNumber(event.target.value)
                          }
                          placeholder={t("Enter Passport Number")}
                          autoComplete="off"
                        />
                        <div className="errorMsg">{passportNumberErr}</div>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={6} md={3} lg={3} className="grid-boxes">
                      <FormControl className="form-group">
                        <FormLabel className="label" required htmlFor="gender-select">{t("gender label")}</FormLabel>
                        <Select
                          className="select-field"
                          value={gender}
                          size="small"
                          onChange={(e) => validateGender(e)}
                          displayEmpty
                          inputProps={{
                            name: 'gender',
                            id: 'gender-select',
                          }}
                          placeholder={t("gender option")}

                        >
                          <MenuItem value="" disabled>
                            <em>{t("gender option")}</em>
                          </MenuItem>
                          <MenuItem value="Male">{t("male option")}</MenuItem>
                          <MenuItem value="Female">{t("female option")}</MenuItem>
                          <MenuItem value="Other">{t("other option")}</MenuItem>
                        </Select>
                        <div className="errorMsg">{genderErr}</div>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={6} md={3} lg={3} className="grid-boxes">

                      <FormControl className="form-group">
                        <FormLabel className="label" required="country-select">{t("Country Of Resisdence")}</FormLabel>
                        <TextField
                          className="select-field"
                          type="text"
                          value={countryName}
                          size="small"
                          onChange={handleModelChange}
                          placeholder={t("Enter Country Of Resisdence")}
                          autoComplete="off"
                        >
                        </TextField>
                        <div className="errorMsg">{countryNameErr}</div>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={6} md={3} lg={3} className="grid-boxes">
                      <FormControl className="form-group">
                        <FormLabel className="label" required>{t("DOB label")}</FormLabel>

                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DesktopDatePicker

                            className="date-pick"
                            format="DD/MM/YYYY"
                            value={dob}
                            onChange={(e) => validateDob(e)}
                            placeholder={t("Select Date of Birth")}

                          />
                        </LocalizationProvider>

                        <div className="errorMsg">{dobErr}</div>
                      </FormControl>
                    </Grid>

                    <Grid item xs={12} sm={6} md={3} lg={3} className="grid-boxes">
                      <FormControl className="form-group">
                        <FormLabel className="label" required>{t("nationality label")}</FormLabel>
                        <TextField
                          className="Name"
                          type="text"
                          size="small"
                          value={nationality}
                          onKeyDown={(e) =>
                            exceptThisSymbols.includes(e.key) &&
                            e.preventDefault()
                          }
                          onChange={validateNationality}
                          placeholder={t("Enter Nationality")}
                          autoComplete="off"
                        />
                        <div className="errorMsg">{nationalityErr}</div>
                      </FormControl>
                    </Grid>
                  </Grid>
                </form>
              </div>
            </div>
            <div className="traval-information">
              <h2 className='title'>{t("Travel Information")}</h2>
              <div className="box-content">
                <form method="post" name="personalinformationForm" className="form-sec">
                  <Grid container spacing={3}>
                    <Grid item xs={12} sm={12} md={12} lg={12} className="grid-boxes">
                    <FormControl className="form-group">
                    <FormLabel className="label" required>
                          {t("Select the Countries You Visited In the Last Three Weeks")}
                    </FormLabel>
                    <MultiSelect className="multi"
                    options={options}
                    value={selectedCountries}
                    onChange={handleMultiSelectChange}
                    labelledBy={'Select'}
                    valueRenderer={customValueRenderer}
                    disableSearch={false}
                    hasSelectAll={false}
                    noDropdown
                    overrideStrings={{
                      selectSomeItems: 'Select countries...',
                    }}
                  />
                   
                   
                   <div className="errorMsg">{ErrorForMultiSelect}</div>
                  </FormControl>
                    </Grid>
              
                    <Grid item xs={12} sm={6} md={3} lg={3} className="grid-boxes">
                      <FormControl className="form-group">
                        <FormLabel className="label" required htmlFor="transport-mode-select">Mode of Transport</FormLabel>
                        <Select
                          className="select-field"
                          value={transportMode}
                          size="small"
                          onChange={handleTransportModeChange}
                          displayEmpty
                          inputProps={{
                            name: 'transportMode',
                            id: 'transport-mode-select',
                          }}
                        >
                          <MenuItem value="" disabled>
                            <em>{t("Mode of Transport")}</em>
                          </MenuItem>
                          <MenuItem value="bike">Bike</MenuItem>
                          <MenuItem value="car">Car</MenuItem>
                          <MenuItem value="taxi">Taxi</MenuItem>
                        </Select>
                        <div className="errorMsg">{transportModeErr}</div>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={6} md={3} lg={3} className="grid-boxes">
                      <FormControl className="form-group">
                        <FormLabel className="label" required>Date of MW Arrival</FormLabel>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DesktopDatePicker
                            className="date-pick"
                            format="DD/MM/YYYY"
                            value={mwArrivalDate}
                            onChange={handleMwArrivalDateChange}
                            placeholder="Enter Date of MW Arrival"

                          />
                        </LocalizationProvider>
                        <div className="errorMsg">{mwArrivalDateErr}</div>
                      </FormControl>
                    </Grid>

                    <Grid item xs={12} sm={6} md={3} lg={3} className="grid-boxes">
                      <FormControl className="form-group">
                        <FormLabel className="label" required>Seat Number</FormLabel>
                        <TextField
                          className="Name"
                          type="text"
                          size="small"
                          value={seatNumber}
                          onChange={handleSeatNumberChange}
                          placeholder="Enter Seat Number"
                          autoComplete="off"
                        />
                        <div className="errorMsg">{seatNumberErr}</div>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={6} md={3} lg={3} className="grid-boxes">
                      <FormControl className="form-group">
                        <FormLabel className="label" required>Airlines</FormLabel>
                        <TextField
                          className="Name"
                          type="text"
                          size="small"
                          value={airlines}
                          onChange={handleAirlinesChange}
                          placeholder="Enter Airlines"
                          autoComplete="off"
                        />
                        <div className="errorMsg">{airlinesErr}</div>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={6} md={3} lg={3} className="grid-boxes">
                      <FormControl className="form-group">
                        <FormLabel className="label" required>Flight Number</FormLabel>
                        <TextField
                          className="Name"
                          type="text"
                          size="small"
                          value={flightNumber}
                          onChange={handleFlightNumberChange}
                          placeholder="Enter Flight Number"
                          autoComplete="off"
                        />
                        <div className="errorMsg">{flightNumberErr}</div>
                      </FormControl>
                    </Grid>
                  </Grid>
                </form>

              </div>
            </div>
          </Box>
          <div className="submit">
          {value < 2 ? (
            <LoadingButton className="btn" onClick={() => handleTabChange(value + 1)} loading={btnLoading} loadingIndicator="Loading…">
                <span className="next">{t("Next")}&nbsp;&nbsp;<img src={right} className="right" alt="Next" /></span>
            </LoadingButton>
          ) : (
            <LoadingButton className="btn" onClick={validateForm} loading={btnLoading} loadingIndicator="Loading…">
                  <span className="next">{t("submit small text")}</span>
            </LoadingButton>
          )}
        </div>
        </CustomTabPanel>                   

        <CustomTabPanel value={value} index={1} className="tab-box-body">
        {value === 1 && <Contactform handleTabChange={handleTabChange} value={value} />}
        </CustomTabPanel>

      <CustomTabPanel value={value} index={2} className="tab-box-body">
      {value === 2 && <Vax handleTabChange={handleTabChange} value={value} />}
      </CustomTabPanel>

      

      </Container>
    </div>
     

  )
}

export default Travelform











