import React from "react";
import "./Loader.css"

const FormLoader = () => {
  return (
    <div className="form-loader">
      <div className="form-loader-inner">
        <div className="loader-icon"></div>
      </div>
    </div>
  );
};

export default FormLoader;
