import axios from "axios";
import {
  Container,
  FormGroup,
  FormLabel,
  Grid,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import React from "react";
import { useState } from "react";
import useApiFetch from "../../hooks/useApiFetch";
import {
  BODY_TYPE,
  CATEGORY_LISTALL,
  MDA_LISTALL,
  VEHICLE_MAKE,
  VEHICLE_MODEL,
  VEHICLE_SAVE,
} from "../../utils/api-constant";
import Popup from "./Popup";
import LoadingPage from "./LoadingPage";
import { LoadingButton } from "@mui/lab";
import { useTranslation } from "react-i18next";
import { replaceSpaceWithDash } from "../../utils/custom";
import { Link } from "react-router-dom";
import ErrorSection from "../Error/ErrorSection";

const VehicleDutyCalculator = () => {
  const { t } = useTranslation();
  const token = JSON.parse(localStorage.getItem("apiToken"));
  const [vehicleMakeInput, setVehicleMakeInput] = useState("");
  const [vehicleModelCode, setVehicleModelCode] = useState("");
  const [year, setYear] = useState("");
  const [bodyTypeCode, setBodyTypeCode] = useState("");
  const [text, setText] = useState({
    makeText: "",
    modeltext: "",
    bodyText: "",
  });
  const [popup, setPopup] = useState({
    status: false,
    details: {},
  });
  const [errors, setErrors] = useState({
    make: false,
    model: false,
    yesr: false,
    body: false,
  });

  const [success, setSuccess] = useState(null);
  const [modleList, setModelList] = useState([]);
  const [btnLoading, setBtnloading] = useState(false);
  const [ModelSelect, setModelSelect] = useState(false);

  const {
    data: listData,
    loading: listLoading,
    error: listError,
  } = useApiFetch(process.env.REACT_APP_HOST_URL + CATEGORY_LISTALL);

  const {
    data: mdaDtata,
    loading: mdaLoading,
    error: mdaError,
  } = useApiFetch(process.env.REACT_APP_HOST_URL + MDA_LISTALL);
  const { data, loading, error } = useApiFetch(
    process.env.REACT_APP_HOST_URL + VEHICLE_MAKE
  );
  const {
    data: bodyData,
    loading: bodyLoading,
    error: bodyError,
  } = useApiFetch(process.env.REACT_APP_HOST_URL + BODY_TYPE);
  const params = {
    headers: {
      "Content-Type": "application/json",
      needJson: 1,
      apiToken: token,
    },
  };
  const handleVehicleMake = async (value) => {
    setVehicleMakeInput(value);
    if (value) {
      setVehicleModelCode("");
      await axios
        .get(process.env.REACT_APP_HOST_URL + VEHICLE_MODEL + value, params)
        .then((res) => {
          if (res.data.list && Array.isArray(res.data.list)) {
            setModelList(res.data.list);
          } else {
            setModelSelect(true);
          } 
        })
        .catch((e) => {
          console.log(e.message);
          setModelSelect(true);
        });

      setErrors((prev) => ({
        ...prev,
        make: false,
      }));
    } else {
      setErrors((prev) => ({
        ...prev,
        make: true,
      }));
    }
  };
  const handeleVechicleModle = (value) => {
    setVehicleModelCode(value);
    if (value) {
      setErrors((prev) => ({
        ...prev,
        model: false,
      }));
    } else {
      setErrors((prev) => ({
        ...prev,
        model: true,
      }));
    }
  };

  const handleYear = (value) => {
    setYear(value);
    if (value) {
      setErrors((prev) => ({
        ...prev,
        year: false,
      }));
    } else {
      setErrors((prev) => ({
        ...prev,
        year: true,
      }));
    }
  };

  const handleBodyType = (value) => {
    setBodyTypeCode(value);
    if (value) {
      setErrors((prev) => ({
        ...prev,
        body: false,
      }));
    } else {
      setErrors((prev) => ({
        ...prev,
        body: true,
      }));
    }
  };
  if (error || bodyError || listError || mdaError) return <ErrorSection />;
  if (loading || bodyLoading || listLoading || mdaLoading)
    return <LoadingPage />;
  const vehicleMake = data.list;
  const bodyType = bodyData.list;

  if ((vehicleMake.code) || (bodyType.code))
    return <ErrorSection />;

  if (ModelSelect)
    return <ErrorSection />;

  const handleSubmit = async (e) => {
    e.preventDefault();
    setBtnloading(true);
    let hasError = true;

    if (vehicleMakeInput === "") {
      hasError = false;
      setErrors((prev) => ({
        ...prev,
        make: true,
      }));
    }
    if (vehicleModelCode === "") {
      hasError = false;
      setErrors((prev) => ({
        ...prev,
        model: true,
      }));
    }
    if (year === "") {
      hasError = false;
      setErrors((prev) => ({
        ...prev,
        year: true,
      }));
    }
    if (bodyTypeCode === "") {
      hasError = false;
      setErrors((prev) => ({
        ...prev,
        body: true,
      }));
    }
    if (hasError) {
      var makeText, modeltext, bodyText;
      for (var i = 0; i < vehicleMake.length; i++) {
        if (vehicleMake[i].Code === vehicleMakeInput) {
          makeText = vehicleMake[i].Description;
        }
      }
      for (var k = 0; k < modleList.length; k++) {
        if (modleList[k].ModelCode === vehicleModelCode) {
          modeltext = modleList[k].Description;
        }
      }
      for (var j = 0; j < bodyType.length; j++) {
        if (bodyType[j].Code === bodyTypeCode) {
          bodyText = bodyType[j].Description;
        }
      }
      setText({
        makeText,
        modeltext,
        bodyText,
      });

      const params = {
        headers: {
          "Content-Type": "application/json",
          needJson: 1,
          apiToken: token,
        },
      };
      await axios
        .post(
          process.env.REACT_APP_HOST_URL + VEHICLE_SAVE,
          // "https://eservicebackend.stutzen.in/api/external/static/vehicleInfoSave",
          {
            vehicle_make_code: vehicleMakeInput,
            vehicle_model_code: vehicleModelCode,
            vehicle_year: year,
            vehicle_body_code: bodyTypeCode,
          },
          params
        )
        .then(function (response) {
          if (response.data.success) {
            console.log(response.data.message);
            setSuccess(true);
            setPopup({
              status: true,
              details: response.data,
            });
          }
        })
        .catch(function (error) {
          setSuccess(false);
          setPopup({
            status: true,
            details: error,
          });
        });
    }
    setBtnloading(false);
  };

  document.body.onkeydown = function (e) {
    if (e.key === "Enter") {
      handleSubmit(e);
    }
  };
  const currentUrl = window.location.hash.split("/");
  const cat = currentUrl[2];
  const serviceName = currentUrl[3];
  var currentService;
  if (cat === "services") {
    currentService = listData.list.find(
      (item) => replaceSpaceWithDash(item.name) === serviceName
    );
  } else if (cat === "mda") {
    currentService = mdaDtata.list.find(
      (item) => replaceSpaceWithDash(item.name) === serviceName
    );
  }
  if (!currentService) return <ErrorSection />;
  return (
    <>
      <div className="service-header">
        <Container>
          <div className="service-header-inner">
            <div className="left-div">
              <h6>{t("vehicle duty calc title")}</h6>
              <span className="desc">
                {" "}
                <Link
                  to={`/${replaceSpaceWithDash(currentService.name)}/${cat}/${
                    currentService.id
                  }`}
                >
                  {currentService.name}
                </Link>{" "}
                | {t("vehicle duty calc title")}
              </span>
            </div>
          </div>
        </Container>
      </div>
      <Container>
        <div className="service-page-detail">
          <form className="tcc-add">
            <Grid container columnSpacing={{ xs: 0, sm: 4 }}>
              <Grid item xs={12} sm={6} md={4}>
                <FormGroup className="form-group">
                  <FormLabel required>{t("vehicle make label")}</FormLabel>
                  <Select
                    onChange={(e) => handleVehicleMake(e.target.value)}
                    value={vehicleMakeInput}
                    displayEmpty
                  >
                    <MenuItem disabled value="">
                      <span style={{ opacity: 0.4 }}>
                        {t("vehicle make option")}
                      </span>
                    </MenuItem>
                    {Array.isArray(vehicleMake) && vehicleMake.length > 0 ? (
                      vehicleMake.map((item) => (
                        item.Description !== null && item.Description !== '' ? (
                          <MenuItem
                            key={item.Code}
                            name={item.type}
                            value={item.Code}
                          >
                            {item.Description}
                          </MenuItem>
                        ) : null
                      ))
                    ) : (
                      <MenuItem disabled>No data available</MenuItem>
                    )}
                  </Select>
                  {errors.make && (
                    <div className="errorMsg">{t("required alert")}</div>
                  )}
                </FormGroup>
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <FormGroup className="form-group">
                  <FormLabel required>{t("vehicle model label")}</FormLabel>
                  <Select
                    onChange={(e) => handeleVechicleModle(e.target.value)}
                    value={vehicleModelCode}
                    displayEmpty
                  >
                    <MenuItem disabled value="">
                      <span style={{ opacity: 0.4 }}>
                        {t("vehicle model option")}
                      </span>
                    </MenuItem>
                    {modleList?.map((item) => (
                      item.Description !== null && item.Description !== '' ? <MenuItem key={item.ModelCode} value={item.ModelCode}>
                        {item.Description}
                      </MenuItem>
                      : null
                    ))}
                  </Select>
                  {errors.model && (
                    <div className="errorMsg">{t("required alert")}</div>
                  )}
                </FormGroup>
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <FormGroup className="form-group">
                  <FormLabel required>{t("year of make label")}</FormLabel>
                  <TextField
                    placeholder={t("year of make placeholder")}
                    className="form-control"
                    type="number"
                    size="small"
                    value={year}
                    onChange={(e) => {
                      handleYear(e.target.value);
                    }}
                  />
                  {errors.year && (
                    <div className="errorMsg">{t("required alert")}</div>
                  )}
                </FormGroup>
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <FormGroup className="form-group">
                  <FormLabel required>{t("body type label")}</FormLabel>
                  <Select
                    value={bodyTypeCode}
                    onChange={(e) => handleBodyType(e.target.value)}
                    displayEmpty
                  >
                    <MenuItem disabled value="">
                      <span style={{ opacity: 0.4 }}>
                        {t("body type option")}
                      </span>
                    </MenuItem>
                    {Array.isArray(bodyType) ? (
                        bodyType.map((item) => (
                          item.Description !== null && item.Description !== '' ? (
                            <MenuItem key={item.Code} value={item.Code}>
                              {item.Description}
                            </MenuItem>
                          ) : null
                        ))
                      ) : (
                        <MenuItem disabled>No data available</MenuItem>
                      )}
                  </Select>

                  {errors.body && (
                    <div className="errorMsg">{t("required alert")}</div>
                  )}
                </FormGroup>
              </Grid>
            </Grid>
            <Grid item md={12} className="text-right">
              <LoadingButton
                className="btn-submit"
                onClick={handleSubmit}
                loading={btnLoading}
                loadingIndicator="Loading…"
              >
                <span>{t("calc duty text")}</span>
              </LoadingButton>
            </Grid>
          </form>
        </div>
      </Container>
      {popup.status && (
        <Popup sucsess={success}>
          {success ? (
            <>
              <div className="top">
                {t("vehicle duty calculated text")}
                <br />
                <span>
                  {t("mwk text")} {popup.details.PayableAmount}
                </span>
              </div>
              <div className="field">
                <div className="field-li">
                  <span>{t("vehicle make label")}:</span>
                  {text.makeText}
                </div>
                <div className="field-li">
                  <span>{t("vehicle model label")}:</span>
                  {text.modeltext}
                </div>
                <div className="field-li">
                  <span>{t("year of make label")}:</span>
                  {year}
                </div>
                <div className="field-li">
                  <span>{t("body type label")}:</span>
                  {text.bodyText}
                </div>
              </div>
            </>
          ) : (
            <div className="top">{popup.details.message}</div>
          )}
        </Popup>
      )}
    </>
  );
};

export default VehicleDutyCalculator;
