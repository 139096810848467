import React from "react";
import "./Loader.css"

const ServiceLoader = () => {
  return (
    <div className="Service-loader">
      <div className="Service-loader-inner">
      <div className="loader-icon"></div>
      </div>
    </div>
  );
};

export default ServiceLoader;
