import React, { useEffect, useState } from "react";
import { Container } from "@mui/system";
import useApiFetch from "../../../hooks/useApiFetch";
import { STATIC_PAGE } from "../../../utils/api-constant";
import LoadingPage from "../../SeviceScreen/LoadingPage";
import ErrorSection from "../../Error/ErrorSection";
import "./StaticPage.css";
import { useLocation } from "react-router-dom";

const StaticPage = () => {
  const [url, setUrl] = useState("");
  const location = useLocation();
  const { data, loading, error } = useApiFetch(
    process.env.REACT_APP_HOST_URL + STATIC_PAGE
  );

  useEffect(() => {
    setUrl(window.location.href.split("/").pop());
  }, [location]);

  if (error) return <ErrorSection />;
  if (loading) return <LoadingPage />;
  const currentPage = data.list.data.find((item) => item.urlcode === url);
  if (!currentPage) return <ErrorSection />;
  return (
    <>
      <div className="service-header">
        <Container>
          <div className="service-header-inner">
            <div className="left-div">
              <h6>{currentPage.page_title}</h6>
            </div>
          </div>
        </Container>
      </div>
      <Container>
        <div className="service-page-detail static-detail">
          <div dangerouslySetInnerHTML={{ __html: currentPage.content }}></div>
        </div>
      </Container>
    </>
  );
};

export default StaticPage;
