import React from "react";
import PopularServiceBoxGrid from "../PopularServiceBoxGrid/PopularServiceBoxGrid";
import { Container } from "@mui/material";
import { FREQUENT_SERVICE } from "../../../utils/api-constant";
import ServiceLoader from "../../Loader/ServiceLoader";
import LoadingPage from "../../SeviceScreen/LoadingPage";
import ErrorSection from "../../Error/ErrorSection";
import useApiFetch from "../../../hooks/useApiFetch";

const PopularServices = () => {
  
  const { data, loading, error } = useApiFetch(
    process.env.REACT_APP_HOST_URL + FREQUENT_SERVICE
  );

  if (error) return <ErrorSection />;

  if (loading) return <LoadingPage />;

  return (
    <section className="PopularServicesSection">
      <Container maxWidth="lg">
        {/* <h1 className="head-with-line">Popular Services</h1> */}
        <PopularServiceBoxGrid boxGridApi={data} type="service" />
      </Container>
      <ServiceLoader />
    </section>
  );
};

export default PopularServices;
