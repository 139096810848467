import React , {useEffect, useState} from 'react';
import Box from '@mui/material/Box';
import Alert from '@mui/material/Alert';
import IconButton from '@mui/material/IconButton';
import Collapse from '@mui/material/Collapse';
import CloseIcon from '@mui/icons-material/Close';

export default function TransitionAlerts({setShow, show, msg, type}) {
  const [open, setOpen] = useState(false);

  const handleAlertClose = () => {
    setShow(false);
  }

  useEffect( () => {
    setOpen(show);
    if(show){
      window.scrollTo(0, 0);
    }
  },[show])

  return (
    <Box sx={{ width: '100%' }}>
      <Collapse in={open}>
        <Alert
          severity={`${type ? "success" : "error"}`}
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={() => {handleAlertClose()}}
            >
              <CloseIcon fontSize="inherit" />
            </IconButton>
          }
          sx={{ mb: 2 }}
        >
         {msg}
        </Alert>
      </Collapse>      
    </Box>
  );
}