import React from "react";
import "./Footer.css";
import logo from "../../assets/Img/logo.png";
import location1 from "../../assets/Img/location1.png";
import phone1 from "../../assets/Img/phone1.png";
import message from "../../assets/Img/message.png";
import google from "../../assets/Img/google.png";
import apple from "../../assets/Img/apple.png";
import { Link, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { STATIC_FOOTER } from "../../utils/api-constant";
import useApiFetch from "../../hooks/useApiFetch";
function Footer() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { data, loading, error } = useApiFetch(
    process.env.REACT_APP_HOST_URL + STATIC_FOOTER
  );
  return (
    <footer className="FooterUI">
      <div className="footer-section">
        <div className="leftCol">
          <div className="text1">
            <Link to="/home">
              <img src={logo} alt="Logo" />
            </Link>
            <p href="/" className="title-text">
              {t("site title")}
              <span>{t("site subtitle")}</span>
            </p>
          </div>
          <div className="detail">
            <p className="para">{t("footer description")}</p>
          </div>
          <div className="download">
            <div className="text">
              <h5>{t("download app text")}</h5>
            </div>
            <br />
            <a>
              <img src={google} alt="google" />
            </a>
            <a>
              <img src={apple} alt="apple" />
            </a>
          </div>
        </div>
        <div className="rightCol">
          <div className="footer-col">
            <div className="text">
              <h5>{t("quicklink text")}</h5>
            </div>
            <div className="detail">
              <div className="pad1">
                <ul>
                  <li>
                    <Link to="/health/services/1">
                      {t("health care text")}
                    </Link>
                  </li>                 
                  <li>
                    <Link to="/transport/services/3">
                      {t("transport text")}
                    </Link>
                  </li>
                  <li>
                    <Link to="/finance/services/2">
                      {t("finance text")}
                    </Link>
                  </li>
                  {/* <li>
                    <Link to="/Travelform">Travel</Link>
                  </li> */}
                </ul>
              </div>
            </div>
          </div>

          <div className="footer-col">
            <div className="text">
              <h5>{t("contact title")}</h5>
            </div>
            <div className="detail">
              <div className="pad">
                <div className="address-icon">
                  <span className="icon-img">
                    <img className="icon" src={location1} alt="Location" />
                  </span>
                  <p>{t("footer addr")}</p>
                </div>

                <div className="address-icon">
                  <span className="icon-img">
                    <img className="icon1" src={message} alt="Email" />
                  </span>
                  <p>{t("footer mail")}</p>
                </div>

                <div className="address-icon">
                  <span className="icon-img">
                    <img className="icon" src={phone1} alt="Phone" />
                  </span>
                  <p>{t("footer contact")}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="copy-right">
        <div className="bor"></div>
        <div className="bot">
          {!loading & !error ? (
            <div
              dangerouslySetInnerHTML={{ __html: data.list[0]?.content }}
            ></div>
          ) : (
            <p></p>
          )}
          <p>
            <Link to="cms-page/privacy_policy">{t("policy text")}</Link>
            <Link to="cms-page/terms_and_conditions">
              {t("term and condition text")}
            </Link>
          </p>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
