import {
  Container,
  FormGroup,
  FormLabel,
  Grid,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import axios from "axios";
import React, { useContext, useEffect } from "react";
import { useState } from "react";
import validator from "validator";
import useApiFetch from "../../hooks/useApiFetch";
import {
  CATEGORY_LISTALL,
  GET_TCC_TYPE,
  MDA_LISTALL,
  TCC_SERVICE_SAVE,
} from "../../utils/api-constant";
import LoadingPage from "./LoadingPage";
import Popup from "./Popup";
import { LoadingButton } from "@mui/lab";
import AuthContext from "../../context/AuthContext";
import { Link, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { replaceSpaceWithDash } from "../../utils/custom";
import ErrorSection from "../Error/ErrorSection";

const TccAdd = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const token = JSON.parse(localStorage.getItem("apiToken"));
  const authCtx = useContext(AuthContext);

  useEffect(() => {
    if (!authCtx.isLogin) {
      return navigate("/login");
    }
  }, [authCtx.isLogin]);

  const [btnLoading, setBtnloading] = useState(false);
  const [success, setSuccess] = useState(null);
  const [email, setEmail] = useState({
    value: "",
    error: false,
    isNotEmail: false,
  });
  const [firstName, setFirstName] = useState({
    value: "",
    error: false,
  });
  const [lastName, setLastName] = useState({
    value: "",
    error: false,
  });
  const [mobile, setMobile] = useState({
    value: "",
    error: false,
  });
  const [tpin, setTpin] = useState({
    value: "",
    error: false,
  });
  const [tccType, setTccType] = useState({
    value: "",
    name: "",
    error: false,
  });
  const [popup, setPopup] = useState({
    status: false,
    details: {},
  });
  const {
    data: listData,
    loading: listLoading,
    error: listError,
  } = useApiFetch(process.env.REACT_APP_HOST_URL + CATEGORY_LISTALL);
  const { data, loading, error } = useApiFetch(
    process.env.REACT_APP_HOST_URL + GET_TCC_TYPE
  );

  const {
    data: mdaDtata,
    loading: mdaLoading,
    error: mdaError,
  } = useApiFetch(process.env.REACT_APP_HOST_URL + MDA_LISTALL);

  if (error || listError || mdaError) return <ErrorSection />;
  if (loading || listLoading || mdaLoading) return <LoadingPage />;

  const tccTypeList = data.list;

  const handleEmailChange = (value) => {
    setEmail((prev) => ({
      ...prev,
      value,
    }));
    if (value) {
      if (validator.isEmail(value)) {
        setEmail((prev) => ({
          ...prev,
          value,
          error: false,
          isNotEmail: false,
        }));
      } else {
        setEmail((prev) => ({
          ...prev,
          value,
          error: false,
          isNotEmail: true,
        }));
      }
    } else {
      setEmail((prev) => ({
        ...prev,
        value,
        error: true,
        isNotEmail: false,
      }));
    }
  };

  const handleFirstNameChange = (value) => {
    if (value) {
      setFirstName({
        value,
        error: false,
      });
    } else {
      setFirstName({
        value,
        error: true,
      });
    }
  };

  const handleLastNameChange = (value) => {
    if (value) {
      setLastName({
        value,
        error: false,
      });
    } else {
      setLastName({
        value,
        error: true,
      });
    }
  };

  const handleMobileChange = (value) => {
    if (value) {
      setMobile({
        value,
        error: false,
      });
    } else {
      setMobile({
        value,
        error: true,
      });
    }
  };

  const handleTpinChange = (value) => {
    if (value) {
      setTpin({
        value,
        error: false,
      });
    } else {
      setTpin({
        value,
        error: true,
      });
    }
  };

  const handleTccTypeChange = (event) => {
    const { value } = event.target;
    const selectedTccType = tccTypeList.find((item) => item.Code === value);
    if (selectedTccType) {
      setTccType({
        value: selectedTccType.Code,
        name: selectedTccType.Description,
        error: false,
      });
    } else {
      setTccType({
        value: "",
        name: "",
        error: true,
      });
    }
  };

  const handleSubmit = async (e) => {
    setBtnloading(true);
    e.preventDefault();
    let valid = true;

    if (!email.value) {
      setEmail((prev) => ({
        ...prev,
        error: true,
      }));
      valid = false;
    } else if (!validator.isEmail(email.value)) {
      setEmail((prev) => ({
        ...prev,
        isNotEmail: true,
      }));
      valid = false;
    }

    if (!firstName.value) {
      setFirstName((prev) => ({
        ...prev,
        error: true,
      }));
    }

    if (!lastName.value) {
      setLastName((prev) => ({
        ...prev,
        error: true,
      }));
    }

    if (!lastName.value) {
      setLastName((prev) => ({
        ...prev,
        error: true,
      }));
    }

    if (!mobile.value) {
      setMobile((prev) => ({
        ...prev,
        error: true,
      }));
    }

    if (!tpin.value) {
      setTpin((prev) => ({
        ...prev,
        error: true,
      }));
    }

    if (!tccType.value) {
      setTccType((prev) => ({
        ...prev,
        error: true,
      }));
    }

    if (valid) {
      const params = {
        headers: {
          "Content-Type": "application/json",
          needJson: 1,
          apiToken: token,
        },
      };
      await axios
        .post(
          process.env.REACT_APP_HOST_URL + TCC_SERVICE_SAVE,
          // "https://eservicebackend.stutzen.in/api/external/static/tccServicesSave",
          {
            email_address: email.value,
            tpin: tpin.value,
            tcctype_code: tccType.value,
            tcctype_codevalue: tccType.name, // Include tcctype_name
            applicant_first_name: firstName.value,
            applicant_last_name: lastName.value,
            phone_number: mobile.value,
          },
          params
        )
        .then(function (response) {
          if (response.data.success) {
            console.log(response.data.message);
            setSuccess(true);
            setPopup({
              status: true,
              details: response.data,
            });
          }
        })
        .catch(function (error) {
          setSuccess(false);
          setPopup({
            status: true,
            details: error,
          });
        });
    }
    setBtnloading(false);
  };

  document.body.onkeydown = function (e) {
    if (e.key === "Enter") {
      handleSubmit(e);
    }
  };
  const currentUrl = window.location.hash.split("/");
  const cat = currentUrl[2];
  const serviceName = currentUrl[3];
  var currentService;
  if (cat === "services") {
    currentService = listData.list.find(
      (item) => replaceSpaceWithDash(item.name) === serviceName
    );
  }
  else if (cat === "mda") {
    currentService = mdaDtata.list.find(
      (item) => replaceSpaceWithDash(item.name) === serviceName
    );
  }
  if (!currentService) return <ErrorSection />;
  return (
    <div className="service-page">
      <div className="service-header">
        <Container>
          <div className="service-header-inner">
            <div className="left-div">
              <h6>{t("add tcc title")}</h6>
              <span className="desc">
                {" "}
                <Link
                  to={`/${replaceSpaceWithDash(currentService.name)}/${cat}/${
                    currentService.id
                  }`}
                >
                  {currentService.name}
                </Link>{" "}
                |{" "}
                <Link
                  to={`/${currentUrl[1]}/${currentUrl[2]}/${currentUrl[3]}/${currentUrl[4]}`}
                >
                  {t("tcc application title")}
                </Link>{" "}
                | {t("add tcc title")}
              </span>
            </div>
          </div>
        </Container>
      </div>
      <Container>
        <div className="service-page-detail">
          <form className="tcc-add">
            <Grid container columnSpacing={{ xs: 0, sm: 4 }}>
              <Grid item xs={12} sm={6} md={4}>
                <FormGroup className="form-group">
                  <FormLabel required>{t("email label")}</FormLabel>
                  <TextField
                    placeholder={t("email placeholder")}
                    className="form-control"
                    type="text"
                    size="small"
                    value={email.value}
                    onChange={(e) => handleEmailChange(e.target.value)}
                  />
                  {email.error && (
                    <div className="errorMsg">{t("required alert")}</div>
                  )}
                  {email.isNotEmail && (
                    <div className="errorMsg">{t("email valid alert")}</div>
                  )}
                </FormGroup>
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <FormGroup className="form-group">
                  <FormLabel required>{t("first name label")}</FormLabel>
                  <TextField
                    placeholder={t("first name placeholder")}
                    className="form-control"
                    type="text"
                    size="small"
                    value={firstName.value}
                    onChange={(e) => handleFirstNameChange(e.target.value)}
                  />
                  {firstName.error && (
                    <div className="errorMsg">{t("required alert")}</div>
                  )}
                </FormGroup>
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <FormGroup className="form-group">
                  <FormLabel required>{t("last name label")}</FormLabel>
                  <TextField
                    placeholder={t("last name placeholder")}
                    className="form-control"
                    type="text"
                    size="small"
                    value={lastName.value}
                    onChange={(e) => handleLastNameChange(e.target.value)}
                  />
                  {lastName.error && (
                    <div className="errorMsg">{t("required alert")}</div>
                  )}
                </FormGroup>
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <FormGroup className="form-group">
                  <FormLabel required>{t("mobile label")}</FormLabel>
                  <TextField
                    placeholder={t("mobile placeholder")}
                    className="form-control"
                    type="number"
                    size="small"
                    value={mobile.value}
                    onChange={(e) => handleMobileChange(e.target.value)}
                  />
                  {mobile.error && (
                    <div className="errorMsg">{t("required alert")}</div>
                  )}
                </FormGroup>
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <FormGroup className="form-group">
                  <FormLabel required>{t("tpin label")}</FormLabel>
                  <TextField
                    placeholder={t("tpin placeholder")}
                    className="form-control"
                    type="text"
                    size="small"
                    value={tpin.value}
                    onChange={(e) => handleTpinChange(e.target.value)}
                  />
                  {tpin.error && (
                    <div className="errorMsg">{t("required alert")}</div>
                  )}
                </FormGroup>
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <FormGroup className="form-group">
                  <FormLabel required>{t("tcc type code label")}</FormLabel>
                  <Select
                    value={tccType.value}
                    onChange={handleTccTypeChange}
                    displayEmpty
                  >
                    <MenuItem disabled value="">
                      <span style={{ opacity: 0.4 }}>
                        {t("tcc type option")}
                      </span>
                    </MenuItem>
                    {Array.isArray(tccTypeList) && tccTypeList.length > 0 ? (
                      tccTypeList.map((item) => (
                        <MenuItem value={item.Code} key={item.Code}>
                          {item.Description}
                        </MenuItem>
                      ))
                    ) : (
                      <MenuItem disabled>No items available</MenuItem>
                    )}
                  </Select>

                  {tccType.error && (
                    <div className="errorMsg">{t("required alert")}</div>
                  )}
                </FormGroup>
              </Grid>
            </Grid>
            <Grid item md={12} className="text-right">
              <LoadingButton
                className="btn-submit"
                onClick={handleSubmit}
                loading={btnLoading}
                loadingIndicator="Loading…"
              >
                <span>{t("submit application text")}</span>
              </LoadingButton>
            </Grid>
          </form>
        </div>
      </Container>
      {popup.status && (
        <Popup sucsess={success}>
          {success ? (
            <div className="top">
              {popup.details.message}
              <br />
              <br />
              {t("application ref no text")}
              <span>{popup.details.ApplicationRef}</span>
            </div>
          ) : (
            <div className="top">{popup.details.message}</div>
          )}
        </Popup>
      )}
    </div>
  );
};

export default TccAdd;
