import React, { useContext } from "react";
import "./ServicePageLayout.css";
import ExchangeRate from "./ExchangeRate";
import TccView from "./TccView";
import TccValidator from "./TccValidator";
import VehicleDutyCalculator from "./VehicleDutyCalculator";
import { useNavigate, useLocation } from "react-router-dom";
import { useEffect } from "react";
import AuthContext from "../../context/AuthContext";
import HealthFacility from "./HealthFacility";
import ErrorSection from "../Error/ErrorSection";

const ServicePageLayout = () => {
  const navigate = useNavigate();
  const authCtx = useContext(AuthContext);
  const currentUrl = window.location.hash.split("/");
  const service = currentUrl[4];
  var currentPage;

  const location = useLocation();
  const { anonymous_user } = location.state !== null && location.state !== undefined ? location.state : "/";
  console.log("anonymous_user--> " + anonymous_user);

  useEffect(() => {
    if(anonymous_user === 0){
      if (!authCtx.isLogin) {
        console.log("ServicePageLayout");
        return navigate("/login?", { state: { from: 1, navigateUrl: "/" + currentUrl[1] + "/" + currentUrl[2] + "/" + currentUrl[3] + "/" + currentUrl[4] } });
      }
    }
  }, [authCtx.isLogin]);

  useEffect(() => {
    const HandleBackClick = (event) => {
      console.log("ServicePageLayout_HandleBackClick")
      navigate('/');
    };
    window.addEventListener('popstate', HandleBackClick); // Add listener
    return () => {
      window.removeEventListener('popstate', HandleBackClick); // Clean up on unmount
    };
  }, []);

  switch (service) {
    case "updated-exchange-rates":
      currentPage = <ExchangeRate />;
      break;
    case "tax-clearance-certification-validation":
      currentPage = <TccValidator />;
      break;
    case "tax-clearance-certificate-application":
      currentPage = <TccView />;
      break;
    case "vehicle-duty-calculator":
      currentPage = <VehicleDutyCalculator />;
      break;
    case "health-facilities":
      currentPage = <HealthFacility />;
      break;

    default:
      currentPage = <ErrorSection />;
      break;
  }

  return <div className="service-page">{currentPage}</div>;
};

export default ServicePageLayout;
