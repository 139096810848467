import React, { useEffect, useState } from "react";
import { Container, Tooltip } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import { Grid } from "@mui/material";
import "./SchemeMdaBoxGrid.css";
import { replaceSpaceWithDash } from "../../../utils/custom";
import { useTranslation } from "react-i18next";

function SchemeMdaBoxGrid(props) {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const currentUrl = window.location.hash.split("/");
  const serviceName = currentUrl[1];

  const navigate = useNavigate();

  useEffect(() => {
    // setTimeout(() => {

    const apiData = props?.boxGridApi?.list || [];
    setData(apiData);
    setLoading(false);
    // }, 3000);
  }, [props.boxGridApi]);
  console.log(props);

  return (
    <Container maxWidth="lg">
      <Grid container spacing={2}>
        {data.length !== 0 ? (
          props?.boxGridApi?.list?.map((item) => (
            <Grid
              item
              xs={12}
              sm={6}
              md={4}
              lg={4}
              className="grid-boxes"
              key={item.id}
              id={item.id}
            >
              {props.detail ? (
                <Link to={`/${replaceSpaceWithDash(item.name)}/mda/${item.id}`}>
                  <div className="category-outer-box">
                    <div className="left-img">
                      <img
                        src={`${process.env.REACT_APP_HOST_URL}${process.env.REACT_APP_IMAGE_URL}${item.icon}`}
                        alt={item.name}
                      />
                    </div>
                    <div className="right-cont">
                      <Tooltip title={item.name} placement="top-start">
                        <h4>{item.name}</h4>
                      </Tooltip>
                      {/* <p>{item.description}</p> */}
                      {/* <Link to={`/services/${service.type}/${item.id}`} target="_blank"><img src={redArrow} alt="Proceed"/></Link> */}
                    </div>
                  </div>
                </Link>
              ) : (
                <div style={{ cursor: 'pointer' }}
                    onClick={() => {
                      if (item.id === 1 || item.id === 2 || item.id === 3 || item.id === 4 || item.id === 5) {
                        navigate(`/detail/mda/${serviceName}/${replaceSpaceWithDash(item.name)}`, { state: { anonymous_user: item.anonymous_user, } })
                      } }} >
                  <div className="category-outer-box">
                    <div className="left-img">
                      <img
                        src={`${process.env.REACT_APP_HOST_URL}${process.env.REACT_APP_IMAGE_URL}${item.icon}`}
                        alt={item.name}
                      />
                    </div>
                    <div className="right-cont">
                      <Tooltip title={item.name} placement="top-start">
                        <h4>{item.name}</h4>
                      </Tooltip>
                      {/* <p>{item.description}</p> */}
                      {/* <Link to={`/services/${service.type}/${item.id}`} target="_blank"><img src={redArrow} alt="Proceed"/></Link> */}
                    </div>
                  </div>
                </div>
              )}
            </Grid>
          ))
        ) : (
          <div className="no-data-found">
            <br />
            {t("no data text")}
            <br />
          </div>
        )}
      </Grid>
    </Container>
  );
}

export default SchemeMdaBoxGrid;
