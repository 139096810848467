import React, { useState } from 'react';
import { Box, Container } from '@mui/material';
import Grid from "@mui/material/Grid";
import FormControl from "@mui/material/FormControl";
import LoadingButton from '@mui/lab/LoadingButton';
import right from '../../../assets/Img/right-arrow.png'
import left from '../../../assets/Img/left.png'
import PropTypes from 'prop-types';
import Dialog from '@mui/material/Dialog';
import download from'../../../assets/Img/download.png';
import tick from '../../../assets/Img/tick.png';
import './vax.css';
import { useTranslation } from 'react-i18next';

const Vax = (props) => {

  const { handleTabChange, value } = props;
  console.log("Current value:", value);
  const { t } = useTranslation();

  const [openDialog, setOpenDialog] = useState(false);
  const handleDialogOpen = () => {
    setOpenDialog(true);
  };

  const handleDialogClose = () => {
    setOpenDialog(false);
  };


  Vax.propTypes = {
    handleTabChange: PropTypes.func.isRequired,
    value: PropTypes.number.isRequired,
  };

    const [btnLoading, setBtnLoading] = useState(false);
  const [vaccineData, setVaccineData] = useState([
    {
      id: 1,
      name: "COVID-19 Vaccine",
      checked: false,
    },
    {
      id: 2,
      name: "Yellow Fever Vaccinated",
      checked: false,
    },
  ]);

  const handleVaccineChange = (id) => {
    const updatedData = vaccineData.map(item => {
      if (item.id === id) {
        return { ...item, checked: !item.checked };
      }
      return item;
    });
    setVaccineData(updatedData);
  };


 
  const [symptomData, setSymptomData] = useState([
    {
      id: 1,
      name: "Fever",
    },
    {
      id: 2,
      name: "Cough",
    },
    {
      id: 3,
      name: "Headache",
    },
    {
      id: 4,
      name: "Diarrhea",
    },
    {
      id: 5,
      name: "Difficulty Breathing",
    },
    {
      id: 6,
      name: "Fatigue",
    },
    {
      id: 7,
      name: "Sore Throat",
    },
    {
      id: 8,
      name: "Muscle & Joint pain",
    },
    {
      id: 9,
      name: "An Unexplained Bleeding",
    },
    {
      id: 10,
      name: "Skin Rash",
    }
  ]);

  const handleSymptomChange = (id) => {
    const updatedData = symptomData.map(item => {
      if (item.id === id) {
        return { ...item, checked: !item.checked };
      }
      return item;
    });
    setSymptomData(updatedData);
  };

  const validateForm = () => {
    if (!vaccineData.some(item => item.checked)) {
      console.log('Please select at least one vaccine option.');
      return false;
    }
  
    if (!symptomData.some(item => item.checked)) {
      console.log('Please select at least one symptom option.');
      return false;
    }
  
    return true; 
  };
  

  return (
    <div className='form-section2'>
      <Container maxWidth="lg">
        <Box sx={{ flexGrow: 1 }} className="box-border">
          <div className="vax-information">
            <h2 className='title'>{t("Vaccination status?")}</h2>
            <div className="box-content2">
              <form method="post" name="vaxinformationForm" className="form-sec">
                <ul className='list'>
                {vaccineData.map(item => (
                    <li className={item.checked ? 'checked' : ''}>
                      <FormControl className="form-group">
                        <label className="container">
                          <input
                            className="round-checkbox"
                            type="checkbox"
                            checked={item.checked}
                            onChange={() => handleVaccineChange(item.id)}
                          />
                          <span className="checkmark"></span>
                          {item.name}
                        </label>
                      </FormControl>
                    </li>
                  ))}
                </ul>
              </form>
            </div>
          </div>

          <div className="vax-information">
            <h2 className='title'>{t("Have you had any of these symptoms today or in the past 24 hours?")}</h2>
            <div className="box-content3">
              <form method="post" name="vaxinformationForm" className="form-sec">
                <ul className='list'>
                  {symptomData.map(item => (
                    <li key={item.id} className={item.checked ? 'checked' : ''}>
                      <FormControl className="form-group">
                        <label className="container">
                          <input
                            className="round-checkbox"
                            type="checkbox"
                            checked={item.checked}
                            onChange={() =>  handleSymptomChange(item.id)}
                          />
                          <span className="checkmark"></span>
                          {item.name}
                        </label>
                      </FormControl>
                    </li>
                    ))}
                </ul>
              </form>
            </div>
          </div>
        </Box>

        
        <div className="submit">
              {/* Previous and Next buttons */}
        <LoadingButton className="btn1" loading={btnLoading} loadingIndicator="Loading…" onClick={() => handleTabChange(value - 1)}>
        <span className="previous"><img src={left} className="right" alt="previous" />&nbsp;&nbsp;Previous</span>
      </LoadingButton>
      <LoadingButton
      className="btn"
      onClick={() => {
        // if (validateForm()) {
          handleDialogOpen(); // Proceed to the next step
        // }
      }}
      loading={btnLoading}
      loadingIndicator="Loading…"
    >
      <span className="next">
              {t("Next")}&nbsp;&nbsp; <img src={right} className="right" alt="next" />
      </span>
    </LoadingButton>
       </div>
      </Container>
       {/* Define the dialog */}
       <Dialog open={openDialog} onClose={handleDialogClose}  backdrop="static" className='dia'>
       <div className="dialog-content">
       <div className='bor'>
       <img src={tick} className='tick'/>
       </div>
       
          <h4>{t("Submission Success")}</h4>
         <img src="https://tse2.mm.bing.net/th?id=OIP.-e6wZbEeF40nNIBhXeREngHaH_&pid=Api&P=0&h=180" className='qr-code'></img>
         <div className='white'>
            <p><span className='ref'>{t("Ref No") + 87827827267}</span> {t("your traveler declaration form")}</p>
            <p>{t("has been submitted Successfully")}</p>
         </div>
          <button> <img src={download} className='down-img'></img>{t("Download")}</button>
        
      
   
     </div>
     </Dialog>
    </div>
  );
}

export default Vax;
