import React, { useState, useEffect, useContext, useRef } from "react";
import axios from "axios";
import { GET_USER_DETAILS, UPDATE_PROFILE, NIN_NO_VALIDATE } from "../../../utils/api-constant";
import { useNavigate, useLocation } from "react-router-dom";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import FormGroup from "@mui/material/FormGroup";
import FormLabel from "@mui/material/FormLabel";
import Button from "@mui/material/Button";
import LoadingButton from "@mui/lab/LoadingButton";
import TextField from "@mui/material/TextField";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import dayjs from "dayjs";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import "./ProfileInformation.css";
import { countries } from "../../../Files/Country";
import FormLoader from "../../Loader/FormLoader";
import Alert from "../../Alert/Alert";
import AuthContext from "../../../context/AuthContext";
import { isMobile, isNin } from "../../../utils/custom";
import { useTranslation } from "react-i18next";

const Register = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const authCtx = useContext(AuthContext);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [middleName, setMiddleName] = useState("");
  const [countryName, setCountryName] = useState("");
  const [countryList, setCountryList] = useState([]);
  const [ninNumber, setNinNumber] = useState("");
  const [passportNumber, setPassportNumber] = useState("");
  const [mobileNumber, setMobileNumber] = useState("");
  const [dob, setDob] = useState(null);
  const [gender, setGender] = useState("");
  const [eMail, setEMail] = useState("");

  const [RegResp, setRegResponse] = useState("");

  const [firstNameErr, setFirstNameErr] = useState("");
  const [lastNameErr, setLastNameErr] = useState("");
  const [mobileNumberErr, setMobileNumberErr] = useState("");
  const [dobErr, setDobErr] = useState("");
  const [genderErr, setGenderErr] = useState("");
  const [countryNameErr, setCountryNameErr] = useState("");
  const [ninNumberErr, setNinNumberErr] = useState("");
  const [passportNumberErr, setPassportNumberErr] = useState("");

  const [apiToken, setApiToken] = useState(null);
  const [loginDetails, setLoginDetails] = useState([]);
  const location = useLocation();
  const [btnLoading, setBtnLoading] = useState(false);
  const [formLoad, setFormLoad] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [alertType, setAlertType] = useState(false);
  const [middleNameErr, setMiddleNameErr] = useState("");
  const itemRef = useRef(null);

  useEffect(() => {
    if (
      localStorage.getItem("apiToken") !== "undefined" &&
      localStorage.getItem("apiToken") !== "" &&
      localStorage.getItem("apiToken") !== null
    ) {
      setFormLoad(true);
      let apiToken = JSON.parse(localStorage.getItem("apiToken"));
      let loginData = JSON.parse(localStorage.getItem("userDetails"));
      setApiToken(apiToken);
      setLoginDetails(loginData);
      getUserDetails(apiToken, 1);
    }
    //console.log(isMobile());
    if (isMobile()) {
      if (itemRef.current) {
        itemRef.current.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }, [location]);

  function getUserDetails(apiToken, from) {
    const params = {
      headers: {
        needJson: 1,
        apiToken: apiToken,
        "Content-Type": "application/json",
      },
    };
    axios
      .get(process.env.REACT_APP_HOST_URL + GET_USER_DETAILS, params)
      .then((response) => {
        if (response.data.success) {
          updateForm(response.data.userDetails);
          localStorage.removeItem("userDetails");
          localStorage.setItem(
            "userDetails",
            JSON.stringify(response.data.userDetails)
          );
          if(from === 2){
            window.location.reload()
          }
        }
      });
  }

  function updateForm(loginData) {
    setFirstName(loginData.fname);
    setLastName(loginData.lname);
    setMiddleName(loginData.sur_name);
    setMobileNumber(loginData.phone);
    setDob(loginData.date_of_birth);
    setGender(loginData.gender);
    setEMail(loginData.email);
    setCountryName(loginData.nationality);
    setNinNumber(loginData.nin_number);
    setPassportNumber(loginData.passport_number);
    setFormLoad(false);
  }

  function updateProfile(formIsValid) {
    const headers = {
      needJson: 1,
      apiToken: apiToken,
      "Content-Type": "application/json",
    };
    //console.log("formIsValid--> " + formIsValid);
    if (formIsValid) {
      setBtnLoading(true);
      axios
        .post(
          process.env.REACT_APP_HOST_URL + UPDATE_PROFILE + loginDetails.id,
          {
            fname: firstName,
            lname: lastName,
            sur_name: middleName,
            nationality: countryName,
            nin_number: ninNumber,
            passport_number: passportNumber,
            phone: mobileNumber,
            date_of_birth: dob,
            gender: gender,
            email: eMail,
          },
          {
            headers: headers,
          }
        )
        .then((response) => {
          //console.log(response.data.message);
          setRegResponse(response.data.message);
          setAlertType(response.data.success);
          if (response.data.success) {
            getUserDetails(apiToken, 2);
          }
          setShowAlert(true);
          setBtnLoading(false);
        });
    }
  }

  const formetDateTime = (value) => {
    var date = new Date(value);
    const yyyy = date.getFullYear();
    let mm = date.getMonth() + 1;
    let dd = date.getDate();

    if (dd < 10) dd = "0" + dd;
    if (mm < 10) mm = "0" + mm;
    const formattedToday = yyyy + "-" + mm + "-" + dd;
    return formattedToday;
  };

  function validateForm(e) {
    e.preventDefault();
    let formIsValid = true;
    if (!firstName) {
      formIsValid = false;
      setFirstNameErr(t("required alert"));
    } else {
      setFirstNameErr("");
    }
    if (!lastName) {
      formIsValid = false;
      setLastNameErr(t("required alert"));
    } else {
      setLastNameErr("");
    }
    if (!countryName) {
      formIsValid = false;
      setCountryNameErr(t("required alert"));
    } else {
      setCountryNameErr("");
    }
    
    if (!mobileNumber) {
      formIsValid = false;
      setMobileNumberErr(t("required alert"));
    } else if (mobileNumber.length !== 10) {
      console.log("mobileNumber--> " + mobileNumber.length);
      formIsValid = false;
      setMobileNumberErr(t("Please enter the valid mobile number"));
    } else {
      setMobileNumberErr("");
    }
    if (!dob) {
      formIsValid = false;
      setDobErr(t("required alert"));
    } else {
      setDobErr("");
    }
    if (!gender) {
      formIsValid = false;
      setGenderErr(t("required alert"));
    } else {
      setGenderErr("");
    }
    if (countryName === "MALAWI") {
      if (!ninNumber) {
        formIsValid = false;
        setNinNumberErr(t("required alert"));
      } else {
        setNinNumberErr("");
        setBtnLoading(true);
        NRBValidation(formIsValid);
      }
    } else {
      if (!passportNumber) {
        formIsValid = false;
        setPassportNumberErr(t("required alert"));
      } else {
        setPassportNumberErr("");
        updateProfile(formIsValid);
      }
    }
  }

  function NRBValidation(formIsValid){
    console.log("formIsValid")
    const params = {
      headers: {
        "Content-Type": "application/json",
        needJson: 1,
      },
    };
    axios
      .get(
        process.env.REACT_APP_HOST_URL + NIN_NO_VALIDATE + ninNumber,
        params
      )
      .then((res) => {
        console.log(res.data);
        if (res.data.success) {
          if (res.data.list.length > 0) {
            if (firstName.trim().toLocaleLowerCase() === res.data.list[0].firstname.trim().toLocaleLowerCase() 
              && lastName.trim().toLocaleLowerCase() === res.data.list[0].surname.trim().toLocaleLowerCase() 
              && gender === res.data.list[0].gender
              && dob === formetDateTime(res.data.list[0].dateobirth)) {
              setFirstNameErr('');
              setLastNameErr('');
              setGenderErr('');
              setDobErr('');
              if (res.data.list[0].othernames != null && res.data.list[0].othernames !== '') {
                if (middleName.trim().toLocaleLowerCase() === res.data.list[0].othernames.trim().toLocaleLowerCase()) {
                  setMiddleNameErr('');
                  updateProfile(formIsValid);
                } else {
                  setBtnLoading(false);
                  if (middleName.trim().toLocaleLowerCase() !== res.data.list[0].othernames.trim().toLocaleLowerCase()) {
                    setMiddleNameErr(t("middle not match alert"));
                  } else {
                    setMiddleNameErr('');
                  }
                }
              } else {
                updateProfile(formIsValid);
              }
            } else {
              setBtnLoading(false);
              formIsValid = false;
              if (firstName.trim().toLocaleLowerCase() !== res.data.list[0].firstname.trim().toLocaleLowerCase()) {
                setFirstNameErr(t("firstname not match alert"));
              } else {
                setFirstNameErr('');
              }
              if (lastName.trim().toLocaleLowerCase() !== res.data.list[0].surname.trim().toLocaleLowerCase()) {
                setLastNameErr(t("lastname not match alert"));
              } else {
                setLastNameErr('');
              }
              if (gender !== res.data.list[0].gender) {
                setGenderErr(t("gender not match alert"));
              } else {
                setGenderErr('');
              }
              if (dob !== formetDateTime(res.data.list[0].dateobirth)) {
                setDobErr(t("DOB not match alert"));
              } else {
                setDobErr('');
              }
            }
          } else {
            setBtnLoading(false);
            formIsValid = false;
          }
        } else {
          setBtnLoading(false);
          formIsValid = false;
          setNinNumberErr(t("valid national id alert"));
        }
      })
      .catch((e) => {
        console.log(e.message);
        setBtnLoading(false);
      });
  }

  // Restrict symbols in number
  const exceptThisSymbols = ["e", "E", "+", "-", "."];

  // input validation
  const validateFirstName = (e) => {
    var firstNameVal = e.target.value;
    setFirstName(firstNameVal);
    if (firstNameVal !== "") {
      setFirstNameErr("");
    } else {
      setFirstNameErr(t("required alert"));
    }
  };
  const validateLastName = (e) => {
    var lastNameVal = e.target.value;
    setLastName(lastNameVal);
    if (lastNameVal !== "") {
      setLastNameErr("");
    } else {
      setLastNameErr(t("required alert"));
    }
  };
  const validateMobileNo = (e) => {
    var mobileNoVal = e.target.value;
    setMobileNumber(mobileNoVal);
  
    if (mobileNoVal !== "") {
      if(countryName == "MALAWI")
      {
          if (/^\d{10}$/.test(mobileNoVal)) {  // Check if the input is exactly 10 digits
            setMobileNumberErr("");
          } else {
            setMobileNumberErr(t("Please enter a valid 10-digit mobile number"));
          }
      }else{
        if (/^\d{7,15}$/.test(mobileNoVal)) {  // Check if the input is between 7 and 15 digits
          setMobileNumberErr("");
        } else {
          setMobileNumberErr(t("Please enter a valid mobile number with 7 to 15 digits"));
        }
      }      
    } else {
      setMobileNumberErr(t("required alert"));
    }
  };

  const validateGender = (e) => {
    setGender(e.target.value);
    setGenderErr("");
  };

  const handleModelChange = (e) => {
    setCountryName(e.target.value);
    setCountryNameErr("");
  };
  const validateDob = (e) => {
    setDob(e.format("YYYY-MM-DD"));
    setDobErr("");
  };
  const validateNin = (value) => {
    setNinNumber(value);
    if (value !== "") {
      setNinNumberErr("");
    } else {
      setNinNumberErr(t("required alert"));
    }
  };  

  document.body.onkeydown = function (e) {
    if (e.key === "Enter") {
      validateForm(e);
    }
  };
  useEffect(() => {
    setCountryList(countries);
  }, []);

  useEffect(() => {
    if (!authCtx.isLogin) {
      navigate("/");
    }
  }, [authCtx.isLogin]);
  return (
    <section ref={itemRef} className="ProfileInformationUI">
      <Box sx={{ flexGrow: 1 }}>
        <div
          className={`account-formSection ${formLoad ? "form-loading" : ""}`}
        >
          <Alert
            setShow={setShowAlert}
            show={showAlert}
            msg={RegResp}
            type={alertType}
          />
          <h3 className="head-h3">{t("profile info text")}</h3>
          <form method="post" name="userRegistrationForm">
            <Grid container columnSpacing={{ xs: 0, sm: 4 }}>
              <Grid item xs={12} sm={6} md={6}>
                <FormGroup className="form-group">
                  <FormLabel required>{t("first name label")}</FormLabel>
                  <TextField
                    className="form-control"
                    type="text"
                    size="small"
                    value={firstName}
                    onChange={(e) => validateFirstName(e)}
                  />
                  <div className="errorMsg">{firstNameErr}</div>
                </FormGroup>
              </Grid>
              <Grid item xs={12} sm={6} md={6}>
                <FormGroup className="form-group">
                  <FormLabel required>{t("last name label")}</FormLabel>
                  <TextField
                    className="form-control"
                    type="text"
                    size="small"
                    value={lastName}
                    onChange={(e) => validateLastName(e)}
                  />
                  <div className="errorMsg">{lastNameErr}</div>
                </FormGroup>
              </Grid>
              <Grid item xs={12} sm={6} md={6}>
                <FormGroup className="form-group">
                  <FormLabel>{t("middle name label")}</FormLabel>
                  <TextField
                    className="form-control"
                    type="text"
                    size="small"
                    value={middleName}
                    onChange={(event) => setMiddleName(event.target.value)}
                  />
                  <div className="errorMsg">{middleNameErr}</div>
                </FormGroup>
              </Grid>
              <Grid item xs={12} sm={6} md={6}>
                <FormGroup className="form-group">
                  <FormLabel required>{t("nationality label")}</FormLabel>
                  <Select
                    value={countryName}
                    size="small"
                    onChange={handleModelChange}
                  >
                    <MenuItem value="">
                      <em>{t("choose a country option")}</em>
                    </MenuItem>
                    {countryList.map((e, key) => {
                      return (
                        <MenuItem key={key} value={e.label}>
                          {e.label}
                        </MenuItem>
                      );
                    })}
                  </Select>
                  <div className="errorMsg">{countryNameErr}</div>
                </FormGroup>
              </Grid>

              {countryName === "MALAWI" ? (
                <Grid item xs={12} sm={6} md={6}>
                  <FormGroup className="form-group">
                    <FormLabel required>{t("national id label")}</FormLabel>
                    <TextField
                      className="form-control"
                      type="text"
                      size="small"
                      value={ninNumber}
                      onKeyDown={(e) =>
                        exceptThisSymbols.includes(e.key) && e.preventDefault()
                      }
                      onChange={(event) => validateNin(event.target.value)}
                    />
                    <div className="errorMsg">{ninNumberErr}</div>
                  </FormGroup>
                </Grid>
              ) : null}
              {countryName !== "MALAWI" && countryName !== "" ? (
                <Grid item xs={12} sm={6} md={6}>
                  <FormGroup className="form-group">
                    <FormLabel required>{t("passport no label")}</FormLabel>
                    <TextField
                      className="form-control"
                      type="text"
                      size="small"
                      value={passportNumber}
                      onKeyDown={(e) =>
                        exceptThisSymbols.includes(e.key) && e.preventDefault()
                      }
                      onChange={(event) =>
                        setPassportNumber(event.target.value)
                      }
                    />
                    <div className="errorMsg">{passportNumberErr}</div>
                  </FormGroup>
                </Grid>
              ) : null}
              <Grid item xs={12} sm={6} md={6}>
                <FormGroup className="form-group">
                  <FormLabel required>{t("mobile no label")}</FormLabel>
                  <TextField
                    className="form-control"
                    type="number"
                    size="small"
                    onKeyDown={(e) =>
                      exceptThisSymbols.includes(e.key) && e.preventDefault()
                    }
                    value={mobileNumber}
                    onChange={(e) => validateMobileNo(e)}
                  />
                  <div className="errorMsg">{mobileNumberErr}</div>
                </FormGroup>
              </Grid>
              <Grid item xs={12} sm={6} md={6}>
                <FormGroup className="form-group">
                  <FormLabel required>{t("DOB label")}</FormLabel>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DesktopDatePicker
                      className="date-pick"
                      format="DD/MM/YYYY"
                      value={dayjs(dob)}
                      onChange={(e) => validateDob(e)}
                      disableFuture={true}
                    />
                  </LocalizationProvider>
                  <div className="errorMsg">{dobErr}</div>
                </FormGroup>
              </Grid>
              <Grid item xs={12} sm={6} md={6}>
                <FormGroup className="form-group">
                  <FormLabel required>{t("gender label")}</FormLabel>
                  <Select
                    value={gender}
                    size="small"
                    onChange={(e) => validateGender(e)}
                  >
                    <MenuItem value="Male">{t("male option")}</MenuItem>
                    <MenuItem value="Female">{t("female option")}</MenuItem>
                    <MenuItem value="Other">{t("other option")}</MenuItem>
                  </Select>
                  <div className="errorMsg">{genderErr}</div>
                </FormGroup>
              </Grid>
              <Grid item xs={12} sm={6} md={6}>
                <FormGroup className="form-group">
                  <FormLabel required>{t("email label")}</FormLabel>
                  <TextField
                    className="form-control"
                    type="text"
                    size="small"
                    value={eMail}
                    disabled
                  />
                </FormGroup>
              </Grid>
              <div className="btn-group">
                <Button className="btn btn-cancel">{t("cancel text")}</Button>
                <LoadingButton
                  className="btn btn-submit"
                  onClick={validateForm}
                  loading={btnLoading}
                  loadingIndicator="Loading…"
                >
                  <span>{t("submit small text")}</span>
                </LoadingButton>
              </div>
            </Grid>
          </form>
          <FormLoader />
        </div>
      </Box>
    </section>
  );
};

export default Register;
