import React, { useEffect } from "react";
import './NoAccess.css';
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

export default function PageNotFound ({setShowHeader, setShowFooter}) {

  const { t } = useTranslation();
  const navigate = useNavigate();
  useEffect(() => {
    setShowHeader(false);
    setShowFooter(false);
  });
  function redirectHome() {
    setShowHeader(true);
    setShowFooter(true);
    navigate("/");
  }
  return (
    <div className='NoAccess'>
      <h1 className="heading-404">{t("404 Not Found")}</h1>
      <button onClick={redirectHome}>{t("Go back to Home")}</button>
    </div>
  )
}
