import React, { useState, useEffect } from "react";
import { Box, Container } from '@mui/material'
import Grid from "@mui/material/Grid";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import TextField from "@mui/material/TextField";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import validator from "validator";
import right from '../../../assets/Img/right-arrow.png';
import leftarrow from '../../../assets/Img/arrow-left.svg'
import LoadingButton from '@mui/lab/LoadingButton';
import {districts} from '../../../Files/district';

import PropTypes from 'prop-types';
import './contact.css';
import { useTranslation } from "react-i18next";

const Contactform = (props) => {
  
  const { handleTabChange, value } = props;
  const { t } = useTranslation();

  Contactform.propTypes = {
    handleTabChange: PropTypes.func.isRequired,
    value: PropTypes.number.isRequired,
  };

    const [btnLoading, setBtnLoading] = useState(false);
    const [malawiResidentialAddress, setMalawiResidentialAddress] = useState("");
    const [MalawiResidencialAddressErr, setMalawiResidencialAddressErr] = useState("");
    const [taLocation, setTALocation] = useState("");
    const [TALocationErr, setTALocationErr] = useState("");
    const [mobileNumber, setMobileNumber] = useState("");
    const [mobileNumberErr, setMobileNumberErr] = useState("");
    const [eMail, setEMail] = useState("");
    const [eMailErr, setEMailErr] = useState("");
    const [nearestLandmark, setNearestLandmark] = useState("");
    const [nearestLandmarkErr, setNearestLandmarkErr] = useState("");
    const [selectedDistrict, setSelectedDistrict] = useState("");
    const [districtOptions, setDistrictOptions] = useState([]);    
    const [districtErr, setDistrictErr] = useState("");
    const [nextOfKinLocalEmergency, setNextOfKinLocalEmergency] = useState("");
    const [nextOfKinLocalEmergencyErr, setNextOfKinLocalEmergencyErr] = useState("");
    const [nextOfKinTelephoneNumber, setNextOfKinTelephoneNumber] = useState("");
    const [nextOfKinTelephoneNumberErr, setNextOfKinTelephoneNumberErr] = useState("");
 

  function validateForm() {
    let formIsValid = true;
   
    if (!malawiResidentialAddress) { formIsValid = false; setMalawiResidencialAddressErr("* Required."); } else {setMalawiResidencialAddressErr(""); }

    if (!taLocation) { formIsValid = false; setTALocationErr("* Required."); } else { setTALocationErr(""); }
    if (!mobileNumber) { formIsValid = false; setMobileNumberErr("* Required."); } else { setMobileNumberErr(""); }
    if (!eMail) { formIsValid = false; setEMailErr("* Required."); } else { setEMailErr(""); }
    if (!nearestLandmark) { formIsValid = false; setNearestLandmarkErr("* Required."); } else { setNearestLandmarkErr(""); }

    if (!selectedDistrict) { formIsValid = false; setDistrictErr("* Required."); } else { setDistrictErr(""); }
    if (!nextOfKinLocalEmergency) { formIsValid = false; setNextOfKinLocalEmergencyErr("* Required."); } else { setNextOfKinLocalEmergencyErr(""); }

    if (!nextOfKinTelephoneNumber) { formIsValid = false; setNextOfKinTelephoneNumberErr("* Required."); } else {setNextOfKinTelephoneNumberErr(""); }
   
    //  if (formIsValid) {
    //   alert("Successfully");
    //   setTimeout(() => {
    //     window.location.href = '/';
    //   }, 1000); 
    // } else {
    //   console.error("error");
    // }
  }
  useEffect(() => {
    
    if (districts) {
      setDistrictOptions(districts);     
    }
  }, []);

  // input validation

  const exceptThisSymbols = ['symbol1', 'symbol2']; 
  const validateMobileNo = (e) => {
    var mobileNoVal = e.target.value;
    setMobileNumber(mobileNoVal);
    if (mobileNoVal !== "") {
      setMobileNumberErr("");
    } else {
      setMobileNumberErr("* Required.");
    }
  };
  const validateEmail = (e) => {
    var email = e.target.value;
    setEMail(email);
    if (email !== "") {
      if (validator.isEmail(email)) {
        setEMailErr("");
      } else {
        setEMailErr("Please enter the valid Email!");
      }
    } else {
      setEMailErr("* Required.");
    }
  };
  
  const validateMalawiResidentialAddress = (e) => {
    const address = e.target.value;
    setMalawiResidentialAddress(address);
    if (address !== "") {
      setMalawiResidencialAddressErr("");
    } else {
      setMalawiResidencialAddressErr("* Required.");
    }
  };
  
  const validateTALocation = (e) => {
    const location = e.target.value;
    setTALocation(location);
    if (location !== "") {
      setTALocationErr("");
    } else {
      setTALocationErr("* Required.");
    }
  };

  const validateNearestLandmark = (e) => {
    const landmark = e.target.value;
    setNearestLandmark(landmark);
    if (landmark !== "") {
      setNearestLandmarkErr("");
    } else {
      setNearestLandmarkErr("* Required.");
    }
  };
  
  const validateDistrict = (e) => {
    const selectedDistrict = e.target.value;
   setSelectedDistrict(selectedDistrict);
    if ( selectedDistrict !== "") {
      setDistrictErr("");
    } else {
      setDistrictErr("* Required.");
    }
  };
  
  const validateNextOfKinLocalEmergency = (e) => {
    const nextOfKinLocalEmergency = e.target.value;
    setNextOfKinLocalEmergency(nextOfKinLocalEmergency);
    if (nextOfKinLocalEmergency !== "") {
      setNextOfKinLocalEmergencyErr("");
    } else {
      setNextOfKinLocalEmergencyErr("* Required.");
    }
  };
  
  const validateNextOfKinTelephoneNumber = (e) => {
    const nextOfKinTelephoneNumber = e.target.value;
    setNextOfKinTelephoneNumber(nextOfKinTelephoneNumber);
    if (nextOfKinTelephoneNumber !== "") {
      setNextOfKinTelephoneNumberErr("");
    } else {
      setNextOfKinTelephoneNumberErr("* Required.");
    }
  };
  
  return (
    <div className='form-section1'>
      <Container maxWidth="lg">
   
    
        <Box sx={{ flexGrow: 1 }} className="box-border">
     
            <div className="contact-information">
            <h2 className='title'>{t("Contact Information")}</h2>
            <div className="box-content1">
           
           
              <form method="post" name="personalinformationForm" className="form-sec">
                <Grid container spacing={3}>
                  <Grid item xs={12} sm={6} md={3} lg={3} className="grid-boxes">
                    <FormControl className="form-group">
                      <FormLabel className="label" required>{t("Malawi Residencial Address")}</FormLabel>
                      <TextField
                      className="Name"
                      type="text"
                      size="small"
                      value={malawiResidentialAddress}
                      onChange={(e) => validateMalawiResidentialAddress(e)}
                        placeholder={t("Enter Malawi Residential Address")}
                      autoComplete="off"
                    />
                    <div className="errorMsg">{MalawiResidencialAddressErr}</div>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={6} md={3} lg={3} className="grid-boxes">
                    <FormControl className="form-group">
                      <FormLabel className="label" required>{t("T/A Location")}</FormLabel>
                      <TextField
                      className="Name"
                      type="text"
                      size="small"
                      value={taLocation}
                      onChange={(e) => validateTALocation(e)}
                        placeholder={t("Enter T/A Location")}
                      autoComplete="off"
                    />
                    <div className="errorMsg">{TALocationErr}</div>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={6} md={3} lg={3} className="grid-boxes">
                    <FormControl className="form-group">
                      <FormLabel className="label" required>{t("mobile no label")}</FormLabel>
                      <TextField
                        className="form-control"
                        type="number"
                        size="small"
                        onKeyDown={(e) =>
                          exceptThisSymbols.includes(e.key) && e.preventDefault()
                        }
                        value={mobileNumber}
                        onChange={(e) => validateMobileNo(e)}
                        placeholder={t("Enter Mobile Number")}
                      />
                      <div className="errorMsg">{mobileNumberErr}</div>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={6} md={3} lg={3} className="grid-boxes">
                    <FormControl className="form-group">
                      <FormLabel className="label" required>{t("email label")}</FormLabel>
                    <TextField
                      className="form-control"
                      type="text"
                      size="small"
                      value={eMail}
                      onChange={(e) => validateEmail(e)}
                        placeholder={t("Enter Your Email")}
                    />
                    <div className="errorMsg">{eMailErr}</div>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={6} md={3} lg={3} className="grid-boxes">

                    <FormControl className="form-group">
                      <FormLabel className="label" required="country-select">{t("Nearest Land Mark")}</FormLabel>
                      <TextField
                      className="form-control"
                      type="text"
                      size="small"
                      value={nearestLandmark}
                      onChange={(e) => validateNearestLandmark(e)}
                        placeholder={t("Enter Nearest Land Mark")}
                    />
                    <div className="errorMsg">{nearestLandmarkErr}</div>
                    </FormControl>
                  </Grid>

                  <Grid item xs={12} sm={6} md={3} lg={3} className="grid-boxes">
                 
                  <FormControl className="form-group">
                      <FormLabel className="label" required>{t("district label")}</FormLabel>
                  <Select
                  className="select-field"
                  value={selectedDistrict}
                  size="small"
                  onChange={(e) => validateDistrict(e)} 
                  displayEmpty
                  inputProps={{
                    name: 'district',
                    id: 'district-select',
                  }}
                >
                  <MenuItem value="" disabled>
                          <em>{t("district option")}</em>
                  </MenuItem>
                  {districtOptions.map((option) => (
                    <MenuItem key={option.code} value={option.label}>
                      {option.label}
                    </MenuItem>
                    ))}
                  
                </Select>
                
                  <div className="errorMsg">{districtErr}</div>
                </FormControl>
              </Grid>
               

                  <Grid item xs={12} sm={6} md={3} lg={3} className="grid-boxes">
                    <FormControl className="form-group">
                      <FormLabel className="label" required>{t("Next Of Kin/Local Emergency")}</FormLabel>
                      <TextField
                      className="Name"
                      type="text"
                      size="small"
                      value={nextOfKinLocalEmergency}
                      onKeyDown={(e) =>
                        exceptThisSymbols.includes(e.key) &&
                        e.preventDefault()
                      }
                      onChange={(e) => validateNextOfKinLocalEmergency(e)}
                        placeholder={t("Enter Next Of Kin/Local Emergency")}
                      autoComplete="off"
                    />
                    <div className="errorMsg">{nextOfKinLocalEmergencyErr}</div>
                    </FormControl>
                  </Grid>

                  <Grid item xs={12} sm={6} md={3} lg={3} className="grid-boxes">
                    <FormControl className="form-group">
                      <FormLabel className="label" required>{t("Next Of Kin/Telephone Number")}</FormLabel>
                      <TextField
                      className="Name"
                      type="text"
                      size="small"
                      value={nextOfKinTelephoneNumber}
                      onKeyDown={(e) =>
                        exceptThisSymbols.includes(e.key) &&
                        e.preventDefault()
                      }
                      onChange={(e) => validateNextOfKinTelephoneNumber(e)}
                        placeholder={t("Enter Next Of Kin/Telephone Number")}
                      autoComplete="off"
                    />
                    <div className="errorMsg">{nextOfKinTelephoneNumberErr}</div>   
                    </FormControl>
                  </Grid>
                </Grid>
              </form>
            </div>
          </div>
      
          </Box>

         
          <div className="submit">
           {/* Previous and Next buttons */}
        <LoadingButton className="btn1" loading={btnLoading} loadingIndicator="Loading…" onClick={() => handleTabChange(value - 1)}>
            <span className="previous"><img src={leftarrow} className="left" alt="previous" />&nbsp;&nbsp;{t("Previous")}</span>
      </LoadingButton>
      <LoadingButton
      className="btn"
      onClick={() => {
        // if (validateForm()) {
          handleTabChange(value + 1); 
        // }
      }}
      loading={btnLoading}
      loadingIndicator="Loading…"
    >
      <span className="next">
              {t("Next")}&nbsp;&nbsp; <img src={right} className="right" alt="next" />
      </span>
    </LoadingButton>
  
          </div>
       
    </Container>
    </div>
  )
}

export default Contactform
