import React, { useState, useEffect, useContext, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import { CHANGE_MAIL, CHANGE_MAIL_ID } from "../../../utils/api-constant";
import FormGroup from "@mui/material/FormGroup";
import FormLabel from "@mui/material/FormLabel";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import validator from "validator";
import LoadingButton from "@mui/lab/LoadingButton";
import FormLoader from "../../Loader/FormLoader";
import Alert from "../../Alert/Alert";
import AuthContext from "../../../context/AuthContext";
import { useTranslation } from "react-i18next";
import { isMobile } from "../../../utils/custom";

const ChangeMail = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const authCtx = useContext(AuthContext);
  const [currentEmailId, setCurrentEmailID] = useState("");
  const [emailId, setEmailID] = useState("");
  const [emailIdErr, setEmailIDErr] = useState("");
  const [apiToken, setApiToken] = useState(null);
  const location = useLocation();
  const [btnLoading, setBtnLoading] = useState(false);
  const [formLoad, setFormLoad] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [alertType, setAlertType] = useState(false);
  const [response, setResponse] = useState("");

  const [otp, setOtp] = useState("");
  const [otpErr, setOtpErr] = useState("");

  const [openMailForm, isOpenMailForm] = useState(true);
  const [openOtpForm, isOpenOtpForm] = useState(false);
  const itemRef = useRef(null);

  const cancelForm = () => {
    setEmailID("");
    setEmailIDErr("");
  };

  // input validation
  const validateEmail = (e) => {
    var email = e.target.value;
    setEmailID(email);
    if (email !== "") {
      if (validator.isEmail(email)) {
        setEmailIDErr("");
      } else {
        setEmailIDErr(t("email valid alert"));
      }
    } else {
      setEmailIDErr(t("required alert"));
    }
  };

  useEffect(() => {
    if (
      localStorage.getItem("apiToken") !== "undefined" &&
      localStorage.getItem("apiToken") !== "" &&
      localStorage.getItem("apiToken") !== null
    ) {
      setFormLoad(true);
      let apiToken = JSON.parse(localStorage.getItem("apiToken"));
      let loginData = JSON.parse(localStorage.getItem("userDetails"));
      setCurrentEmailID(loginData.email);
      setApiToken(apiToken);
      setFormLoad(false);
    }
    //console.log(isMobile());
    if (isMobile()) {
      if (itemRef.current) {
        itemRef.current.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }, [location]);
  const validateForm = (e) => {
    e.preventDefault();
    let formIsValid = true;
    if (emailId !== "") {
      if (validator.isEmail(emailId)) {
        setEmailIDErr("");
        formIsValid = true;
      } else {
        setEmailIDErr(t("email valid alert"));
        formIsValid = false;
      }
    } else {
      setEmailIDErr(t("required alert"));
      formIsValid = false;
    }
    ChangeMail(formIsValid);
  };

  function ChangeMail(formIsValid) {
    const headers = {
      needJson: 1,
      apiToken: apiToken,
      "Content-Type": "application/json",
    };
    if (formIsValid) {
      setBtnLoading(true);
      axios
        .post(
          process.env.REACT_APP_HOST_URL + CHANGE_MAIL,
          {
            new_email_id: emailId,
          },
          {
            headers: headers,
          }
        )
        .then((response) => {
          setResponse(response.data.message);
          setAlertType(response.data.success);
          setShowAlert(true);
          setBtnLoading(false);
          if (response.data.success) {
            isOpenMailForm(false);
            isOpenOtpForm(true);
          }
        });
    }
  }

  const validateOtpForm = (e) => {
    e.preventDefault();
    let otpFormIsValid = true;
    if (otp == "") {
      setOtpErr(t("required alert"));
      otpFormIsValid = false;
    }
    UpdateMail(otpFormIsValid);
  };

  const handleOtpChange = (value) => {
    setOtp(value);
    if (value == "") {
      setOtpErr(t("required alert"));
    } else {
      setOtpErr(t(""));
    }
  };

  function UpdateMail(otpFormIsValid) {
    const headers = {
      needJson: 1,
      apiToken: apiToken,
      "Content-Type": "application/json",
    };
    if (otpFormIsValid) {
      setBtnLoading(true);
      axios
        .post(
          process.env.REACT_APP_HOST_URL + CHANGE_MAIL_ID,
          {
            otp: otp,
          },
          {
            headers: headers,
          }
        )
        .then((response) => {
          if (response.data.success) {
            isOpenMailForm(true);
            isOpenOtpForm(false);
            localStorage.removeItem("apiToken");
            localStorage.removeItem("userDetails");
            localStorage.setItem(
              "apiToken",
              JSON.stringify(response.data.apiToken)
            );
            localStorage.setItem(
              "userDetails",
              JSON.stringify(response.data.userDetails)
            );
            setCurrentEmailID(response.data.userDetails.email);
            setApiToken(response.data.apiToken);
            setEmailID("");
            setOtp("");
          }
          setResponse(response.data.message);
          setAlertType(response.data.success);
          setShowAlert(true);
          setBtnLoading(false);
          if (response.data.success){
            window.location.reload();
          }
        });
    }
  }

  document.body.onkeydown = function (e) {
    if (e.key === "Enter") {
      if (openMailForm) {
        validateForm(e);
      } else if (openOtpForm) {
        validateOtpForm(e);
      }
    }
  };
  useEffect(() => {
    if (!authCtx.isLogin) {
      navigate("/");
    }
  }, [authCtx.isLogin]);

  return (
    <div ref={itemRef} className={`account-formSection ${formLoad ? "form-loading" : ""}`}>
      <Alert
        setShow={setShowAlert}
        show={showAlert}
        msg={response}
        type={alertType}
      />
      {openMailForm && (
        <form method="post" name="changeMailForm">
          <h3 className="head-h3">{t("change mail text")}</h3>
          <Grid container columnSpacing={{ xs: 0, sm: 4 }}>
            <Grid item xs={12} sm={6} md={6}>
              <FormGroup className="form-group">
                <FormLabel>{t("your email label")}</FormLabel>
                <TextField
                  className="form-control"
                  type="text"
                  size="small"
                  value={currentEmailId}
                  disabled
                />
              </FormGroup>
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <FormGroup className="form-group">
                <FormLabel required>{t("new email label")}</FormLabel>
                <TextField
                  className="form-control"
                  type="text"
                  size="small"
                  value={emailId}
                  onChange={(e) => validateEmail(e)}
                />
                <div className="errorMsg">{emailIdErr}</div>
              </FormGroup>
            </Grid>
            <div className="btn-group">
              <Button className="btn btn-cancel" onClick={() => cancelForm()}>
                {t("cancel text")}
              </Button>
              <LoadingButton
                className="btn btn-submit"
                onClick={(e) => validateForm(e)}
                loading={btnLoading}
                loadingIndicator="Loading…"
              >
                <span>{t("submit small text")}</span>
              </LoadingButton>
            </div>
          </Grid>
        </form>
      )}
      {openOtpForm && (
        <form method="post" name="changeMailForm">
          <h3 className="head-h3">{t("otp validation text")}</h3>
          <Grid container columnSpacing={{ xs: 0, sm: 4 }}>
            <Grid item xs={12} sm={6} md={6}>
              <FormGroup className="form-group">
                <FormLabel required>{t("enter otp alert")}</FormLabel>
                <TextField
                  className="form-control"
                  type="text"
                  size="small"
                  value={otp}
                  onChange={(event) => handleOtpChange(event.target.value)}
                />
                <div className="errorMsg">{otpErr}</div>
              </FormGroup>
            </Grid>
            <div className="btn-group">
              <LoadingButton
                className="btn btn-submit"
                onClick={(e) => validateOtpForm(e)}
                loading={btnLoading}
                loadingIndicator="Loading…"
              >
                <span>{t("submit small text")}</span>
              </LoadingButton>
            </div>
          </Grid>
        </form>
      )}
      <FormLoader />
    </div>
  );
};

export default ChangeMail;
