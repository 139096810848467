import React, { useState, useEffect } from "react";
import { Container } from "@mui/material";
import axios from "axios";
import { MDA_LISTALL, SERVICE_LISTALL } from "../../utils/api-constant";
import ServiceLoader from "../Loader/ServiceLoader";
import SchemeMdaBoxGrid from "../Home/SchemeMdaBoxGrid/SchemeMdaBoxGrid";
import "../Home/SchemeMdaBoxGrid/SchemeMdaBoxGrid.css";
import { replaceSpaceWithDash } from "../../utils/custom";
import LoadingPage from "../SeviceScreen/LoadingPage";
import useApiFetch from "../../hooks/useApiFetch";
import CallIcon from "@mui/icons-material/Call";
import EmailIcon from "@mui/icons-material/Email";
import LanguageIcon from "@mui/icons-material/Language";

function BusinessMDAService() {
  const localmdas = JSON.parse(localStorage.getItem("mdas"));
  const [catId, setCatId] = useState("");
  const [mdaId, setMdaId] = useState("");
  const [loading, setLoading] = useState(true);

  const [id, setId] = useState("");
  const [service, setService] = useState();

  const { data: listData, loading: listLoading } = useApiFetch(
    process.env.REACT_APP_HOST_URL + MDA_LISTALL
  );

  function getServiceList() {
    const params = {
      headers: {
        needJson: 1,
        "Content-Type": "application/json",
      },
    };
    if (catId || mdaId) {
      axios
        .get(
          process.env.REACT_APP_HOST_URL + SERVICE_LISTALL + `?mda_id=${mdaId}`,
          params
        )
        .then((response) => {
          setService(response.data);
          setLoading(false);
        });
    }
  }
  const currentUrl = window.location.hash.split("/");
  const mdaName = currentUrl[1];
  useEffect(() => {
    const currentUrl = window.location.hash.split("/");
    const type = currentUrl[2];
    const id = currentUrl[3];
    setId(id);
    setMdaId(id);
  }, []);

  useEffect(() => {
    getServiceList();
  }, [id]);
  if (loading || listLoading) return <LoadingPage />;

  const currentMdas = listData.list.find(
    (item) => replaceSpaceWithDash(item.name) === mdaName
  );
  return (
    <section className="BusinessService Services-Scheme-Section">
      <div className="service-header">
        <Container>
          <div className="service-header-inner">
            <div className="left-div">
              <h6>{currentMdas.name}</h6>
              <span className="desc">{currentMdas.description}</span>
              <div className="feature-url">
              {currentMdas.phone && (
                <a
                  href={`tel:${currentMdas.phone}`}
                  target="_blank"
                  className="list-detail"
                >
                  <CallIcon className="icon" /> <span>{currentMdas.phone}</span>
                </a>
              )}
              {currentMdas.email && (
                <a
                  href={`mailto:${currentMdas.email}`}
                  target="_blank"
                  className="list-detail"
                >
                  <EmailIcon className="icon" />{" "}
                  <span>{currentMdas.email}</span>
                </a>
              )}
              {currentMdas.website && (
                <a
                  href={currentMdas.website}
                  target="_blank"
                  className="list-detail"
                >
                  <LanguageIcon className="icon" />{" "}
                  <span>{currentMdas.website}</span>
                </a>
              )}
              </div>
            </div>
          </div>
        </Container>
      </div>
      <div className="BusinessService_box">
        <Container>
          <div className="service-page-detail">
            <SchemeMdaBoxGrid boxGridApi={service} type="mda" detail={false} />
          </div>
        </Container>
        <ServiceLoader />
      </div>
    </section>
  );
}

export default BusinessMDAService;
