import React, { useState, useEffect } from "react";
import { Container } from "@mui/material";
import axios from "axios";
import { CATEGORY_LISTALL, SERVICE_LISTALL } from "../../utils/api-constant";
import ServiceLoader from "../Loader/ServiceLoader";
import ServicesBoxGrid from "../Home/ServicesBoxGrid/ServicesBoxGrid";
import "../Home/Services/Services.css";
import LoadingPage from "../SeviceScreen/LoadingPage";
import { replaceSpaceWithDash } from "../../utils/custom";
import useApiFetch from "../../hooks/useApiFetch";
import ErrorSection from "../Error/ErrorSection";
import { useLocation } from "react-router-dom";

function BusinessService() {
  const location = useLocation();
  const [catId, setCatId] = useState("");
  const [mdaId, setMdaId] = useState("");
  const [loading, setLoading] = useState(true);

  const [id, setId] = useState("");
  const [service, setService] = useState();
  const [error, setError] = useState(null);

  const {
    data: listData,
    loading: listLoading,
    error: listError,
  } = useApiFetch(process.env.REACT_APP_HOST_URL + CATEGORY_LISTALL);

  function getServiceList() {
    const params = {
      headers: {
        needJson: 1,
        "Content-Type": "application/json",
      },
    };
    if (catId || mdaId) {
      axios
        .get(
          process.env.REACT_APP_HOST_URL +
            SERVICE_LISTALL +
            `?category_id=${catId}`,
          params
        )
        .then((response) => {
          setService(response.data.list);
          setLoading(false);
        })
        .catch((e) => {
          setLoading(false);
          setError(e.message);
        });
    }
  }
  const currentUrl = window.location.hash.split("/");
  const serviceName = currentUrl[1];
  useEffect(() => {
    const type = currentUrl[2];
    const id = currentUrl[3];
    setId(id);
    setCatId(id);    
  }, [location]);

  useEffect(() => {    
    getServiceList();
  }, [id]);

  if (error || listError) return <ErrorSection />;

  if (loading || listLoading) return <LoadingPage />;

  const currentService = listData.list.find(
    (item) => replaceSpaceWithDash(item.name) === serviceName
  );
  return (
    <section className="BusinessService Services-Section">
      <div className="service-header">
        <Container>
          <div className="service-header-inner">
            <div className="left-div">
              <h6>{currentService.name}</h6>
              <span className="desc">{currentService.description}</span>
            </div>
          </div>
        </Container>
      </div>
      <div className="BusinessService_box">
        <Container>
          <div className="service-page-detail">
            <ServicesBoxGrid setService={service} type="cat" detail={false} />
          </div>
          {/* <BoxGrid boxGridApi={service} /> */}
        </Container>
        <ServiceLoader />
      </div>
    </section>
  );
}

export default BusinessService;
