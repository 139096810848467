import React from "react";
import "./Banner.css";
import { GET_BANNER_LIST } from "../../../utils/api-constant";
import bannerImg from "../../../assets/Img/Banner1.jpg";
import Carousel from "react-material-ui-carousel";
import useApiFetch from "../../../hooks/useApiFetch";
import LoadingBanner from "../../Loader/LoadingBanner";
import { Link, useNavigate } from "react-router-dom";
import EastIcon from '@mui/icons-material/East';

function Banner({ type, banner_name }) {
  // const { data, loading, error } = useApiFetch(
  //   process.env.REACT_APP_HOST_URL +
  //     GET_BANNER_LIST +
  //     `?type=${type}&banner_name=${banner_name}`
  // );
  const { data, loading, error } = useApiFetch(
    process.env.REACT_APP_HOST_URL +
      GET_BANNER_LIST +
      `?type=${type}`
  );
  if (loading) return <LoadingBanner />;
  const banners = data.list;

  return (
    <section className="BannerSection">
      <Carousel navButtonsAlwaysVisible={true}>
        {typeof banners != "undefined" ? (
          banners?.map((item) => (
            <div key={item.id} style={{ position: 'relative' }}>
            <img
              src={
                process.env.REACT_APP_HOST_URL +
                process.env.REACT_APP_IMAGE_URL +
                item.banner_image
              }
              alt="banner"
            />
            {item.banner_description && (            
              <div className="center_banner_txt" style={{ position: 'absolute', top: '50%', left: '3%', transform: 'translate(0%, -50%)', textAlign: 'left', color: 'white' }}>
                <span class="badge">Ministry of Education</span>
                {item.banner_description && (  
                  <h2> {item.banner_description} </h2> 
                )}
                {item.button_description && (  
                  <p> {item.button_description} </p> 
                )}
                {item.button_label && (  
                  <Link to={item.button_link} style={{ top: '65%'}}>{item.button_label} <EastIcon/></Link>
                )}
              </div>   
              // <div class="banner-container">
              //     <div class="banner-text">
              //         <span class="badge">Ministry of Education</span>
              //         <h1>{item.banner_description} </h1>
              //         <p>{item.button_description}</p>
              //         <Link to={item.button_link} className="read-more" style={{ top: '65%'}}>{item.button_label}</Link>
              //     </div>
              // </div>         
            )}            
            
            </div>
          ))
        ) 
        : (
          <img src={bannerImg} alt="banner" />
        )}
      </Carousel>
    </section>
  );
}

export default Banner;
