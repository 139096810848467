import React from 'react';
import { Container } from '@mui/material';
import {Link} from 'react-router-dom';
import { Grid } from '@mui/material';
import redArrow from "../../../assets/Img/red-arrow.png";
import { CATEGORY_LISTALL } from '../../../utils/api-constant';
import './Services.css';
import ServicesBoxGrid from '../ServicesBoxGrid/ServicesBoxGrid';
import LoadingPage from '../../SeviceScreen/LoadingPage';
import useApiFetch from "../../../hooks/useApiFetch";
import { useTranslation } from 'react-i18next';
function Services() {
  const { t } = useTranslation();
  const { data, loading, error } = useApiFetch(
    process.env.REACT_APP_HOST_URL + CATEGORY_LISTALL +'?limit=6'
  );
  if (loading) return <LoadingPage/>;
  const service = data.list;
  
  return (
    <section className='Services-Section'>
      
        <Container maxWidth="lg">
                <Grid container spacing={2}>
                        <Grid item xs={12} sm={12} md={8} lg={8}>
                            <div className="sec-head">
                               <h2>{t("service category title")}</h2>
                               <p>{t("service category description")}</p>
                            </div>
                        </Grid>
                        <Grid item xs={12} sm={12} md={4} lg={4}>
                            <div className="arrowIcons">      
                              <div >
                               
                                <Link to='/services-list'  className='view-btn'> <span>{t("view all service text")}</span><img src={redArrow} alt="Proceed"/></Link>
                              </div>                          
                              
                            </div>
                        </Grid>

                </Grid>
                <ServicesBoxGrid  setService={service} type="service" detail={true}/>    
                
          </Container>                 
      
    </section>
  );
}

export default Services;