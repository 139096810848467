import { Menu, MenuItem } from "@mui/material";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import english from "../../assets/Img/englishflag.png";
import malawi from "../../assets/Img/malawiflag.png";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const languages = [
  { code: "en", lang: "English", flag: english },
  { code: "ny", lang: "Chichewa", flag: malawi },
];
const LanguageSelector = () => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const { i18n } = useTranslation();
  const handleChangeLanguage = (lang) => {
    i18n.changeLanguage(lang);
    handleLanguageClose();
  };

  const handleLanguageOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleLanguageClose = () => {
    setAnchorEl(null);
  };
  const currentLanguage = languages.find((item) => item.code === i18n.language);
  return (
    <div className="lang-select">
      <div
        className="lang-text"
        onClick={handleLanguageOpen}
        aria-label="account of current user"
        aria-controls="menu-appbar"
        aria-haspopup="true"
      >
        {/* {(currentLanguage && <img src={currentLanguage.flag} alt={currentLanguage.lang} />) || (
          <img src={english} alt="english" />
        )} */}
        {(currentLanguage && currentLanguage.lang) || "English"}
        <ExpandMoreIcon className="down-arrow" />
      </div>
      <Menu
        className="lang-dropdown"
        id="filter-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleLanguageClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        keepMounted
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        {languages.map((lang) => {
          return (
            <MenuItem
              key={lang.code}
              value={lang.code}
              onClick={() => handleChangeLanguage(lang.code)}
              className={lang.code === i18n.language ? "selected" : ""}
            >
              {/* <img src={lang.flag} alt={lang.lang} /> */}
               <span>{lang.lang}</span>
            </MenuItem>
          );
        })}
      </Menu>
    </div>
  );
};

export default LanguageSelector;
