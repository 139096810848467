import React, { useState, useEffect, useCallback } from "react";
import {
  Container,
  FormGroup,
  FormLabel,
  MenuItem,
  Select,
  Grid,
  RadioGroup,
  Radio,
  FormControlLabel,
  styled,
} from "@mui/material";
import axios from "axios";
import { UBS_MDA_LIST, TRANSACTION_PAY, TRANSACTION_UPDATE, GET_USER_DETAILS, MDA_SERVICE_LIST } from "../../utils/api-constant";
import ServiceLoader from "../Loader/ServiceLoader";
import "../Home/Services/Services.css";
import "./Makepayment.css";
import LoadingPage from "../SeviceScreen/LoadingPage";
import ErrorSection from "../Error/ErrorSection";
import { useTranslation } from "react-i18next";
import LoadingButton from "@mui/lab/LoadingButton";
import useRazorpay from "react-razorpay";
import PaymentSuccess from "./PaymentSuccess";
import PaymentFailed from "./PaymentFailed";

function Makepayment() {

  const [mdaService, setMdaServices] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedMda, setSelectedMda] = useState({});
  const { t } = useTranslation();
  const [Razorpay] = useRazorpay();
  const [PaymentSuccessAlert, setPaymentSuccessAlert] = useState({
    success: false,
    data: ""
  });
  const [PaymentFailedAlert, setPaymentFailedAlert] = useState({
    success: false,
    data: ""
  });
  const [UserDetails, setUserDetails] = useState({});
  const [MDAServiceListAll, setMDAServiceListAll] = useState([]);
  const [ParentIdZeroList, setParentIdZeroList] = useState([]);
  const [ChooseServiceList, setChooseServiceList] = useState([]);
  const [SelectedService, setSelectedService] = useState({});
  const [SelectedSubLabelHeadList, setSelectedSubLabelHeadList] = useState([]);
  const [SelectedSubLabelList, setSelectedSubLabelList] = useState([]);
  const [SelectedSubLabel, setSelectedSubLabel] = useState({});
  const [SelectedPayment, setSelectedPayment] = useState({});
  const [selectedSubLabelValues, setSelectedSubLabelValues] = useState({});
  const [PaymentOptionArray, setPaymentOptionArray] = useState([]);
  const [responseLoading, setLoadingStatus] = useState(true);

  // Fetch MDA services list from API
  const getServiceList = () => {
    const params = {
      headers: {
        needJson: 1,
        "Content-Type": "application/json",
      },
    };
    axios
      .get(process.env.REACT_APP_HOST_URL1 + UBS_MDA_LIST, params)
      .then((response) => {
        setMdaServices(response.data.list);
        setLoading(false);
      })
      .catch((e) => {
        setLoading(false);
        setError(e.message);
      });
  };

  const getMDAServiceList = (item) => {
    const params = {
      headers: {
        needJson: 1,
        "Content-Type": "application/json",
      },
    };
    axios
      .get(process.env.REACT_APP_HOST_URL1 + MDA_SERVICE_LIST + item._id, params)
      .then((response) => {
        console.log(response.data.list);
        if (response.data && response.data.list.length > 0) {
          setMDAServiceListAll(response.data.list);
          const filteredList = response.data.list.filter((items) => items.parent_id === item.uid);
          setParentIdZeroList(filteredList);
          setChooseServiceList([]);
        } else {
          setMDAServiceListAll([]);
          setParentIdZeroList([]);
          setChooseServiceList([]);
          setSelectedMda({});
        }
        setLoading(false);
      })
      .catch((e) => {
        setLoading(false);
        setError(e.message);
      });
  };

  useEffect(() => {
    getServiceList();
    let apiToken = JSON.parse(localStorage.getItem("apiToken"));
    getUserDetails(apiToken);
  }, []);

  const handleMdaList = (item, from, index) => {
    console.log("from", from, "item", item);
    console.log("SelectedSubLabelHeadList_length", SelectedSubLabelHeadList.length, "SelectedSubLabelList_length", SelectedSubLabelList.length);
    console.log("MDAServiceListAll11", MDAServiceListAll);
    setLoadingStatus(true);
    if (from === "1") {
      setSelectedMda(item);
      setSelectedService({});
      setSelectedPayment({});
      setSelectedSubLabel({});
      setSelectedSubLabelHeadList([]);
      setSelectedSubLabelList([]);
      if (item.is_sub_need === 0) {
        const filteredList = MDAServiceListAll.filter((items) => items.parent_id === item.uid);
        setChooseServiceList(filteredList);
        setParentIdZeroList([]);
      } else {
        getMDAServiceList(item);
      }
    } else if (from === "2") {
      setSelectedSubLabel({});
      setSelectedService(item);
      let paymentOptions = item.payment_option;
      console.log("paymentOptions", paymentOptions)
      // Ensure paymentOptions is always an array
      if (typeof paymentOptions === 'string') {
        paymentOptions = [paymentOptions];
        console.log("paymentOptions1", paymentOptions)
      }
      if (paymentOptions.length > 0) {
        const PaymentOptionArray = paymentOptions.map(id => {
          let name;
          switch (id) {
            case '1':
              name = "Pay with Payment Gateway";
              break;
            case '2':
              name = "Pay with Bank Payment";
              break;
            case '4':
              name = "Pay with Offline Payment";
              break;
            default:
              name = "";
          }
          return { id, name };
        });
        console.log(PaymentOptionArray);
        setPaymentOptionArray(PaymentOptionArray);
      } else {
        setPaymentOptionArray([]);
      }
      
    } else if (from === "3") {
      setSelectedService({});
      setSelectedPayment({});
      setChooseServiceList([]);
      setSelectedSubLabelList([]);
      setSelectedSubLabelHeadList([]);
      if (item.is_service_sub_need === 0) {
        const filteredList = MDAServiceListAll.filter((items) => items.parent_id === item.uid);
        console.log("filteredList_if", filteredList);
        setChooseServiceList(filteredList);
      } else {
        setChooseServiceList([]);
      }
      setSelectedSubLabel(item);
      setSelectedSubLabelHeadList(prev => (Array.isArray(prev) ? [...prev, item] : [item]));
  
      const filteredList = MDAServiceListAll.filter(itm => {
        // console.log("itm.parent_id", itm.parent_id, "item.uid", item.uid);
        return itm.parent_id === item.uid;
      });
      console.log("filteredList1", filteredList);
      setSelectedSubLabelList(filteredList);
      
    } else if (from === "4") {
      if (item.is_service_sub_need === 0) {
        const filteredList = MDAServiceListAll.filter((items) => items.parent_id === item.uid);
        console.log("filteredList_if", filteredList);
        setChooseServiceList(filteredList);
      } else {
        setSelectedSubLabelHeadList(prevFilteredMdaService => (Array.isArray(prevFilteredMdaService) ? [...prevFilteredMdaService, item] : [item]));
        setSelectedSubLabelList((prevFilteredMdaService) => {
          const newlist = prevFilteredMdaService.filter(itm => {
            // console.log("itm.level", itm.level, "item.level", item.level);
            return itm.level <= item.level
          });
          console.log("newlist", newlist);
          const datas = MDAServiceListAll.filter(itm => {
            // console.log("itm.parent_id", itm.parent_id, "item.uid", item.uid);
            return itm.parent_id === item.uid;
          });
          console.log("datas", datas);
          if (datas.length === 0) {
            setChooseServiceList([]);
            setSelectedService({});
            setSelectedPayment({});
          }
          return [...newlist,...datas];
        });
        setChooseServiceList([]);
      }
    }
    setTimeout(() => {
      setLoadingStatus(false);
    }, 2000); 
  };

  const handleSelectChange = (value, index) => {
    setSelectedSubLabelValues(prev => ({
      ...prev,
      [index]: value
    }));
  };

  function getUserDetails(apiToken) {
    const params = {
      headers: {
        needJson: 1,
        apiToken: apiToken,
        "Content-Type": "application/json",
      },
    };
    axios
      .get(process.env.REACT_APP_HOST_URL + GET_USER_DETAILS, params)
      .then((response) => {
        if (response.data.success) {
          setUserDetails(response.data.userDetails);
        }
      })
      .catch((e) => {
        setError(e.message);
      });
  };

  function TransactionPay(from) {
    const parts = [UserDetails.fname, UserDetails.lname, UserDetails.sur_name];
    const fullName = parts.filter(Boolean).join(' ').trim();

    axios
      .post(
        process.env.REACT_APP_HOST_URL1 + TRANSACTION_PAY,
        {
          payerId: UserDetails.nationality === "MALAWI" ? UserDetails.nin_number : UserDetails.passport_number,
          foreigner: UserDetails.nationality === "MALAWI" ? "0" : "1",
          userName: fullName,
          email: UserDetails.email,
          contactNo: UserDetails.phone,
          service_uid: SelectedService.uid,
          is_active: 1,
          portal: 2,
          payment_mode: from
        },
        {
          headers: {
            needJson: 1,
          },
        }
      )
      .then((response) => {
        console.log(response.data);
        if (response.data.success) {
          if(from === "1"){
            handlePayment(response.data.prn_no, SelectedService, UserDetails);
          } else {
            setPaymentSuccessAlert({
              success: true,
              data: response.data
            });
          }
        } else if (response.data.success === false){
          setPaymentFailedAlert({
            success: true,
            data: response.data
          });
        }else {
          setError("true");
        }
      }).catch((e) => {
        setPaymentSuccessAlert({
          success: false,
          data: ""
        });
        setPaymentFailedAlert({
          success: false,
          data: ""
        });
        setError(e.message);
      });
  }

  function TransactionUpdate(status, payment_id, error_code, error_message, prn_no) {
    axios
      .post(
        process.env.REACT_APP_HOST_URL1 + TRANSACTION_UPDATE,
        {
          prn_status: status,
          receipt_no: payment_id !== null && payment_id !== "" && payment_id !== undefined ? payment_id : "",
          bank_transaction_id: payment_id !== null && payment_id !== "" && payment_id !== undefined ? payment_id : "",
          prn: prn_no,
          error_code: error_code,
          error_message: error_message,
        },
        {
          headers: {
            needJson: 1,
          },
        }
      )
      .then((response) => {
        console.log(response.data);
        if (response.data.success) {
          if (status === "Payment received") {
            setPaymentSuccessAlert({
              success: true,
              data: response.data
            });
          } else if (status === "failed") {
            setPaymentFailedAlert({
              success: true,
              data: response.data
            });
          }
        } else {
          setError("true");
        }
      }).catch((e) => {
        setPaymentSuccessAlert({
          success: false,
          data: ""
        });
        setPaymentFailedAlert({
          success: false,
          data: ""
        });
        setError(e.message);
      });
  }

  const handlePayment = useCallback((prn_no, SelectedIdAmount, UserDetails) => {
    // const order = await createOrder(params);
    console.log(parseFloat(SelectedIdAmount.amount));
    const parts = [UserDetails.fname, UserDetails.lname, UserDetails.sur_name];
    const fullName = parts.filter(Boolean).join(' ').trim();
    console.log("UserDetails", UserDetails);
    const options = {
      key: "rzp_test_g6KEuH6FbRCm1N",
      amount: parseFloat(SelectedIdAmount.amount)*100,
      currency: "MWK",
      name: fullName,
      description: "Transaction",
      // image: "https://via.placeholder.com/150x150.png",
      // order_id: prn_no,
      handler: (response) => {
        console.log("Payment Success", response);
        TransactionUpdate("Payment received", response.razorpay_payment_id, "", "", prn_no)
      },
      prefill: {
        name: fullName,
        email: UserDetails.email,
        contact: UserDetails.phone,
      },
      theme: {
        "color": "#D8071E"
      },
      modal: {
        ondismiss: function () {
          console.log("Payment popup closed");
          // alert("Payment Cancelled");
        }
      }
    };

    const rzpay = new Razorpay(options);
    rzpay.on('payment.failed', function (response) {
      // Payment failed callback
      console.log("Payment Failed", response.error);
      TransactionUpdate("failed", response.payment_id, response.error.reason, response.error.description, prn_no)
      rzpay.close();
      // alert("Payment Failed: " + response.error.description);
    });
    rzpay.open();
  }, [Razorpay]);

  const HandleFailedClose = () => {
    setPaymentFailedAlert({
      success: false,
      data: ""
    });
    setSelectedMda("");
    setSelectedService({});
    setSelectedPayment({});
    setChooseServiceList([]);
    setSelectedSubLabelList([]);
    setSelectedSubLabelHeadList([]);
    setMDAServiceListAll([]);
    setParentIdZeroList([]);
  }

  const HandleSuccessClose = () => {
    setPaymentSuccessAlert({
      success: false,
      data: ""
    });
  }

  const BpIcon = styled('span')(({ theme }) => ({
    borderRadius: '50%',
    width: 20,
    height: 20,
    boxShadow:
      theme.palette.mode === 'dark'
        ? '0 0 0 1px rgb(16 22 26 / 40%)'
        : 'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
    backgroundColor: theme.palette.mode === 'dark' ? '#394b59' : '#f5f8fa',
    backgroundImage:
      theme.palette.mode === 'dark'
        ? 'linear-gradient(180deg,hsla(0,0%,100%,.05),hsla(0,0%,100%,0))'
        : 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
    '.Mui-focusVisible &': {
      outline: '2px auto rgba(19,124,189,.6)',
      outlineOffset: 2,
    },
    'input:hover ~ &': {
      backgroundColor: theme.palette.mode === 'dark' ? '#30404d' : '#ebf1f5',
    },
    'input:disabled ~ &': {
      boxShadow: 'none',
      background:
        theme.palette.mode === 'dark' ? 'rgba(57,75,89,.5)' : 'rgba(206,217,224,.5)',
    },
  }));

  const BpCheckedIcon = styled(BpIcon)({
    backgroundColor: '#137cbd',
    backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
    '&::before': {
      display: 'block',
      width: 20,
      height: 20,
      backgroundImage: 'radial-gradient(#fff,#fff 28%,transparent 32%)',
      content: '""',
    },
    'input:hover ~ &': {
      backgroundColor: '#106ba3',
    },
  });

  const HandlePaymentClick = (item) =>{
    setSelectedPayment(item);
  }

  const HandleSubmitClick = () => {
    if (SelectedPayment.id === "1"){
      TransactionPay("1")
    } else if (SelectedPayment.id === "2") {
      TransactionPay("2")
    } else if (SelectedPayment.id === "4") {
      TransactionPay("4")
    }
  }

  if (PaymentSuccessAlert.success) return <PaymentSuccess data={PaymentSuccessAlert.data} handleclose={HandleSuccessClose} paymenttype={SelectedPayment}/>;

  if (PaymentFailedAlert.success) return <PaymentFailed data={PaymentFailedAlert.data} handleclose={HandleFailedClose}/>;

  if (error) return <ErrorSection />;

  if (loading) return <LoadingPage />;

  return (
    <section className="BusinessService Services-Section">
      <div className="service-header">
        <Container>
          <div className="service-header-inner">
            <div className="left-div">
              <h6>{t("Make Payment")}</h6>
              <span className="desc">{t("make payment desc")}</span>
            </div>
          </div>
        </Container>
      </div>
      <div className="BusinessService_box payPage">
        <Container>
          <div className="service-page-detail">
            <form className="payment-add">
              <Grid container columnSpacing={{ xs: 0, sm: 4 }}>
                <Grid item xs={12} sm={8} md={8}>
                  <FormGroup className="form-group mda-group">
                    <FormLabel style={{ minWidth: 50 }} required>{t("ministry department agency")}</FormLabel>
                    <Select
                      value={selectedMda._id}
                      displayEmpty >
                      <MenuItem disabled value="">
                        <span style={{ opacity: 0.4 }}>{t("select")}</span>
                      </MenuItem>
                      {Array.isArray(mdaService) && mdaService.length > 0
                        ? (mdaService.map((item, index) =>
                          item.name !== null && item.name !== '' ? (
                            <MenuItem
                              onClick={(e) => handleMdaList(item, "1", index)}
                              key={item._id}
                              value={item._id} >                                                               
                                {item.name}                               
                            </MenuItem>
                          ) : null))
                        : ( <MenuItem disabled>No data available</MenuItem>)}
                    </Select>
                  </FormGroup>
                  <FormGroup className="form-group">                  
                    {Object.keys(selectedMda).length > 0 && selectedMda.is_sub_need === 1 && (
                      <FormGroup className="form-group mda-group">
                        <FormLabel style={{ minWidth: 50 }} required>{selectedMda.subLabel} </FormLabel>
                        <Select value={SelectedSubLabel.optionValue} displayEmpty>
                          <MenuItem disabled value="">
                            <span style={{ opacity: 0.4 }}>{t("select")}</span>
                          </MenuItem>
                          {Array.isArray(ParentIdZeroList) && ParentIdZeroList.length > 0 ? (
                            ParentIdZeroList.map((item, index) =>
                              item.optionValue !== null && item.optionValue !== '' ? (
                                <MenuItem
                                  onClick={(e) => handleMdaList(item, "3", index)}
                                  key={item._id}
                                  value={item._id}
                                >
                                  {item.optionValue}
                                </MenuItem>
                              ) : null
                            )
                          ) : (
                            <MenuItem disabled>No data available</MenuItem>
                          )}
                        </Select>
                      </FormGroup> )}
                    
                      {SelectedSubLabelList.length > 0  &&
                        SelectedSubLabelHeadList.map((item, index) => {
                          const shouldRender = SelectedSubLabelList.some(subItem => subItem.parent_id === item.uid);
                          return shouldRender && (item.is_service_sub_need !== "0" && item.is_service_sub_need !== 0) ? (
                            <div key={index} className="flex-grid">
                              <FormLabel required>{item.subLabel} </FormLabel>
                              <Select
                                value={selectedSubLabelValues[index] || ''}
                                displayEmpty
                                onChange={(e) => {
                                  const value = SelectedSubLabelList.find(subItem => subItem.uid === e.target.value);
                                  handleMdaList(value, "4", index);
                                  handleSelectChange(e.target.value, index);
                                }}>
                                <MenuItem disabled value="">
                                  <span style={{ opacity: 0.4 }}>Choose option</span>
                                </MenuItem>
                                {SelectedSubLabelList.filter(subItem => subItem.parent_id === item.uid).map(subItem => (
                                  <MenuItem key={subItem.uid} value={subItem.uid}>
                                    {subItem.optionValue}
                                  </MenuItem>
                                ))}
                              </Select>
                            </div>
                          ) : null;
                        })
                      }
                    {ChooseServiceList.length > 0 && <FormGroup className="form-group mda-group">
                      <FormLabel style={{ minWidth: 50 }} required>{t("choose service")} </FormLabel>
                      <Select
                        value={SelectedService.serviceName}
                        displayEmpty >
                        <MenuItem disabled value="">
                          <span style={{ opacity: 0.4 }}>{t("select")}</span>
                        </MenuItem>
                        {ChooseServiceList.length > 0
                          ? (ChooseServiceList.map((item, index) =>
                            item.serviceName !== null && item.serviceName !== '' ? (
                              <MenuItem
                                onClick={(e) => handleMdaList(item, "2",index)}
                                key={item._id}
                                value={item._id} >
                                {item.serviceName}
                              </MenuItem>
                            ) : null))
                          : (<MenuItem disabled>No data available</MenuItem>)}
                      </Select>
                    </FormGroup>}
                    {Object.keys(SelectedService).length > 0 && 
                      <div className="row-center amountRow">
                        <FormGroup className="form-group mda-group">
                          <FormLabel style={{ minWidth: 50 }}>{t("Amount to Pay")}</FormLabel>
                          <div className="pay-amount">
                            <FormLabel className="service-amt">
                              <span className="sub-font">MWK {SelectedService.amount || '--'}</span>
                            </FormLabel>
                          </div>
                        </FormGroup>
                      </div> }
                    {Object.keys(SelectedService).length > 0 &&
                      <div className="row-center bankRow">
                        <FormGroup className="form-group payment-option">
                          <FormLabel style={{ minWidth: 50 }}>{t("choose the mode of payment")}</FormLabel>
                          <div className="paymentOptionClass" style={{  }}>
                            <RadioGroup defaultValue="outlined" name="radio-buttons-group">
                              {PaymentOptionArray.map((item) =>
                                item.name !== null && item.name !== '' 
                                  ? <FormControlLabel value={item.id} control={<Radio
                                    disableRipple
                                    color="default"
                                    checkedIcon={<BpCheckedIcon />}
                                    icon={<BpIcon />} />} label={item.name}
                                    onClick={(e) => HandlePaymentClick(item)} />
                                : null)}
                            </RadioGroup>
                          </div>
                        </FormGroup>
                      </div>}
                   
                  </FormGroup>
                  {(Object.keys(SelectedPayment).length > 0 && SelectedPayment.id === "2") && <div className="payment-btn">
                    <FormLabel style={{ minWidth: 50 }}></FormLabel>
                      <div className="bank-payment">
                        <FormLabel className="bank-pay-txt">{t("bank text")}</FormLabel>
                        <div className="row-center paymentOptionDetails">
                          <FormGroup className="bank-group">
                            <FormLabel className="bank-head">{t("bank name")}</FormLabel>
                            <FormLabel className="bank-detail">{SelectedService.bank_name}</FormLabel>
                          </FormGroup>
                          <FormGroup className="bank-group">
                            <FormLabel className="bank-head">{t("account no")}</FormLabel>
                            <FormLabel className="bank-detail">{SelectedService.account_number}</FormLabel>
                          </FormGroup>
                          <FormGroup className="bank-group">
                            <FormLabel className="bank-head">{t("branch")}</FormLabel>
                            <FormLabel className="bank-detail">{SelectedService.branch_name}</FormLabel>
                          </FormGroup>
                        </div>
                      </div>
                      </div>}
                    {Object.keys(SelectedPayment).length > 0 && <div className="payment-btn-start">
                    <FormLabel style={{ minWidth: 50 }}></FormLabel>
                    <div className="">
                      <LoadingButton
                        className="btn btn-primary pay-btn"
                        loadingIndicator="Loading…"
                        onClick={HandleSubmitClick}
                        variant="contained" >
                        <span>{SelectedPayment.id === "1" ? t("pay and proceed") : t("generate slip")}</span>
                      </LoadingButton>
                    </div>
                    </div>}
                </Grid>
              </Grid>
            </form>
          </div>
        </Container>
        <ServiceLoader />
      </div>
    </section>
  );
}

export default Makepayment;