import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import {
  NIN_NO_VALIDATE,
  REGISTER_OTP_URL,
  REGISTER_URL,
  RESEND_OTP,
} from "../../../utils/api-constant";
import { Link, useNavigate } from "react-router-dom";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Container from "@mui/material/Container";
import FormGroup from "@mui/material/FormGroup";
import FormLabel from "@mui/material/FormLabel";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import VisibilityOutlined from "@mui/icons-material/VisibilityOutlined";
import VisibilityOffOutlined from "@mui/icons-material/VisibilityOffOutlined";
import EventIcon from "@mui/icons-material/Event";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import validator from "validator";
import "./Register.css";
import { countries } from "../../../Files/registerCountry";
import LoadingButton from "@mui/lab/LoadingButton";
import Alert from "../../Alert/Alert";
import AuthContext from "../../../context/AuthContext";
import { useTranslation } from "react-i18next";
import EmojiFlagsIcon from "@mui/icons-material/EmojiFlags";
import PermIdentityIcon from "@mui/icons-material/PermIdentity";
import LockOpenIcon from "@mui/icons-material/LockOpen";
import { Radio, RadioGroup } from "@mui/material";
import mk from "../../../assets/Img/mk.png";
import other from "../../../assets/Img/other.jpg";
import mkw from "../../../assets/Img/mk-w.png";
import otherw from "../../../assets/Img/other-w.png";

const Register = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const authCtx = useContext(AuthContext);
  const [termsChecked, setTermsChecked] = useState(false);
  const [btnLoading, setBtnLoading] = useState(false);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [middleName, setMiddleName] = useState("");
  const [countryName, setCountryName] = useState("");
  const [countryList, setCountryList] = useState([]);
  const [ninNumber, setNinNumber] = useState("");
  const [passportNumber, setPassportNumber] = useState("");
  const [mobileNumber, setMobileNumber] = useState("");
  const [dob, setDob] = useState(null);
  const [otp, setOtp] = useState(null);
  // const [userType, setUserType] = useState("");
  const [eMail, setEMail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [RegResp, setRegResponse] = useState("");
  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleMouseDownPassword = () => setShowPassword(!showPassword);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const handleClickShowConfirmPassword = () =>
    setShowConfirmPassword(!showConfirmPassword);
  const handleMouseDownConfirmPassword = () =>
    setShowConfirmPassword(!showConfirmPassword);
  const [gender, setGender] = useState("");
  const [firstNameErr, setFirstNameErr] = useState("");
  const [lastNameErr, setLastNameErr] = useState("");
  const [mobileNumberErr, setMobileNumberErr] = useState("");
  const [dobErr, setDobErr] = useState("");
  const [genderErr, setGenderErr] = useState("");
  const [countryNameErr, setCountryNameErr] = useState("");
  const [ninNumberErr, setNinNumberErr] = useState("");
  const [passportNumberErr, setPassportNumberErr] = useState("");
  const [middleNameErr, setMiddleNameErr] = useState("");
  const [otpErr, setOtpErr] = useState(null);
  // const [userTypeErr, setUserTypeErr] = useState("");
  const [eMailErr, setEMailErr] = useState("");
  const [passwordErr, setPasswordErr] = useState("");
  const [confirmPasswordErr, setConfirmPasswordErr] = useState("");
  const [registerFormOpen, isRegisterFormOpen] = useState(true);
  const [otpFormOpen, isOtpFormOpen] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [alertType, setAlertType] = useState(false);
  const [termAlert, setTermAlert] = useState(false);
  const [isMalawi, setIsMalawi] = useState(false);
  const [isOtherCountry, setIsOtherCountry] = useState(false);
  const [radioValue, setRadioValue] = useState("");
  const [redioErr, setRadioErr] = useState("");
  // setEMail(""); setPassword(""); setConfirmPassword("");
  const [userNinInfo, setUserNinInfo] = useState([]);
  const [nrbBtnLoading, setNrbBtnLoading] = useState(false);

  const formetDateTime = (value) => {
    var date = new Date(value);
    const yyyy = date.getFullYear();
    let mm = date.getMonth() + 1;
    let dd = date.getDate();

    if (dd < 10) dd = "0" + dd;
    if (mm < 10) mm = "0" + mm;
    const formattedToday = yyyy + "-" + mm + "-" + dd;
    return formattedToday;
  };

  const [infoTab, setInfoTab] = useState({
    national: true,
    personal: false,
    access: false,
  });

  function validateForm() {
    let formIsValid = true;

    // if (!userType) {formIsValid = false;setUserTypeErr(t("required alert"));} else {setUserTypeErr("");}
    if (!eMail) {
      formIsValid = false;
      setEMailErr(t("required alert"));
    } else if (!validator.isEmail(eMail)) {
      formIsValid = false;
      setEMailErr(t("email valid alert"));
    } else {
      setEMailErr("");
    }

    if (!password) {
      formIsValid = false;
      setPasswordErr(t("required alert"));
    } else if (!validator.isStrongPassword(password)) {
      formIsValid = false;
      setPasswordErr(t("valid password alert"));
    } else {
      setPasswordErr("");
    }
    if (!confirmPassword) {
      formIsValid = false;
      setConfirmPasswordErr(t("required alert"));
    } else if (password !== confirmPassword) {
      formIsValid = false;
      setConfirmPasswordErr("Confirm password doesn't match password.");
    } else {
      setConfirmPasswordErr("");
    }
    if (!termsChecked) {
      formIsValid = false;
      setTermsChecked(false);
      setTermAlert(true);
    }
    getOtpForSignup(formIsValid);
  }
  function ValidateOtpForm(e) {
    e.preventDefault();
    let otpFormIsValid = true;
    if (!otp) {
      otpFormIsValid = false;
      setOtpErr(t("required alert"));
    } else {
      setOtpErr("");
    }
    Signup(otpFormIsValid);
  }
  const headers = {
    needJson: 1,
  };
  function getOtpForSignup(formIsValid) {
    if (formIsValid) {
      setBtnLoading(true);
      let userData = {
        fname: firstName,
        lname: lastName,
        sur_name: middleName,
        nationality: countryName,
        nin_number: ninNumber,
        passport_number: passportNumber,
        phone: mobileNumber,
        date_of_birth: dob,
        gender: gender,
        email: eMail,
        password: password,
        cpassword: confirmPassword,
      };
      localStorage.setItem("userData", JSON.stringify(userData));
      console.log(userData);
      axios
        .post(
          process.env.REACT_APP_HOST_URL + REGISTER_OTP_URL,
          {
            fname: firstName,
            email: eMail,
            phone: mobileNumber,
            password: password,
            cpassword: confirmPassword,
          },
          {
            headers: headers,
          }
        )
        .then((response) => {
          setRegResponse(response.data.message);
          if (response.data.success) {
            window.scrollTo(0, 0);
            isRegisterFormOpen(false);
            isOtpFormOpen(true);
            setShowAlert(false);
          }
          setBtnLoading(false);
          setAlertType(response.data.success);
          setShowAlert(true);
        });
    } else {
      window.scrollTo(0, 0);
    }
  }
  function Signup(otpFormIsValid) {
    if (otpFormIsValid) {
      setSubmitLoading(true);
      let params = JSON.parse(localStorage.getItem("userData"));
      axios
        .post(
          process.env.REACT_APP_HOST_URL + REGISTER_URL,
          {
            fname: params.fname,
            lname: params.lname,
            sur_name: params.sur_name,
            nationality: params.nationality,
            nin_number: params.nin_number,
            passport_number: params.passport_number,
            phone: params.phone,
            date_of_birth: params.date_of_birth,
            gender: params.gender,
            email: params.email,
            password: params.password,
            cpassword: params.cpassword,
            otp: otp,
          },
          {
            headers: headers,
          }
        )
        .then((response) => {
          setRegResponse(response.data.message);
          console.log(response.data);
          if (response.data.success) {
            localStorage.removeItem("userData");
            localStorage.setItem(
              "apiToken",
              JSON.stringify(response.data.apiToken)
            );
            localStorage.setItem(
              "userDetails",
              JSON.stringify(response.data.userDetails)
            );
            authCtx.authLogin();
            setSubmitLoading(false);
            navigate("/");
          } else {
            setSubmitLoading(false);
          }
          setAlertType(response.data.success);
          setShowAlert(true);
          //navigate("/");
        });
    }
  }

  const handleResendOtp = async (e) => {
    e.preventDefault();
    let user = JSON.parse(localStorage.getItem("userData"));
    const params = {
      headers: {
        needJson: 1,
      },
    };
    const data = {
      email: user.email,
      fname: user.fname,
    };
    await axios
      .post(process.env.REACT_APP_HOST_URL + RESEND_OTP, data, params)
      .then((response) => {
        setRegResponse(response.data.message);
        setAlertType(response.data.success);
        setShowAlert(true);
      })
      .catch((e) => {
        setRegResponse(e.message);
        setAlertType(false);
        setShowAlert(true);
      });
  };

  const label = (
    <span>
      {t("term label one")}{" "}
      
      <Link to="../cms-page/terms_and_conditions" className="term-link" target="_blank">
        {t("term label two")}
      </Link>
    </span>
  );

  // Restrict symbols in number
  const exceptThisSymbols = ["e", "E", "+", "-", "."];

  // input validation
  const validateFirstName = (e) => {
    var firstNameVal = e.target.value;
    setFirstName(firstNameVal);
    if (firstNameVal !== "") {
      setFirstNameErr("");
    } else {
      setFirstNameErr(t("required alert"));
    }
  };
  const validateLastName = (e) => {
    var lastNameVal = e.target.value;
    setLastName(lastNameVal);
    if (lastNameVal !== "") {
      setLastNameErr("");
    } else {
      setLastNameErr(t("required alert"));
    }
  };
  const validateMobileNo = (e) => {
    var mobileNoVal = e.target.value;
    setMobileNumber(mobileNoVal);
  
    if (mobileNoVal !== "") {
      if(countryName == "MALAWI")
      {
          if (/^\d{10}$/.test(mobileNoVal)) {  // Check if the input is exactly 10 digits
            setMobileNumberErr("");
          } else {
            setMobileNumberErr(t("Please enter a valid 10-digit mobile number"));
          }
      }else{
        if (/^\d{7,15}$/.test(mobileNoVal)) {  // Check if the input is between 7 and 15 digits
          setMobileNumberErr("");
        } else {
          setMobileNumberErr(t("Please enter a valid mobile number with 7 to 15 digits"));
        }
      }      
    } else {
      setMobileNumberErr(t("required alert"));
    }
  };
  
  const validateEmail = (e) => {
    var email = e.target.value;
    setEMail(email);
    if (email !== "") {
      if (validator.isEmail(email)) {
        setEMailErr("");
      } else {
        setEMailErr(t("email valid alert"));
      }
    } else {
      setEMailErr(t("required alert"));
    }
  };
  const validatePassword = (e) => {
    var passwordVal = e.target.value;
    setPassword(passwordVal);
    if (passwordVal !== "") {
      if (validator.isStrongPassword(passwordVal)) {
        setPasswordErr("");
      } else {
        setPasswordErr(t("valid password alert"));
      }
    } else {
      setPasswordErr(t("required alert"));
    }
  };
  const validateConfirmPassword = (e) => {
    var confirmPasswordVal = e.target.value;
    setConfirmPassword(confirmPasswordVal);
    if (confirmPasswordVal !== "") {
      setConfirmPasswordErr("");
    } else {
      setConfirmPasswordErr(t("required alert"));
    }
  };
  const validateGender = (e) => {
    setGender(e.target.value);
    if (e.target.value !== "") {
      setGenderErr("");
    } else {
      setGenderErr(t("required alert"));
    }
  };
  const handleNationlityChange = (value) => {
    if (value === "MALAWI") {
      setCountryName(value);
      setIsMalawi(true);
      setIsOtherCountry(false);
      setPassportNumber("");
      setPassportNumberErr("");
    } else if (value === "OTHER") {
      setCountryName("");
      setIsOtherCountry(true);
      setIsMalawi(false);
      setNinNumber("");
      setNinNumberErr("");
    }
    setRadioValue(value);
    setRadioErr("");
  };
  const handleOtherCountryChange = (value) => {
    setCountryName(value);
    if (!value) {
      setCountryNameErr(t("required alert"));
    } else {
      setCountryNameErr("");
    }
  };
  const handlePassportChange = (value) => {
    setPassportNumber(value);
    if (!value) {
      setPassportNumberErr(t("required alert"));
    } else {
      setPassportNumberErr("");
    }
  };

  const validateDob = (e) => {
    setDob(e.format("YYYY-MM-DD"));
    setDobErr("");
  };
  const handleCheckChange = () => {
    setTermsChecked(!termsChecked);
    setTermAlert(termsChecked);
  };
  const validateNin = (value) => {
    setNinNumber(value);
    if (value !== "") {
      setNinNumberErr("");
    } else {
      setNinNumberErr(t("required alert"));
    }
  };

  const handleOtpChange = (value) => {
    setOtp(value);
    if (!value) {
      setOtpErr(t("required alert"));
    } else {
      setOtpErr("");
    }
  };

  const handleStepAccesslInfo = (e) => {
    e.preventDefault();
    let formIsValid = true;
    if (!firstName) {
      formIsValid = false;
      setFirstNameErr(t("required alert"));
    } else if (isMalawi && firstName.trim().toLowerCase() !== userNinInfo.list[0].firstname.trim().toLowerCase()) {
      formIsValid = false;
      setFirstNameErr(t("firstname not match alert"));
    } else {
      setFirstNameErr("");
    }
    if (!lastName) {
      formIsValid = false;
      setLastNameErr(t("required alert"));
    } else if (isMalawi && lastName.trim().toLowerCase() !== userNinInfo.list[0].surname.trim().toLowerCase()) {
      formIsValid = false;
      setLastNameErr(t("lastname not match alert"));
    } else {
      setLastNameErr("");
    }
    if (!mobileNumber) {
      formIsValid = false;
      setMobileNumberErr(t("required alert"));
    } else if (mobileNumber.length !== 10) {
      console.log("mobileNumber--> "+mobileNumber.length);
      formIsValid = false;
      setMobileNumberErr(t("Please enter the valid mobile number"));
    } else {
      setMobileNumberErr("");
    }
    if (!dob) {
      formIsValid = false;
      setDobErr(t("required alert"));
    } else if (
      isMalawi &&
      dob !== formetDateTime(userNinInfo.list[0].dateobirth)
    ) {
      formIsValid = false;
      setDobErr(t("DOB not match alert"));
    } else {
      setDobErr("");
    }
    if (!gender) {
      formIsValid = false;
      setGenderErr(t("required alert"));
    } else if (isMalawi && gender !== userNinInfo.list[0].gender) {
      formIsValid = false;
      setGenderErr(t("gender not match alert"));
    } else {
      setGenderErr("");
    }
    if (
      middleName &&
      isMalawi &&
      middleName.trim().toLowerCase() !== userNinInfo.list[0].othernames.trim().toLowerCase()
    ) {
      formIsValid = false;
      setMiddleNameErr(t("middle not match alert"));
    } else {
      setMiddleNameErr("");
    }
    if (formIsValid) {
      setInfoTab({
        national: false,
        personal: false,
        access: true,
      });
    }
  };

  const handleStepPersonalInfo = async (e) => {
    e.preventDefault();
    let formIsValid = true;
    if (!radioValue) {
      formIsValid = false;
      setRadioErr(t("required alert"));
    }
    if (!countryName) {
      formIsValid = false;
      setCountryNameErr(t("required alert"));
    } else {
      setCountryNameErr("");
    }
    if (isMalawi) {
      if (!ninNumber) {
        formIsValid = false;
        setNinNumberErr(t("required alert"));
      } else {
        setNrbBtnLoading(true);
        const params = {
          headers: {
            "Content-Type": "application/json",
            needJson: 1,
          },
        };
        await axios
          .get(
            process.env.REACT_APP_HOST_URL + NIN_NO_VALIDATE + ninNumber,
            params
          )
          .then((res) => {
            console.log(res.data);
            if (res.data.success) {
              setUserNinInfo(res.data);
            } else {
              formIsValid = false;
              setNinNumberErr(t("valid national id alert"));
            }

            setNrbBtnLoading(false);
          })
          .catch((e) => {
            console.log(e.message);
            setNrbBtnLoading(false);
          });
      }
    } else {
      if (!passportNumber) {
        formIsValid = false;
        setPassportNumberErr(t("required alert"));
      } else {
        setPassportNumberErr("");
      }
    }
    if (formIsValid) {
      setInfoTab({
        national: false,
        personal: true,
        access: false,
      });
    }
  };

  document.body.onkeydown = function (e) {
    if (e.key === "Enter") {
      if (infoTab.national) {
        handleStepPersonalInfo(e);
      } else if (infoTab.personal) {
        handleStepAccesslInfo(e);
      } else if (infoTab.access && registerFormOpen) {
        validateForm(e);
      } else if (otpFormOpen) {
        ValidateOtpForm(e);
      }
    }
  };

  useEffect(() => {
    setCountryList(countries);
    let isAuth = JSON.parse(localStorage.getItem("apiToken"));
    if (isAuth && isAuth !== null) {
      navigate("/");
    }
  }, []);
  return (
    <div className="outer-common-ui login-center-box">
      <Container>
        <section
          className={`RgisterUI outer-inner login-box ${
            registerFormOpen ? "register-box" : ""
          }`}
        >
          <Grid>
            <Grid item sm={12}>
              {registerFormOpen && (
                <div className="formSection">
                  <Alert
                    setShow={setShowAlert}
                    show={showAlert}
                    msg={RegResp}
                    type={alertType}
                  />
                  <h3 className="head-h3">{t("register title")}</h3>
                  <form method="post" name="userRegistrationForm">
                    <div className="reg-tab-head">
                      <div
                        className={`ttl ${infoTab.national ? "active" : ""}`}
                      >
                        <EmojiFlagsIcon className="circle" />
                        <span>{t("nationality label")}</span>
                      </div>
                      <div
                        className={`ttl ${infoTab.personal ? "active" : ""}`}
                      >
                        <PermIdentityIcon className="circle" />

                        <span>{t("profile info text")}</span>
                      </div>
                      <div className={`ttl ${infoTab.access ? "active" : ""}`}>
                        <LockOpenIcon className="circle" />
                        <span>{t("access info text")}</span>
                      </div>
                    </div>
                    <div
                      className={`reg-tab-body ${
                        infoTab.national ? "active" : ""
                      }`}
                    >
                      <Grid container columnSpacing={{ xs: 0, sm: 4 }}>
                        <Grid item xs={12} sm={12} md={12}>
                          <FormGroup className="form-group">
                            <FormLabel required>{t("are you text")}</FormLabel>
                            <RadioGroup
                              aria-labelledby="demo-controlled-radio-buttons-group"
                              name="controlled-radio-buttons-group"
                              value={radioValue}
                              onChange={(e) =>
                                handleNationlityChange(e.target.value)
                              }
                              className="radio-group"
                            >
                              <FormControlLabel
                                value="MALAWI"
                                control={<Radio className="hidden" />}
                                label={
                                  <span
                                    className={`radio-box ${
                                      radioValue === "MALAWI" ? "active" : ""
                                    }`}
                                  >
                                    <img
                                      src={mk}
                                      className="log bl"
                                      alt="img"
                                    />{" "}
                                    <img
                                      src={mkw}
                                      className="log wt"
                                      alt="img"
                                    />
                                    {t("malawian option")}
                                  </span>
                                }
                              />
                              <FormControlLabel
                                value="OTHER"
                                control={<Radio className="hidden" />}
                                label={
                                  <span
                                    className={`radio-box ${
                                      radioValue === "OTHER" ? "active" : ""
                                    }`}
                                  >
                                    <img
                                      src={other}
                                      className="log bl"
                                      alt="img"
                                    />
                                    <img
                                      src={otherw}
                                      className="log wt"
                                      alt="img"
                                    />{" "}
                                    {t("foreigner option")}
                                  </span>
                                }
                              />
                            </RadioGroup>
                            <div className="errorMsg">{redioErr}</div>
                          </FormGroup>
                        </Grid>

                        {isMalawi && (
                          <Grid item xs={12} sm={6} md={4}>
                            <FormGroup className="form-group">
                              <FormLabel required>
                                {t("national id label")}
                              </FormLabel>
                              <TextField
                                className="form-control"
                                type="text"
                                size="small"
                                value={ninNumber}
                                onKeyDown={(e) =>
                                  exceptThisSymbols.includes(e.key) &&
                                  e.preventDefault()
                                }
                                onChange={(event) =>
                                  validateNin(event.target.value)
                                }
                              />
                              <div className="errorMsg">{ninNumberErr}</div>
                            </FormGroup>
                          </Grid>
                        )}
                        {isOtherCountry && (
                          <>
                            <Grid item xs={12} sm={6} md={4}>
                              <FormGroup className="form-group">
                                <FormLabel required>
                                  {t("country label")}
                                </FormLabel>
                                <Select
                                  value={countryName}
                                  size="small"
                                  onChange={(e) =>
                                    handleOtherCountryChange(e.target.value)
                                  }
                                >
                                  <MenuItem value="">
                                    <em>{t("choose a country option")}</em>
                                  </MenuItem>
                                  {countryList.map((e, key) => {
                                    return (
                                      <MenuItem key={key} value={e.label}>
                                        {e.label}
                                      </MenuItem>
                                    );
                                  })}
                                </Select>
                                <div className="errorMsg">{countryNameErr}</div>
                              </FormGroup>
                            </Grid>
                            <Grid item xs={12} sm={6} md={4}>
                              <FormGroup className="form-group">
                                <FormLabel required>
                                  {t("passport no label")}
                                </FormLabel>
                                <TextField
                                  className="form-control"
                                  type="text"
                                  size="small"
                                  value={passportNumber}
                                  onKeyDown={(e) =>
                                    exceptThisSymbols.includes(e.key) &&
                                    e.preventDefault()
                                  }
                                  onChange={(event) =>
                                    handlePassportChange(event.target.value)
                                  }
                                />
                                <div className="errorMsg">
                                  {passportNumberErr}
                                </div>
                              </FormGroup>
                            </Grid>
                          </>
                        )}
                      </Grid>
                      <div className="btn-group flex flex-end">
                        <LoadingButton
                          className="btn btn-primary width-fit"
                          loading={nrbBtnLoading}
                          loadingIndicator="Loading…"
                          onClick={handleStepPersonalInfo}
                        >
                          <span>{t("continue text")}</span>
                        </LoadingButton>
                      </div>
                    </div>
                    <div
                      className={`reg-tab-body ${
                        infoTab.personal ? "active" : ""
                      }`}
                    >
                      <Grid container columnSpacing={{ xs: 0, sm: 4 }}>
                        <Grid item xs={12} sm={6} md={4}>
                          <FormGroup className="form-group">
                            <FormLabel required>
                              {t("first name label")}
                            </FormLabel>
                            <TextField
                              className="form-control"
                              type="text"
                              size="small"
                              value={firstName}
                              onChange={(e) => validateFirstName(e)}
                            />
                            <div className="errorMsg">{firstNameErr}</div>
                          </FormGroup>
                        </Grid>
                        <Grid item xs={12} sm={6} md={4}>
                          <FormGroup className="form-group">
                            <FormLabel required>
                              {t("last name label")}
                            </FormLabel>
                            <TextField
                              className="form-control"
                              type="text"
                              size="small"
                              value={lastName}
                              onChange={(e) => validateLastName(e)}
                            />
                            <div className="errorMsg">{lastNameErr}</div>
                          </FormGroup>
                        </Grid>
                        <Grid item xs={12} sm={6} md={4}>
                          <FormGroup className="form-group">
                            <FormLabel>{t("middle name label")}</FormLabel>
                            <TextField
                              className="form-control"
                              type="text"
                              size="small"
                              value={middleName}
                              onChange={(event) =>
                                setMiddleName(event.target.value)
                              }
                            />
                            <div className="errorMsg">{middleNameErr}</div>
                          </FormGroup>
                        </Grid>
                        <Grid item xs={12} sm={6} md={4}>
                          <FormGroup className="form-group">
                            <FormLabel required>
                              {t("mobile no label")}
                            </FormLabel>
                            <TextField
                              className="form-control"
                              type="number"
                              size="small"
                              onKeyDown={(e) =>
                                exceptThisSymbols.includes(e.key) &&
                                e.preventDefault()
                              }
                              value={mobileNumber}
                              onChange={(e) => validateMobileNo(e)}
                            />
                            <div className="errorMsg">{mobileNumberErr}</div>
                          </FormGroup>
                        </Grid>
                        <Grid item xs={12} sm={6} md={4}>
                          <FormGroup className="form-group">
                            <FormLabel required>{t("DOB label")}</FormLabel>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                              <DesktopDatePicker
                                className="date-pick"
                                format="DD/MM/YYYY"
                                value={dob}
                                onChange={(e) => validateDob(e)}
                                renderInput={(params) => (
                                  <div>
                                    <EventIcon />
                                    <input {...params.inputProps} />
                                  </div>
                                )}
                                disableFuture={true}
                              />
                            </LocalizationProvider>
                            <div className="errorMsg">{dobErr}</div>
                          </FormGroup>
                        </Grid>
                        <Grid item xs={12} sm={6} md={4}>
                          <FormGroup className="form-group">
                            <FormLabel required htmlFor="gender-select">
                              {t("gender label")}
                            </FormLabel>
                            <Select
                              value={gender}
                              size="small"
                              onChange={(e) => validateGender(e)}
                            >
                              <MenuItem value="">
                                <em>{t("gender option")}</em>
                              </MenuItem>
                              <MenuItem value="Male">
                                {t("male option")}
                              </MenuItem>
                              <MenuItem value="Female">
                                {t("female option")}
                              </MenuItem>
                              <MenuItem value="Other">
                                {t("other option")}
                              </MenuItem>
                            </Select>
                            <div className="errorMsg">{genderErr}</div>
                          </FormGroup>
                        </Grid>
                      </Grid>
                      <div className="btn-group flex flex-space-between">
                        <LoadingButton
                          className="btn btn-back width-fit"
                          onClick={() =>
                            setInfoTab((prev) => ({
                              ...prev,
                              national: true,
                              personal: false,
                              access: false,
                            }))
                          }
                        >
                          <span>{t("back text")}</span>
                        </LoadingButton>
                        <LoadingButton
                          className="btn btn-primary width-fit"
                          onClick={handleStepAccesslInfo}
                        >
                          <span>{t("continue text")}</span>
                        </LoadingButton>
                      </div>
                    </div>

                    <div
                      className={`reg-tab-body ${
                        infoTab.access ? "active" : ""
                      }`}
                    >
                      <Grid container columnSpacing={{ xs: 0, sm: 4 }}>
                        <Grid item xs={12} sm={6} md={4}>
                          <FormGroup className="form-group">
                            <FormLabel required>{t("email label")}</FormLabel>
                            <TextField
                              className="form-control"
                              type="text"
                              size="small"
                              value={eMail}
                              onChange={(e) => validateEmail(e)}
                            />
                            <div className="errorMsg">{eMailErr}</div>
                          </FormGroup>
                        </Grid>
                        <Grid item xs={12} sm={6} md={4}>
                          <FormGroup className="form-group">
                            <FormLabel required>
                              {t("password label")}
                            </FormLabel>
                            <TextField
                              className="form-control"
                              size="small"
                              type={showPassword ? "text" : "password"}
                              value={password}
                              onChange={(e) => validatePassword(e)}
                              InputProps={{
                                endAdornment: (
                                  <InputAdornment position="end">
                                    <IconButton
                                      className="eye-icon"
                                      aria-label="toggle password visibility"
                                      onClick={handleClickShowPassword}
                                      onMouseDown={handleMouseDownPassword}
                                      edge="end"
                                    >
                                      {showPassword ? (
                                        <VisibilityOutlined />
                                      ) : (
                                        <VisibilityOffOutlined />
                                      )}
                                    </IconButton>
                                  </InputAdornment>
                                ),
                              }}
                            />
                            <div className="errorMsg">{passwordErr}</div>
                          </FormGroup>
                        </Grid>
                        <Grid item xs={12} sm={6} md={4}>
                          <FormGroup className="form-group">
                            <FormLabel required>
                              {t("confirm password label")}
                            </FormLabel>
                            <TextField
                              className="form-control"
                              size="small"
                              type={showConfirmPassword ? "text" : "password"}
                              value={confirmPassword}
                              onChange={(e) => validateConfirmPassword(e)}
                              InputProps={{
                                endAdornment: (
                                  <InputAdornment position="end">
                                    <IconButton
                                      className="eye-icon"
                                      aria-label="toggle password visibility"
                                      onClick={handleClickShowConfirmPassword}
                                      onMouseDown={
                                        handleMouseDownConfirmPassword
                                      }
                                      edge="end"
                                    >
                                      {showConfirmPassword ? (
                                        <VisibilityOutlined />
                                      ) : (
                                        <VisibilityOffOutlined />
                                      )}
                                    </IconButton>
                                  </InputAdornment>
                                ),
                              }}
                            />
                            <div className="errorMsg">{confirmPasswordErr}</div>
                          </FormGroup>
                        </Grid>
                        <Grid item xs={12} sm={6} md={4}>
                          <FormGroup className="form-group">
                            <FormControlLabel
                              control={
                                <Checkbox
                                  className="check-icon"
                                  checked={termsChecked}
                                  onChange={handleCheckChange}
                                />
                              }
                              label={label}
                            />
                            {termAlert && (
                              <div className="errorMsg">{t("term alert")}</div>
                            )}
                          </FormGroup>
                        </Grid>
                      </Grid>
                      <div className="btn-group flex flex-space-between">
                        <LoadingButton
                          className="btn btn-back width-fit"
                          onClick={() =>
                            setInfoTab((prev) => ({
                              ...prev,
                              national: false,
                              personal: true,
                              access: false,
                            }))
                          }
                        >
                          <span>{t("back text")}</span>
                        </LoadingButton>
                        <LoadingButton
                          className="btn btn-primary width-fit"
                          onClick={validateForm}
                          loading={btnLoading}
                          loadingIndicator="Loading…"
                        >
                          <span>{t("signup text")}</span>
                        </LoadingButton>
                      </div>
                      <div className="form-group"></div>
                    </div>
                  </form>
                </div>
              )}
              {otpFormOpen && (
                <div className="formSection otpForm">
                  <Alert
                    setShow={setShowAlert}
                    show={showAlert}
                    msg={RegResp}
                    type={alertType}
                  />
                  <form method="post" name="userOtpRegistrationForm">
                    <p>{t("verify text")}</p>
                    <FormGroup className="form-group">
                      <FormLabel required>
                        {t("verification code label")}
                      </FormLabel>
                      <TextField
                        className="form-control"
                        type="text"
                        size="small"
                        value={otp}
                        onChange={(event) =>
                          handleOtpChange(event.target.value)
                        }
                      />
                      <div className="errorMsg">{otpErr}</div>
                    </FormGroup>
                    <p>
                      {t("recived code text")}{" "}
                      <a className="clickText" href="/" onClick={handleResendOtp}>
                        {" "}
                        {t("click here text")}{" "}
                      </a>{" "}
                      {t("recive code text")}
                    </p>
                    <div className="form-group">
                      <LoadingButton
                        className="btn btn-primary"
                        onClick={ValidateOtpForm}
                        loading={submitLoading}
                        loadingIndicator="Loading…"
                      >
                        <span>{t("submit text")}</span>
                      </LoadingButton>
                    </div>
                  </form>
                </div>
              )}
            </Grid>
          </Grid>
        </section>
      </Container>
    </div>
  );
};

export default Register;
