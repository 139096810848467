import React, { useState, useEffect, useContext } from "react";
import { Grid, Tooltip, Container } from "@mui/material";
import "./Dashboard.css";
import { Link, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';
import { replaceSpaceWithDash } from "../../../utils/custom";

import { FAVORITE_SERVICES } from "../../../utils/api-constant";
import useApiFetch from "../../../hooks/useApiFetch";
import LoadingPage from "../../SeviceScreen/LoadingPage";


function FavoriteService  ()  {
  const { t } = useTranslation();
  const navigate = useNavigate();
  
  const { data, loading, error } = useApiFetch(
    process.env.REACT_APP_HOST_URL + FAVORITE_SERVICES
  );

  const fisrtCatgory = (cat) => {
    const array = cat.split(",");
    const category = replaceSpaceWithDash(array[0]);
    return category;
  };

  if (loading) return <LoadingPage />;
  if (error) return;
  
  const newData = data.list;
  //console.log(newData)

  return (

    <Container maxWidth="lg" className="Services-Section no-margin bg-white bottompaddingsm toppadding fvt-rootpad">
        <Grid container spacing={2}>
          {newData.length !== 0 ? (
            newData?.map((item) => (
              <Grid item xs={12} sm={6} md={6} lg={6} className="grid-boxes" key={item.id} id={item.id}>                                 
                    <div className="category-outer-box" 
                  onClick={() => navigate(`/detail/services/${fisrtCatgory(item.category_name)}/${replaceSpaceWithDash(item.service_name)}`, { state: { anonymous_user: 1, } })}>
                      <div className="left-img">
                        <img
                          src={`${process.env.REACT_APP_HOST_URL}${process.env.REACT_APP_IMAGE_URL}${item.icon}`}
                          alt={item.name}
                        />
                      </div>
                      <div className="right-cont">
                        <Tooltip title={item.service_name} placement="top-start">
                          <h4 className="count">{item.service_name}</h4>
                        </Tooltip>
                        <Tooltip title={item.description} placement="top-start">
                          <p className="count-txt">{item.description}</p>   
                        </Tooltip>                        
                      </div>
                    </div>                                  
              </Grid>
            ))
          ) : (
            <div className="no-data-found">
              <br />
              {t("no data text")}
              <br />
            </div>
          )}
        </Grid>
      </Container> 
    
        // <Container maxWidth="lg" className="bg-white fav_cont ">
        //   {newData.length > 0 ? (
        //   <Grid container spacing={2} className="fav_padding">            
        //       {newData.map((item, index) => (
        //           <Grid item xs={12} sm={6} md={4} lg={4} className="grid-boxes dashboard-box " key={item.id} id={item.id}>                  
        //               {/* <Link to={`/${replaceSpaceWithDash(item.name)}/services/${item.id}`}> */}
        //                 <div className="category-outer-box">                                                    
        //                   <div className="">
        //                     <span className="s_count">{item.servicesCount}</span>
        //                   </div>
        //                   <div className="right-cont">
        //                     <Tooltip title={item.service_name} placement="top-start">
        //                       <h4 className="cursor">{item.service_name}</h4>
        //                     </Tooltip>                            
        //                   </div>
        //                 </div>                      
        //           </Grid>
        //         ))}            
        //   </Grid>
        //   ) : ( <div className="center color_black padd-10">No Data Found</div>
        //   )}
        // </Container>      
  );
};

export default FavoriteService;
