import { Outlet, Link } from "react-router-dom";
import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import AsideUser from "./AsideUser";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import Container from "@mui/material/Container";
import "./Menu.css";
import "./Form.css";
import { useContext } from "react";
import AuthContext from "../../../context/AuthContext";
import { useTranslation } from "react-i18next";

const Menu = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const [apiToken, setApiToken] = useState(null);
  const authCtx = useContext(AuthContext);
  const [userDet, setUserDet] = useState([]);
  const [adminLayer, setAdminLayer] = useState(false);
  const isActive = (value) => (location.pathname === value ? "active" : "");

  useEffect(() => {
    if (
      localStorage.getItem("apiToken") !== "undefined" &&
      localStorage.getItem("apiToken") !== "" &&
      localStorage.getItem("apiToken") !== null
    ) {
      let apiToken = JSON.parse(localStorage.getItem("apiToken"));
      let loginData = JSON.parse(localStorage.getItem("userDetails"));
      setApiToken(apiToken);
      setUserDet(loginData);
    }
    const currentUrl = window.location.hash.split("/");
    if (currentUrl[1] === "my-account") {
      setAdminLayer(true);
    } else {
      setAdminLayer(false);
    }
  }, [location]);
  return (
    <>
      {authCtx.isLogin && adminLayer ? (
        <div className="admin-layout">
          <div className="outer-common-ui">
            <div className="outer-header">
              <Container>
                <div className="outer-header-inner">
                  <div className="left-div">
                    <h6>{t("my profile title")}</h6>
                  </div>
                </div>
              </Container>
            </div>
            <Container>
              <div className="outer-inner">
                <Box sx={{ flexGrow: 1 }}>
                  <Grid container>
                    <>
                      <Grid item className="aside-menu">
                        <AsideUser userDet={userDet} />
                        <nav className="aside-box">
                          <ul>
                            <li>
                                <span className="ttl">
                                  {t("Dashboard info text")}
                                </span>
                              </li>
                              <li>
                                <Link
                                  className={isActive(
                                    "/my-account/dashboard"
                                  )}
                                  to="/my-account/dashboard"
                                >
                                  <span>{t("Dashboard")}</span>{" "}
                                  <ArrowForwardIosIcon />
                                </Link>
                              </li>                              
                              <li>
                                <Link
                                  className={isActive(
                                    "/my-account/latest-application"
                                  )}
                                  to="/my-account/latest-application"
                                >
                                  <span>{t("My Applications")}</span>{" "}
                                  <ArrowForwardIosIcon />
                                </Link>
                              </li>
                            </ul>
                          <ul>
                            <li>
                              <span className="ttl">
                                {t("profile info text")}
                              </span>
                            </li>
                            <li>
                              <Link
                                className={isActive(
                                  "/my-account/profile-information"
                                )}
                                to="/my-account/profile-information"
                              >
                                <span>{t("profile info text")}</span>{" "}
                                <ArrowForwardIosIcon />
                              </Link>
                            </li>
                            <li>
                              <Link
                                className={isActive("/my-account/change-mail")}
                                to="/my-account/change-mail"
                              >
                                <span>{t("change mail text")}</span>{" "}
                                <ArrowForwardIosIcon />
                              </Link>
                            </li>
                            <li>
                              <Link
                                className={isActive(
                                  "/my-account/change-password"
                                )}
                                to="/my-account/change-password"
                              >
                                <span>{t("change password text")}</span>{" "}
                                <ArrowForwardIosIcon />
                              </Link>
                            </li>
                            <li>
                              <Link
                                className={isActive(
                                  "/my-account/my-notification"
                                )}
                                to="/my-account/my-notification"
                              >
                                <span>{t("my notification text")}</span>{" "}
                                <ArrowForwardIosIcon />
                              </Link>
                            </li>
                          </ul>
                          <ul>
                          <li>
                              <span className="ttl">
                                {t("Activity info text")}
                              </span>
                            </li>
                            <li>
                              <Link
                                className={isActive(
                                  "/my-account/my-activity"
                                )}
                                to="/my-account/my-activity"
                              >
                                <span>{t("my activity text")}</span>{" "}
                                <ArrowForwardIosIcon />
                              </Link>
                            </li>
                          </ul>
                        </nav>
                      </Grid>
                    </>
                    <Grid item xs>
                      <Outlet />
                    </Grid>
                  </Grid>
                </Box>
              </div>
            </Container>
          </div>
        </div>
      ) : (
        <div className="admin-layout">
          <Container
            maxWidth={""}
            disableGutters={!(authCtx.isLogin && adminLayer)}
          >
            <Box sx={{ flexGrow: 1 }}>
              <Grid container>
                <Grid item xs>
                  <Outlet />
                </Grid>
              </Grid>
            </Box>
          </Container>
        </div>
      )}
    </>
  );
};

export default Menu;
