import axios from "axios";
import { useEffect, useReducer } from "react";

const ACTIONS = {
  API_REQUEST: "api_request",
  FETCH_DATA: "fetch-data",
  ERROR: "error",
};

const initialState = {
  data: [],
  loading: true,
  error: null,
};

const reducer = (state, { type, payload }) => {
  switch (type) {
    case ACTIONS.API_REQUEST:
      return { ...state, data: [], loading: true };
    case ACTIONS.FETCH_DATA:
      return { ...state, data: payload, loading: false };
    case ACTIONS.ERROR:
      return { ...state, data: [], loading: false, error: payload };

    default:
      return state;
  }
};

const useApiFetch = (url) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    const fetchData = async () => {
      dispatch({ type: ACTIONS.API_REQUEST });
      const token = JSON.parse(localStorage.getItem("apiToken"));
      const params = {
        headers: {
          "Content-Type": "application/json",
          needJson: 1,
          apiToken: token,
        },
      };
      await axios
        .get(url, params)
        .then((res) => {
          dispatch({ type: ACTIONS.FETCH_DATA, payload: res.data });
        })
        .catch((e) => {
          dispatch({ type: ACTIONS.ERROR, payload: e.message });
        });
    };
    fetchData();
  }, [url]);

  return state;
};

export default useApiFetch;
