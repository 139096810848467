import React from "react";
import {
  Container,
  FormGroup,
  FormLabel,
  MenuItem,
  Select, Button
} from "@mui/material";

import Menu from "@mui/material/Menu";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import {
  CATEGORY_LISTALL,
  EXCHANGE_RATE,
  MDA_LISTALL,
} from "../../utils/api-constant";
import { useState } from "react";
import useApiFetch from "../../hooks/useApiFetch";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import LoadingPage from "./LoadingPage";
import { useTranslation } from "react-i18next";
import { replaceSpaceWithDash } from "../../utils/custom";
import { Link } from "react-router-dom";
import ErrorSection from "../Error/ErrorSection";
import { LoadingButton } from "@mui/lab";

const ExchangeRate = () => {
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = useState(null);
  const [limit, setLimit] = useState(5);
  const [filterValue, setFilterValue] = useState("");
  const [isShowLimitBtn, setIsShowLimitBtn] = useState(true);
  const open = Boolean(anchorEl);
  const [SearchValue, setSearchValue] = useState("");

  const {
    data: listData,
    loading: listLoading,
    error: listError,
  } = useApiFetch(process.env.REACT_APP_HOST_URL + CATEGORY_LISTALL);

  const {
    data: mdaDtata,
    loading: mdaLoading,
    error: mdaError,
  } = useApiFetch(process.env.REACT_APP_HOST_URL + MDA_LISTALL);

  const { data, loading, error } = useApiFetch(
    process.env.REACT_APP_HOST_URL + EXCHANGE_RATE
  );

  if (error || listError || mdaError) return <ErrorSection />;
  if (loading || listLoading || mdaLoading) return <LoadingPage />;

  if (data.list.code) return <ErrorSection />;

  const handleFilterOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleFilterClose = () => {
    setAnchorEl(null);
  };

  const handleLimitChange = () => {
    setLimit(data.list.length);
    setIsShowLimitBtn(false);
  };

  const handleFilterChange = (value) => {
    setSearchValue(value);
  };

  let listOfCurrency = (data.list && Array.isArray(data.list)) ? data.list.map((item) => item.currency) : [];

  listOfCurrency = listOfCurrency
    .sort() // sort and remove duplicates
    .filter((v, i) => listOfCurrency.indexOf(v) === i);

  const exchangeRate = data.list && data.list.filter((value) =>
    value.currency.toLowerCase().includes(filterValue.toLowerCase())
  );

  const currentUrl = window.location.hash.split("/");
  const cat = currentUrl[2];
  const serviceName = currentUrl[3];
  var currentService;
  if (cat === "services") {
    currentService = listData.list.find(
      (item) => replaceSpaceWithDash(item.name) === serviceName
    );
  }
  else if (cat === "mda") {
    currentService = mdaDtata.list.find(
      (item) => replaceSpaceWithDash(item.name) === serviceName
    );
  }
  if (!currentService) return <ErrorSection />;

  const resetInput = () => {
    setFilterValue("");
    setSearchValue("");
    setAnchorEl(null);
  };

  const handleSubmit = async (e) => {
    if (SearchValue === "All"){
      setFilterValue("");
    }else{
      setFilterValue(SearchValue);
    }
    setAnchorEl(null);
  }; 

  return (
    <>
      <div className="service-header">
        <Container>
          <div className="service-header-inner">
            <div className="left-div">
              <h6>{t("exchange rate list title")}</h6>
              <span className="desc">
                {" "}
                <Link
                  to={`/${replaceSpaceWithDash(currentService.name)}/${cat}/${
                    currentService.id
                  }`}
                >
                  {currentService.name}
                </Link>{" "}
                | {t("exchange rate list title")}
              </span>
            </div>
            <div className="right-div">
              <FilterAltIcon
                className="filter-icon"
                onClick={handleFilterOpen}
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
              />
              <Menu
                className="filter-dropdown"
                id="filter-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleFilterClose}
                MenuListProps={{
                  "aria-labelledby": "basic-button",
                }}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "right",
                }}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
              >
                <FormGroup className="form-group">
                  <FormLabel>{t("currency lable")}</FormLabel>
                  <Select
                    value={SearchValue}
                    size="small"
                    onChange={(e) => handleFilterChange(e.target.value)}
                  >
                    <MenuItem value="All">{t("all option")}</MenuItem>
                    {listOfCurrency.map((item) => (
                      <MenuItem value={item} key={item}>
                        {item}
                      </MenuItem>
                    ))}
                  </Select>
                </FormGroup>
                <div className="btn-group-cancel-apply">
                  <Button className="btn btn-cancel" onClick={resetInput}>
                    {t("Reset")}
                  </Button>
                  <LoadingButton
                    className="btn btn-submit"
                    loadingIndicator="Loading…"
                    onClick={handleSubmit} >
                    <span>{t("Apply")}</span>
                  </LoadingButton>
                </div>
              </Menu>
            </div>
          </div>
        </Container>
      </div>
      <Container>
        <div className="service-page-detail">
          <div className="table-scroll">
            <table>
              <thead>
                <tr>
                  <th className="left">{t("currency name text")}</th>
                  <th className="right">{t("buying text")}</th>
                  <th className="right">{t("middel text")}</th>
                  <th className="right">{t("selling text")}</th>
                </tr>
              </thead>
              <tbody>
              {Array.isArray(exchangeRate) && exchangeRate.length > 0 ? (
                  exchangeRate.slice(0, limit).map((item, index) => (
                    <tr key={index}>
                      <td className="left color-primary">
                        <b>{item.currency}</b>
                      </td>
                      <td className="right">{item.buying}</td>
                      <td className="right">{item.middle}</td>
                      <td className="right">{item.selling}</td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan="4">No data available</td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
          {isShowLimitBtn && exchangeRate.length > 5 && (
            <button className="btn-seemore" onClick={handleLimitChange}>
              {t("see more text")} <ExpandLessIcon className="arrow up" />
              <ExpandMoreIcon className="arrow down" />
            </button>
          )}
        </div>
      </Container>
    </>
  );
};

export default ExchangeRate;
