import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import Box from "@mui/material/Box";
import "./Dashboard.css";
import { useTranslation } from "react-i18next";
// import Paper from '@mui/material/Paper';
// import { styled } from '@mui/material/styles';
import ApplicationCount from "./ApplicationCount";
import ApplicationList from "./ApplicationList";
import FavoriteService from "./FavoriteService";
import { isMobile } from "../../../utils/custom";

function Dashboard  ()  {
  const { t } = useTranslation();
  const [formLoad, setFormLoad] = useState(false);
  const itemRef = useRef(null);

  /* const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  })); */
  
  useEffect(() => {
    console.log(isMobile());
    if (isMobile()) {
      console.log(itemRef.current);
      if (itemRef.current) {
        itemRef.current.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }, []);

  return (
    <section ref={itemRef} className="ProfileInformationUI dashUI" id="dashboard">
      <Box sx={{ flexGrow: 1 
}}>
        <div
          className={`account-formSection ${formLoad ? "form-loading" : ""}`}>
          
          {/* <h3 className="head-h3">{t("Dashboard info text")}</h3> */}

          <ApplicationCount/>

          <h3  className="head-h3 toppadding lat-flex">{t("LatestApplication info text")} 
          <Link to="../my-account/latest-application" className="f-right cursor viewall-txt"> {t("view All txt")} </Link>
          </h3>
          <ApplicationList/>

            <h3 className="head-h3 toppadding">{t("Services info text")}</h3>
              <FavoriteService/>
        </div>
      </Box>
    </section>
  );
};

export default Dashboard;
