import React, { useState, useEffect, useContext, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import { APP_LOGOUT, CHANGE_PASSWORD } from "../../../utils/api-constant";
import FormGroup from "@mui/material/FormGroup";
import FormLabel from "@mui/material/FormLabel";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import validator from "validator";
import LoadingButton from "@mui/lab/LoadingButton";
import Alert from "../../Alert/Alert";
import AuthContext from "../../../context/AuthContext";
import { useTranslation } from "react-i18next";
import { isMobile } from "../../../utils/custom";

const ChangePassword = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const authCtx = useContext(AuthContext);
  const [showCurrentPassword, setShowCurrentPassword] = useState(false);
  const handleClickShowCurrentPassword = () =>
    setShowCurrentPassword(!showCurrentPassword);
  const handleMouseDownCurrentPassword = () =>
    setShowCurrentPassword(!showCurrentPassword);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const handleClickShowNewPassword = () => setShowNewPassword(!showNewPassword);
  const handleMouseDownNewPassword = () => setShowNewPassword(!showNewPassword);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const handleClickShowConfirmPassword = () =>
    setShowConfirmPassword(!showConfirmPassword);
  const handleMouseDownConfirmPassword = () =>
    setShowConfirmPassword(!showConfirmPassword);
  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [currentPasswordErr, setCurrentPasswordErr] = useState("");
  const [newPasswordErr, setNewPasswordErr] = useState("");
  const [confirmPasswordErr, setConfirmPasswordErr] = useState("");
  const [apiToken, setApiToken] = useState(null);
  const location = useLocation();
  const [btnLoading, setBtnLoading] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [alertType, setAlertType] = useState(false);
  const [response, setResponse] = useState("");
  const itemRef = useRef(null);

  useEffect(() => {
    if (
      localStorage.getItem("apiToken") !== "undefined" &&
      localStorage.getItem("apiToken") !== "" &&
      localStorage.getItem("apiToken") !== null
    ) {
      let apiToken = JSON.parse(localStorage.getItem("apiToken"));
      setApiToken(apiToken);
    }
    //console.log(isMobile());
    if (isMobile()) {
      if (itemRef.current) {
        itemRef.current.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }, [location]);

  // input validation
  const validateCurrentPassword = (e) => {
    var value = e.target.value;
    setCurrentPassword(value);
    if (value !== "") {
      setCurrentPasswordErr("");
    } else {
      setCurrentPasswordErr(t("required alert"));
    }
  };
  const validateNewPassword = (e) => {
    var value = e.target.value;
    setNewPassword(value);
    if (value !== "") {
      if (validator.isStrongPassword(value)) {
        setNewPasswordErr("");
      } else {
        setNewPasswordErr(
          t("valid password alert")
        );
      }
    } else {
      setNewPasswordErr(t("required alert"));
    }
  };
  const validateConfirmPassword = (e) => {
    var value = e.target.value;
    setConfirmPassword(value);
    if (value !== "") {
      if (validator.isStrongPassword(value)) {
        setConfirmPasswordErr("");
      } else {
        setConfirmPasswordErr(
          t("valid password alert")
        );
      }
    } else {
      setConfirmPasswordErr(t("required alert"));
    }
  };
  function validateForm(e) {
    e.preventDefault()
    let formIsValid = true;
    if (!currentPassword) {
      formIsValid = false;
      setCurrentPasswordErr(t("required alert"));
    } else {
      setCurrentPasswordErr("");
    }
    if (!newPassword) {
      formIsValid = false;
      setNewPasswordErr(t("required alert"));
    } else if (!validator.isStrongPassword(newPassword)) {
      formIsValid = false;
      setNewPasswordErr(
        t("valid password alert")
      );
    } else if (currentPassword == newPassword) {
      formIsValid = false;
      setNewPasswordErr(t("password same alert"));
    } else {
      setNewPasswordErr("");
    }
    if (!confirmPassword) {
      formIsValid = false;
      setConfirmPasswordErr(t("required alert"));
    } else if (!validator.isStrongPassword(confirmPassword)) {
      formIsValid = false;
      setConfirmPasswordErr(
        t("valid password alert")
      );
    } else if (newPassword !== confirmPassword) {
      formIsValid = false;
      setConfirmPasswordErr(
        t("valid confirm password")
      );
    } else {
      setConfirmPasswordErr("");
    }
    ChangePassword(formIsValid);
  }

  function ChangePassword(formIsValid) {
    const headers = {
      needJson: 1,
      apiToken: apiToken,
      "Content-Type": "application/json",
    };
    if (formIsValid) {
      setBtnLoading(true);
      axios
        .post(
          process.env.REACT_APP_HOST_URL + CHANGE_PASSWORD,
          {
            oldPassword: currentPassword,
            newPassword: newPassword,
            confirmPassword: confirmPassword,
          },
          {
            headers: headers,
          }
        )
        .then((response) => {
          setAlertType(response.data.success);
          setResponse(response.data.message);
          setShowAlert(true);
          setBtnLoading(false);
          if (response.data.success) {
            setCurrentPassword("");
            setNewPassword("");
            setConfirmPassword("");
            axios
              .get(process.env.REACT_APP_HOST_URL + APP_LOGOUT, {
                headers: headers,
              })
              .then((response) => {
                if (response.data.success) {
                  localStorage.removeItem("apiToken");
                  localStorage.removeItem("userDetails");
                  authCtx.authLogout();
                }
              });
          }
        });
    }
  }  

  document.body.onkeydown = function (e) {
    if (e.key === "Enter") {
      validateForm(e);
    }
  };

  useEffect(() => {
    if (!authCtx.isLogin) {
      navigate("/");
    }
  }, [authCtx.isLogin]);

  return (
    <div ref={itemRef} className="account-formSection">
      <Alert
        setShow={setShowAlert}
        show={showAlert}
        msg={response}
        type={alertType}
      />
      <h3 className="head-h3">{t("change password text")}</h3>
      <form method="post" name="userLoginForm">
        <Grid container columnSpacing={{ xs: 0, sm: 4 }}>
          <Grid item xs={12} sm={6} md={6}>
            <FormGroup className="form-group">
              <FormLabel required>{t("current password label")}</FormLabel>
              <TextField
                className="form-control"
                size="small"
                type={showCurrentPassword ? "text" : "password"}
                onChange={(e) => validateCurrentPassword(e)}
                value={currentPassword}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        className="eye-icon"
                        aria-label="toggle password visibility"
                        onClick={handleClickShowCurrentPassword}
                        onMouseDown={handleMouseDownCurrentPassword}
                        edge="end"
                      >
                        {showCurrentPassword ? (
                          <Visibility />
                        ) : (
                          <VisibilityOff />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
              <div className="errorMsg">{currentPasswordErr}</div>
            </FormGroup>
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <FormGroup className="form-group">
              <FormLabel required>{t("new password label")}</FormLabel>
              <TextField
                className="form-control"
                size="small"
                type={showNewPassword ? "text" : "password"}
                onChange={(e) => validateNewPassword(e)}
                value={newPassword}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        className="eye-icon"
                        aria-label="toggle password visibility"
                        onClick={handleClickShowNewPassword}
                        onMouseDown={handleMouseDownNewPassword}
                        edge="end"
                      >
                        {showNewPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
              <div className="errorMsg">{newPasswordErr}</div>
            </FormGroup>
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <FormGroup className="form-group">
              <FormLabel required>{t("confirm password label")}</FormLabel>
              <TextField
                className="form-control"
                size="small"
                type={showConfirmPassword ? "text" : "password"}
                onChange={(e) => validateConfirmPassword(e)}
                value={confirmPassword}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        className="eye-icon"
                        aria-label="toggle password visibility"
                        onClick={handleClickShowConfirmPassword}
                        onMouseDown={handleMouseDownConfirmPassword}
                        edge="end"
                      >
                        {showConfirmPassword ? (
                          <Visibility />
                        ) : (
                          <VisibilityOff />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
              <div className="errorMsg">{confirmPasswordErr}</div>
            </FormGroup>
          </Grid>
          <div className="btn-group">
            <Button className="btn btn-cancel">{t("cancel text")}</Button>
            <LoadingButton
              className="btn btn-submit"
              onClick={validateForm}
              loading={btnLoading}
              loadingIndicator="Loading…"
            >
              <span>{t("submit small text")}</span>
            </LoadingButton>
          </div>
        </Grid>
      </form>
    </div>
  );
};

export default ChangePassword;
