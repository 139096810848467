import axios from "axios";
import { FormGroup, FormLabel, Grid, TextField } from "@mui/material";
import { Container } from "@mui/system";
import React, { useState } from "react";
import { CATEGORY_LISTALL, MDA_LISTALL, TTC_NUMBER_VALIATOR } from "../../utils/api-constant";
import { LoadingButton } from "@mui/lab";
import { useTranslation } from "react-i18next";
import LoadingPage from "./LoadingPage";
import { Link } from "react-router-dom";
import useApiFetch from "../../hooks/useApiFetch";
import { replaceSpaceWithDash } from "../../utils/custom";
import ErrorSection from "../Error/ErrorSection";

const TccValidator = () => {
  const { t } = useTranslation();
  const [tccNumber, setTccNumber] = useState("");
  const [error, setError] = useState('');
  const [popup, setPopup] = useState({
    status: false,
    details: {},
    list: "",
    error: false,
  });
  const [btnLoading, setBtnloading] = useState(false);

  const { data: listData, loading: listLoading,  error: listError } = useApiFetch(
    process.env.REACT_APP_HOST_URL + CATEGORY_LISTALL
  );

  const {
    data: mdaDtata,
    loading: mdaLoading,
    error: mdaError,
  } = useApiFetch(process.env.REACT_APP_HOST_URL + MDA_LISTALL);

  const handleInputChange = (value) => {
    value === "" ? setError(t("required alert")) : setError('');
    setTccNumber(value);
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    setBtnloading(true);
    if (tccNumber === "") {
      setError(t("required alert"));
    } else {
      const token = JSON.parse(localStorage.getItem("apiToken"));
      const params = {
        headers: {
          "Content-Type": "application/json",
          needJson: 1,
          apiToken: token,
        },
      };
      await axios
        .post(
          process.env.REACT_APP_HOST_URL + TTC_NUMBER_VALIATOR,
          // "https://eservicebackend.stutzen.in/api/external/static/validateTccStatic",
          {
            tccNumber,
          },
          params
        )
        .then(function (response) {
          if (response.data.success) {
            console.log(response.data.message);
            setError('');
            setPopup({
              status: true,
              details:
                typeof response.data.list.data === "object" && response.data.list.data,
              list:
                typeof response.data.list.data === "string" && response.data.list.data,
              error: typeof response.data.list.data === "object" ? true : false,
            });
          }else{
            setPopup({
              status: false,
              details: {},
              list: "",
              error: false,
            });
            setError(response.data.message);
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    }
    setBtnloading(false);
  };
  const formetDate = (value) => {
    var date = new Date(value);
    const yyyy = date.getFullYear();
    let mm = date.getMonth() + 1;
    let dd = date.getDate();
    if (dd < 10) dd = "0" + dd;
    if (mm < 10) mm = "0" + mm;
    const formattedToday = dd + "-" + mm + "-" + yyyy;
    return formattedToday;
  };

  document.body.onkeydown = function (e) {
    if (e.key === "Enter") {
      handleSubmit(e);
    }
  };
  if (listError || mdaError) return <ErrorSection />;
  if (listLoading || mdaLoading) return <LoadingPage />;
  const currentUrl = window.location.hash.split("/");
  const cat = currentUrl[2];
  const serviceName = currentUrl[3];
  var currentService;
  if (cat === "services") {
    currentService = listData.list.find(
      (item) => replaceSpaceWithDash(item.name) === serviceName
    );
  }
  else if (cat === "mda") {
    currentService = mdaDtata.list.find(
      (item) => replaceSpaceWithDash(item.name) === serviceName
    );
  }
  if (!currentService) return <ErrorSection />;
  return (
    <>
      <div className="service-header">
        <Container>
          <div className="service-header-inner">
            <div className="left-div">
              <h6>{t("tcc validation title")}</h6>
              <span className="desc">{" "}
                <Link
                  to={`/${replaceSpaceWithDash(currentService.name)}/${cat}/${
                    currentService.id
                  }`}
                >
                  {currentService.name}
                </Link>{" "} | {t("tcc validation title")}</span>
            </div>
          </div>
        </Container>
      </div>
      <Container>
        <div className="service-page-detail">
          <form className="tcc-validate">
            <Grid container columnSpacing={{ xs: 0, sm: 4 }}>
              <Grid item xs={12} sm={6} md={4}>
                <FormGroup className="form-group">
                  <FormLabel required>{t("tcc no label")}</FormLabel>
                  <TextField
                    placeholder={t("tcc no placeholder")}
                    className="form-control"
                    type="text"
                    size="small"
                    value={tccNumber}
                    onChange={(e) => handleInputChange(e.target.value)}
                  />
                  {error && (
                    <div className="errorMsg">{error}</div>
                  )}
                </FormGroup>
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <FormGroup className="form-group">
                  <LoadingButton
                    className="btn-submit"
                    onClick={handleSubmit}
                    loading={btnLoading}
                    loadingIndicator="Loading…"
                  >
                    <span>{t("submit small text")}</span>
                  </LoadingButton>
                </FormGroup>
              </Grid>
            </Grid>
          </form>
          {popup.status && (
            <div className="tcc-validate-detail">
              {popup.error ? (
                <>
                  <div className="ttl">
                    {t("tcc no label")} <span>#{popup.details.TCCNumber}</span>{" "}
                    {t("tcc valid text")}
                  </div>
                  <div className="detail-field">
                    <div className="field-li">
                      <span>{t("purpose of tcc label")}:</span>
                      {popup.details.PurposeOfTCC}
                    </div>
                    <div className="field-li">
                      <span>{t("date of issue label")}:</span>
                      {formetDate(popup.details.DateIssued)}
                    </div>
                    <div className="field-li">
                      <span>{t("expired date label")}:</span>
                      {formetDate(popup.details.ExpiryDate)}
                    </div>
                    <div className="field-li">
                      <span>{t("purpose of code label")}:</span>
                      {popup.details.PurposeCode}
                    </div>
                    <div className="field-li">
                      <span>{t("office code label")}:</span>
                      {popup.details.OfficeCode}
                    </div>
                    <div className="field-li">
                      <span>{t("tax year label")}:</span>
                      {popup.details.TaxYear}
                    </div>
                    <div className="field-li">
                      <span>{t("tpin label")}:</span>
                      {popup.details.TPIN}
                    </div>
                    <div className="field-li">
                      <span>{t("recipient name label")}:</span>
                      {popup.details.ReciepientName}
                    </div>
                    <div className="field-li">
                      <span>{t("recipient adr label")}:</span>
                      {popup.details.ReciepientAddress}
                    </div>
                  </div>
                </>
              ) : (
                <div className="error">{popup.list}</div>
              )}
            </div>
          )}
        </div>
      </Container>
    </>
  );
};

export default TccValidator;
