import React from "react";
import Banner from "./Banner/Banner";
import PopularServices from "./PopularServices/PopularServices";
import Services from "./Services/Services";
import WhatsNew from "./WhatsNew/WhatsNew";
import ServiceSchemeMda from "./ServiceSchemeMda/ServiceSchemeMda";
import Helpcenter from "./Helpcenter/Helpcenter";
const Home = () => {
  return (
    <div className="home-content">
      <Banner type="Banner" banner_name="home_banner" />
      <div className="main-container">
        <div className="layouts">
          <WhatsNew />
          <PopularServices />
          <Services />
          <ServiceSchemeMda />
          <Helpcenter />
        </div>
      </div>
    </div>
  );
};

export default Home;
