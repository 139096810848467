import { Container } from "@mui/material";
import React from "react";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import CancelRoundedIcon from "@mui/icons-material/CancelRounded";
import CheckCircleOutlineRoundedIcon from "@mui/icons-material/CheckCircleOutlineRounded";
import ErrorOutlineRoundedIcon from "@mui/icons-material/ErrorOutlineRounded";
import useApiFetch from "../../hooks/useApiFetch";
import { Link, useNavigate } from "react-router-dom";
import LoadingPage from "./LoadingPage";
import {
  CATEGORY_LISTALL,
  MDA_LISTALL,
  TCC_LIST_ALL,
} from "../../utils/api-constant";
import { useTranslation } from "react-i18next";
import { replaceSpaceWithDash } from "../../utils/custom";
import ErrorSection from "../Error/ErrorSection";

const TccView = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const {
    data: listData,
    loading: listLoading,
    error: listError,
  } = useApiFetch(process.env.REACT_APP_HOST_URL + CATEGORY_LISTALL);
  const { data, loading, error } = useApiFetch(
    process.env.REACT_APP_HOST_URL + TCC_LIST_ALL
  );
  const {
    data: mdaDtata,
    loading: mdaLoading,
    error: mdaError,
  } = useApiFetch(process.env.REACT_APP_HOST_URL + MDA_LISTALL);
  if (error || listError || mdaError) return <ErrorSection />;
  if (loading || listLoading || mdaLoading) return <LoadingPage />;
  const tccList = data.list;
  const formetDateTime = (value) => {
    var date = new Date(value);
    const yyyy = date.getFullYear();
    let mm = date.getMonth() + 1;
    let dd = date.getDate();
    let hr = date.getHours();
    let min = date.getMinutes();
    var ampm = "AM";

    if (hr >= 12) {
      ampm = "PM";
    }

    if (hr > 12) {
      hr = hr - 12;
    }

    if (dd < 10) dd = "0" + dd;
    if (mm < 10) mm = "0" + mm;
    const formattedToday =
      dd + "-" + mm + "-" + yyyy + " " + hr + ":" + min + " " + ampm;
    return formattedToday;
  };
  const currentUrl = window.location.hash.split("/");
  const cat = currentUrl[2];
  const serviceName = currentUrl[3];
  var currentService;
  if (cat === "services") {
    currentService = listData.list.find(
      (item) => replaceSpaceWithDash(item.name) === serviceName
    );
  } else if (cat === "mda") {
    currentService = mdaDtata.list.find(
      (item) => replaceSpaceWithDash(item.name) === serviceName
    );
  }
  if (!currentService) return <ErrorSection />;
  return (
    <>
      <div className="service-header">
        <Container>
          <div className="service-header-inner">
            <div className="left-div">
              <h6>{t("tcc application title")}</h6>
              <span className="desc">
                {" "}
                <Link
                  to={`/${replaceSpaceWithDash(currentService.name)}/${cat}/${
                    currentService.id
                  }`}
                >
                  {currentService.name}
                </Link>{" "}
                | {t("tcc application title")}
              </span>
            </div>
            <div className="right-div">
              <button
                className="btn-add-tcc"
                onClick={() => navigate("tcc-add")}
              >
                <AddCircleOutlineIcon className="plus-icon" />{" "}
                {t("add new tcc")}
              </button>
            </div>
          </div>
        </Container>
      </div>
      <Container>
        <div className="service-page-detail">
          {tccList?.map((item) => (
            <div className="tcc-view-list" key={item.id}>
              <div className="top">
                <div className="left">{item.application_ref}</div>
                <div className="right">
                  <AccessTimeIcon className="clock-icon" />{" "}
                  {formetDateTime(item.updated_at)}
                </div>
              </div>
              <div className="mid">
                {item.applicant_first_name} ({item.email_address})
              </div>
              <div className="bot">
                <div className="left">
                  <div className="field">
                    <span>{t("tcc type label")}</span>
                    {item.tcctype_codevalue}
                  </div>
                  <div className="field">
                    <span>{t("tpin label")}</span>
                    {item.tpin}
                  </div>
                  <div className="field">
                    <span>{t("mobile label")}</span>
                    {item.phone_number}
                  </div>
                  {item.tcc_number && (
                    <div className="field tcc-number">
                      <span>{t("tcc no label")}</span>
                      {item.tcc_number}
                    </div>
                  )}
                </div>
                <div className="right">
                  {item.approval_status === "Approved" && (
                    <div className="status success">
                      <CheckCircleOutlineRoundedIcon className="status-icon" />{" "}
                      {t("tcc success")}
                    </div>
                  )}
                  {item.approval_status === null && (
                    <div className="status pending">
                      <ErrorOutlineRoundedIcon className="status-icon" />{" "}
                      {t("tcc pending")}
                    </div>
                  )}
                  {item.approval_status === "Rejected" && (
                    <div className="status not-valid">
                      <CancelRoundedIcon className="status-icon" />{" "}
                      {item.approval_comments}
                    </div>
                  )}
                </div>
              </div>
            </div>
          ))}

          {tccList.length === 0 && (
            <div className="no-data-found">
              <br />
              {t("no data text")}
            </div>
          )}

          <button className="btn-add-tcc" onClick={() => navigate("tcc-add")}>
            <AddCircleOutlineIcon className="plus-icon" /> {t("add new tcc")}
          </button>
        </div>
      </Container>
    </>
  );
};

export default TccView;
