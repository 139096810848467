import React from "react";
import { Grid } from "@mui/material";
import "./Dashboard.css";
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';
import { APPLICATION_COUNT } from "../../../utils/api-constant";
import useApiFetch from "../../../hooks/useApiFetch";
import LoadingPage from "../../SeviceScreen/LoadingPage";
import applicationIcon from '../../../assets/Img/applicationIcon.png';
import progressingIcon from '../../../assets/Img/progressingIcon.png';
import completedIcon from '../../../assets/Img/completedIcon.png';

function ApplicationCount  ()  {
  
  const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  }));
  
  
  const { data, loading, error } = useApiFetch(
    process.env.REACT_APP_HOST_URL + APPLICATION_COUNT
  );

  if (loading) return <LoadingPage />;
  if (error) return;
  
  const applicationCount = data;
  console.log(applicationCount);

  return (
    
          <Grid container rowSpacing={1} paddingbottom={5} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
              <Grid item xs={12} sm={4} md={4} lg={4}>
                  <Item className="app_color flex-grid-item">
                    <div className="appDetail">
                      <span className="count-txt">Applications</span>
                      <span className="count">{applicationCount.totalCount}</span>
                    </div> 
                    <div className="box-icon">
            <img src={applicationIcon} alt="img" />
                    </div>                   
                  </Item>
              </Grid>
              <Grid item xs={12} sm={4} md={4} lg={4}>
                <Item className="process_color flex-grid-item">
                  <div className="appDetail">
                    <span className="count-txt">Processing</span>
                    <span className="count">{applicationCount.progressingCount}</span> 
                  </div>
                  <div className="box-icon">
            <img src={progressingIcon} alt="img" />
                  </div>                  
                </Item>
              </Grid>
              <Grid item xs={12} sm={4} md={4} lg={4}>
                <Item className="completed_color flex-grid-item">
                  <div className="appDetail">
                    <span className="count-txt">Completed</span>
                    <span className="count">{applicationCount.completedCount}</span>  
                  </div>
                  <div className="box-icon">
            <img src={completedIcon} alt="img" />
                  </div>                 
                </Item>
              </Grid>              
          </Grid>          
        
  );
};

export default ApplicationCount;
