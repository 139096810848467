import { Container } from "@mui/material";
import React from "react";
import "./LoadingPage.css";

const LoadingPage = () => {
  return (
    <div className="loader-sec">
      <div className="loading-header">
        <Container>
          <div className="ttl"></div>
        </Container>
      </div>
      <Container>
        <div className="loading-inner">
          <div className="movie--isloading">
            <div className="loading-image"></div>
            <div className="loading-content">
              <div className="loading-text-container">
                <div className="loading-main-text"></div>
                <div className="loading-sub-text"></div>
              </div>
              <div className="loading-btn"></div>
            </div>
          </div>
          <div className="movie--isloading">
            <div className="loading-image"></div>
            <div className="loading-content">
              <div className="loading-text-container">
                <div className="loading-main-text"></div>
                <div className="loading-sub-text"></div>
              </div>
              <div className="loading-btn"></div>
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
};

export default LoadingPage;
