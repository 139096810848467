import React, { useEffect, useState } from "react";
import axios from "axios";
import "./Dashboard.css";
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import LoadingPage from "../../SeviceScreen/LoadingPage";
import {APPLICATION_ACTIVITIES} from "../../../utils/api-constant";
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import { useTranslation } from "react-i18next";
import { Grid, Tooltip, Container } from "@mui/material";

const ApplicationDetailList = ({ open, refId, refType, onClose, serviceName, mdaName,applicationId }) => {
  const [data, setData] = useState([]);
  const { t } = useTranslation();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  let apiToken = JSON.parse(localStorage.getItem("apiToken"));

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      const params = {
        headers: {
          needJson: 1,
          "Content-Type": "application/json",
          apiToken,
        },
      };
      try {
        const response = await axios.get(`${process.env.REACT_APP_HOST_URL + APPLICATION_ACTIVITIES}?refId=${applicationId}&refType=${refType}`,params);
        setData(response.data.list);
        setLoading(false);
      } catch (err) {
        setError(err);
        setLoading(false);
      }
    };

    if (open) {
      fetchData();
    }
  }, [open, refId, refType]);

  if (loading) return <LoadingPage />;
  if (error) return <div>Error: {error.message}</div>;
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return new Intl.DateTimeFormat('en-GB').format(date); // Formats date to dd/mm/yyyy
  };

  return (
    <div className="popup-overlay">
      <div className="popup-content">
        {/* <button className="close-button" onClick={onClose}>Close</button> */}
        <HighlightOffIcon className="f-right cursor" onClick={onClose}/> 
        <p className="no-margin center">{t("application details")}</p>
        <Grid container spacing={2} className="viewall toppaddingsm">
          <Grid item xs={12} sm={4} md={4} className="no-padd-top">
            <label>{t("application id")} :</label> <br/> <span className="label-txt">{applicationId}</span>
          </Grid>
          <Grid item xs={12} sm={8} md={8} className="no-padd-top">
            <label>{t("mda")} :</label> <br/> <span className="label-txt">{mdaName}</span> 
          </Grid>   
          <Grid item xs={12} className="no-padd-top">
            <label>{t("service name")} :</label> <br/> <span className="label-txt">{serviceName}</span> 
          </Grid>
                 
        </Grid>
        <TableContainer component={Paper} paddingBottom={5} className="app_table_detail app_table topmargin">
          <Table sx={{ minWidth: 650 }} aria-label="Application List">
            <TableHead>
              <TableRow>                
                <TableCell className="width_xss"><b>{t("date")} </b></TableCell>                                                
                <TableCell className="width_sm"><b>{t("description")} </b></TableCell>
                <TableCell className="width_sm"><b>{t("remarks")} </b></TableCell>
                <TableCell className="width_xs"><b>{t("status")} </b></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data.length === 0 ? (
                <TableRow>
                  <TableCell colSpan={4} align="center">
                      No data found
                  </TableCell>
                </TableRow>
              ) : (
                data.map((row) => (
                  <TableRow key={row.ref_id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                    <TableCell className="width_xss">{formatDate(row.date)}</TableCell>
                    {/* <TableCell>{row.type_of_operation}</TableCell> */}
                    <TableCell className="width_sm">{row.description}</TableCell>
                    <TableCell className="width_sm">{row.remarks}</TableCell>
                    <TableCell className="width_xs">
                      {row.status === 'Processing' ? (
                        <span className="statusBatch processing_color">{row.status}</span>
                      ) : row.status === 'Incomplete' ? (
                        <span className="statusBatch incomplete_color">{row.status}</span>
                      ) : row.status === 'Completed' ? (
                        <span className="statusBatch complete_color">{row.status}</span>
                      ) : row.status === 'Approved' ? (
                        <span className="statusBatch approved_color">{row.status}</span>
                      ) : (
                        <span className="statusBatch rejected_color">{row.status}</span>
                      )}
                    </TableCell>
                    {/* <TableCell className="right cursor">
                      <VisibilityIcon onClick={() => handleIconClick(row.ref_id, row.ref_type)} />
                    </TableCell> */}
                  </TableRow>
                ))
              )}
            </TableBody>

          </Table>
        </TableContainer>
      </div>
    </div>
  );
};

export default ApplicationDetailList;
