import React, { useState, useEffect } from "react";
import axios from "axios";
import { FORGOT_PASSWORD } from "../../../utils/api-constant";
import { useNavigate } from "react-router-dom";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Container from "@mui/material/Container";
import FormGroup from "@mui/material/FormGroup";
import FormLabel from "@mui/material/FormLabel";
import TextField from "@mui/material/TextField";
import "./Password.css";
import validator from "validator";
import Modal from "@mui/material/Modal";
import Typography from "@mui/material/Typography";
import LoadingButton from "@mui/lab/LoadingButton";
import Alert from "../../Alert/Alert";
import { useTranslation } from "react-i18next";

const ForgotPassword = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState("");
  const [setResp, setResponse] = useState("");
  const [btnLoading, setBtnLoading] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [alertType, setAlertType] = useState(false);

  // Input validation
  const validateEmail = (e) => {
    var email = e.target.value;
    setEmail(email);
    if (email !== "") {
      if (validator.isEmail(email)) {
        setEmailError("");
      } else {
        setEmailError(t("email valid alert"));
      }
    } else {
      setEmailError(t("required alert"));
    }
  };
  function validateForm() {
    let formIsValid = true;
    if (!email) {
      formIsValid = false;
      setEmailError(t("required alert"));
    } else {
      setEmailError("");
    }
    ForgotPassword(formIsValid);
  }
  const headers = {
    needJson: 1,
  };
  function ForgotPassword(formIsValid) {
    if (formIsValid) {
      setBtnLoading(true);
      axios
        .post(
          process.env.REACT_APP_HOST_URL + FORGOT_PASSWORD,
          {
            email: email,
            via: "web",
          },
          {
            headers: headers,
          }
        )
        .then((response) => {
          setResponse(response.data.message);
          setShowAlert(true);
          setAlertType(response.data.success);
          console.log(response.data.message);
          setBtnLoading(false);
          setEmail(""); // Clear the email input
          //   if (response.data.success) {
          //      navigate("/Resetpassword");
          // }
        });
    }
  } 

  document.body.onkeydown = function (e) {
    if (e.key === "Enter") {
      validateForm(e);
    }
  };
  useEffect(() => {
    let isAuth = JSON.parse(localStorage.getItem("apiToken"));
    if (isAuth && isAuth !== null) {
      navigate("/");
    }
  }, []);
  return (
    <div className="outer-common-ui login-center-box">      
      <Container>
        <section className="ForgotUI outer-inner login-box">
          <div className="grid-con">
            <Grid>              
              <Grid item sm={12}>
                <div className="formSection">
                  <Alert
                    setShow={setShowAlert}
                    show={showAlert}
                    msg={setResp}
                    type={alertType}
                  />
                  <h3 className="head-h3">{t("Forgot passwrod title")}</h3>
                  <form method="post" name="forgotPasswardForm">
                    <FormGroup className="form-group">
                      <FormLabel required>{t("email label")}</FormLabel>
                      <TextField
                        className="form-control"
                        type="text"
                        size="small"
                        value={email}
                        onChange={(e) => validateEmail(e)}
                      />
                      <div className="errorMsg">{emailError}</div>
                    </FormGroup>
                    <div className="form-group button">
                      <LoadingButton
                        className="btn btn-primary"
                        onClick={validateForm}
                        loading={btnLoading}
                        loadingIndicator="Loading…"
                      >
                        <span>{t("submit text")}</span>
                      </LoadingButton>
                    </div>
                    <p className="reg-text">
                      {t("back to login text")}{" "}
                      <span onClick={() => navigate("/login")}>
                        {t("click here text")}
                      </span>
                    </p>
                  </form>
                </div>
              </Grid>
            </Grid>
          </div>
        </section>
      </Container>
    </div>
  );
};

export default ForgotPassword;
