import React from "react";
import "./Loader.css"

const PageLoader = () => {
  return (
    <div className="page-loader">
      <div className="page-loader-inner">
      <div className="loader-icon"></div>
      </div>
    </div>
  );
};

export default PageLoader;
