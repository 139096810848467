import React, { useState } from "react";
import "./Dashboard.css";
import { useTranslation } from "react-i18next";
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import LoadingPage from "../../SeviceScreen/LoadingPage";
import VisibilityIcon from '@mui/icons-material/Visibility';
import ApplicationDetailList from "./ApplicationListDetail";
import { LATEST_APPLICATIONS } from "../../../utils/api-constant";
import useApiFetch from "../../../hooks/useApiFetch";


const ApplicationList = () => {
  const { t } = useTranslation();
  
  const [popupState, setPopupState] = useState({ open: false, refId: null, refType: null, serviceName:'', mdaName:'', applicationId:null });

  const { data, loading, error } = useApiFetch(
    process.env.REACT_APP_HOST_URL + LATEST_APPLICATIONS + '?limit=5'
  );
  const newData = data.list;
  const handleIconClick = (refId, refType, serviceName, mdaName, applicationId) => {
    setPopupState({ open: true, refId, refType, serviceName, mdaName, applicationId });
  };

  const handleClosePopup = () => {
    setPopupState({ open: false, refId: null, refType: null, serviceName:'', mdaName:'', applicationId:null });
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return new Intl.DateTimeFormat('en-GB').format(date); // Formats date to dd/mm/yyyy
  };

  if (loading) return <LoadingPage />;
  if (error) return <div>Error: {error.message}</div>;

  return (
    <div>
      <TableContainer component={Paper} paddingbottom={5} className="app_table">
        <Table sx={{ minWidth: 650 }} aria-label="Application List">
          <TableHead>
            <TableRow>
              <TableCell><b>{t("id")}</b></TableCell>
              <TableCell className="width_xs"><b>{t("date")}</b></TableCell>
              <TableCell><b>{t("service name")}</b></TableCell>
              <TableCell><b>{t("mda")}</b></TableCell>
              <TableCell><b>{t("status")}</b></TableCell>
              <TableCell className="right"><b>{t("action")}</b></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
          {newData.length === 0 ? (
                  <TableRow>
                    <TableCell colSpan={6} align="center">
                        No data found
                    </TableCell>
                  </TableRow>
            ):(
              newData.map((row) => (
                <TableRow key={row.id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                  <TableCell>{row.id}</TableCell>
                  <TableCell className="width_xs">{formatDate(row.date)}</TableCell>
                  <TableCell>{row.service_name}</TableCell>
                  <TableCell>{row.mda_name}</TableCell>
                  <TableCell>
                    {row.status === 'Processing' ? (
                      <span className="statusBatch processing_color">{row.status}</span>
                    ) : row.status === 'Incomplete' ? (
                      <span className="statusBatch incomplete_color">{row.status}</span>
                    ) : row.status === 'Completed' ? (
                      <span className="statusBatch complete_color">{row.status}</span>
                    ) : row.status === 'Approved' ? (
                      <span className="statusBatch approved_color">{row.status}</span>
                    ) : (
                      <span className="statusBatch rejected_color">{row.status}</span>
                    )}
                  </TableCell>
                  <TableCell className="right cursor">
                    <VisibilityIcon onClick={() => handleIconClick(row.ref_id, row.ref_type,row.service_name,row.mda_name, row.id)} />
                  </TableCell>
                </TableRow>
              ))
            )}
          </TableBody>
        </Table>
      </TableContainer>

    

      {popupState.open && (
        <ApplicationDetailList
          open={popupState.open}
          refId={popupState.refId}
          applicationId={popupState.applicationId}
          refType={popupState.refType}
          onClose={handleClosePopup}
          serviceName={popupState.serviceName}
          mdaName={popupState.mdaName}
        />
      )}
    </div>
  );
};

export default ApplicationList;
