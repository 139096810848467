import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import Paper from '@mui/material/Paper';
import { FormGroup, FormLabel, Grid, MenuItem, Select } from "@mui/material";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TablePagination from '@mui/material/TablePagination';
import LoadingPage from "../../SeviceScreen/LoadingPage";
import VisibilityIcon from '@mui/icons-material/Visibility';
import { useTranslation } from "react-i18next";
import '../Dashboard/Dashboard.css';
import Box from "@mui/material/Box";
import ApplicationDetailList from "../Dashboard/ApplicationListDetail"
import { LATEST_APPLICATIONS, SERVICE_LISTALL } from "../../../utils/api-constant";
import useApiFetch from "../../../hooks/useApiFetch";
import { isMobile } from "../../../utils/custom";

function ApplicationListAll() {
  const { t } = useTranslation();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [totalCount, setTotalCount] = useState(0);
  const [SearchText, setSearchText] = useState("");
  const [selectedService, setSelectedService] = useState("all"); // Default to "all"
  const apiToken = JSON.parse(localStorage.getItem("apiToken"));
  const [popupState, setPopupState] = useState({ open: false, refId: null, refType: null, serviceName:'', mdaName:'', applicationId:null });
  const itemRef = useRef(null);

  useEffect(() => {
    fetchData();
    //console.log(isMobile());
    if (isMobile()) {
      if (itemRef.current) {
        itemRef.current.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }, [page, rowsPerPage, selectedService]);

  const fetchData = async () => {
    setLoading(true);
    try {
      await getApplicationListAll(page * rowsPerPage, rowsPerPage, selectedService);
    } catch (err) {
      setError(err);
      setLoading(false);
    }
  };

  const getApplicationListAll = async (start, limit, serviceId = "all") => {
    const params = {
      headers: {
        needJson: 1,
        "Content-Type": "application/json",
        apiToken,
      },
    };

    try {
      const response = await axios.get(`${process.env.REACT_APP_HOST_URL + LATEST_APPLICATIONS}?start=${start}&limit=${limit}&serviceId=${serviceId === "all" ? "" : serviceId}`, params);
      setData(response.data.list);
      setTotalCount(response.data.total); // Assuming the API returns the total number of items
      setLoading(false);
    } catch (e) {
      setLoading(false);
      setError(e.message);
    }
  };

  const {
    data: listData,
    loading: listLoading,
    error: listError,
  } = useApiFetch(process.env.REACT_APP_HOST_URL + SERVICE_LISTALL);

  const handleIconClick = (refId, refType, serviceName, mdaName, applicationId) => {
    setPopupState({ open: true, refId, refType, serviceName, mdaName, applicationId });
  };

  const handleClosePopup = () => {
    setPopupState({ open: false, refId: null, refType: null, serviceName:'', mdaName:'', applicationId:null });
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0); // Reset to first page when rows per page changes
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return new Intl.DateTimeFormat('en-GB').format(date); // Formats date to dd/mm/yyyy
  };

  const handleServiceChange = (event) => {
    const serviceId = event.target.value;
    setSelectedService(serviceId);
    const selectedServiceName = listData?.list?.find(service => service.id === serviceId)?.name || t("All");
    setSearchText(selectedServiceName);
  };

  if (error) return <div>Error: {error}</div>;

  return (
    loading
      ? <div ref={itemRef} className="account-formSection notify-list-page form-loading">
        <LoadingPage />
      </div>
      : <section ref={itemRef} className="ProfileInformationUI all-app">
      <Box sx={{ flexGrow: 1 }}>
        <div className={`account-formSection ${loading ? "form-loading" : ""}`}>
          <div className="dash-app-table latest-app-table">
            <h3 className="head-h3">{t("MYApplication info text")}</h3>

            {listData && listData.list && listData.list.length > 0 && (
              <Grid>
                <Grid item xs={12} sm={6}>
                  <FormGroup className="form-group">
                    <FormLabel>{t("service label")}</FormLabel>
                    <Select
                      onChange={handleServiceChange}
                      value={selectedService}
                    >
                      <MenuItem value="all">{t("All")}</MenuItem>
                      {listData.list.map((item) => (
                        <MenuItem value={item.id} key={item.id}>
                          {item.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormGroup>
                </Grid>
              </Grid>
            )}

            <TableContainer component={Paper} paddingBottom={5} className="app_table">
              <Table sx={{ minWidth: 650 }} aria-label="Application List">
                <TableHead>
                  <TableRow>
                  <TableCell className=""><b>{t("id")}</b></TableCell>
                    <TableCell className="width_xs"><b>{t("date")}</b></TableCell>
                    <TableCell><b>{t("service name")}</b></TableCell>
                    <TableCell><b>{t("mda")}</b></TableCell>
                    <TableCell><b>{t("status")}</b></TableCell>
                    <TableCell className="right"><b>{t("action")}</b></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                {data.length === 0 ? (
                  <TableRow>
                    <TableCell colSpan={6} align="center">
                        No data found
                    </TableCell>
                  </TableRow>
                ):(
                  data.map((row) => (
                    <TableRow key={row.id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                      <TableCell className="">{row.id}</TableCell>
                      <TableCell className="width_xs">{formatDate(row.date)}</TableCell>
                      <TableCell>{row.service_name}</TableCell>
                      <TableCell>{row.mda_name}</TableCell>
                      <TableCell>
                        {row.status === 'Processing' ? (
                          <span className="statusBatch processing_color">{row.status}</span>
                        ) : row.status === 'Incomplete' ? (
                          <span className="statusBatch incomplete_color">{row.status}</span>
                        ) : row.status === 'Completed' ? (
                          <span className="statusBatch complete_color">{row.status}</span>
                        ) : row.status === 'Approved' ? (
                          <span className="statusBatch approved_color">{row.status}</span>
                        ) : (
                          <span className="statusBatch rejected_color">{row.status}</span>
                        )}
                      </TableCell>
                      <TableCell className="right cursor">
                        <VisibilityIcon onClick={() => handleIconClick(row.ref_id, row.ref_type, row.service_name, row.mda_name, row.id)} />
                      </TableCell>
                    </TableRow>
                  ))
                )}
                </TableBody>
              </Table>
            </TableContainer>
            {data.length > 0 && (    
              <TablePagination
                rowsPerPageOptions={[25, 50, 75, 100]}
                component="div"
                count={totalCount}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
          )}
          </div>
        </div>
      </Box>
      {popupState.open && (
        <ApplicationDetailList
          open={popupState.open}
          refId={popupState.refId}
          refType={popupState.refType}
          applicationId={popupState.applicationId}
          onClose={handleClosePopup}
          serviceName={popupState.serviceName}
          mdaName={popupState.mdaName}
        />
      )}
    </section>
  );
};

export default ApplicationListAll;
