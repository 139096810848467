import { Container } from "@mui/system";
import React from "react";

const LoadingBanner = () => {
  return (
    <div className="loading-banner">
      <Container>
        <div className="banner-content">
          <div className="movie--isloading">
            <div className="loading-image"></div>
            <div className="loading-content">
              <div className="loading-text-container">
                <div className="loading-main-text"></div>
                <div className="loading-sub-text"></div>
              </div>
              <div className="loading-btn"></div>
            </div>
          </div>
          <div className="movie--isloading">
            <div className="loading-image"></div>
            <div className="loading-content">
              <div className="loading-text-container">
                <div className="loading-main-text"></div>
                <div className="loading-sub-text"></div>
              </div>
              <div className="loading-btn"></div>
            </div>
          </div>
          <div className="movie--isloading">
            <div className="loading-image"></div>
            <div className="loading-content">
              <div className="loading-text-container">
                <div className="loading-main-text"></div>
                <div className="loading-sub-text"></div>
              </div>
              <div className="loading-btn"></div>
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
};

export default LoadingBanner;
