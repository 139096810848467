import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Grid } from "@mui/material";
import rightArrow from "../../../assets/Img/right-arr.png";
import "./PopularServiceBoxGrid.css";
import { useTranslation } from "react-i18next";
import { replaceSpaceWithDash } from "../../../utils/custom";

const PopularServiceBoxGrid = (props) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    setTimeout(() => {
      const apiData = props?.boxGridApi?.list || [];
      //console.log(apiData)
      setData(apiData);
      setLoading(false);
    }, 3000);
  }, [props.boxGridApi]);
  const fisrtCatgory = (cat) => {
    const array = cat.split(",");
    const category = replaceSpaceWithDash(array[0]);
    return category;
  };
  return (
    <Grid container spacing={3}>
      <Grid item xs={12} sm={6} md={6} lg={6} className="">
        <div className="freq-head">
          <h2>{t("frequently used sercive")}</h2>
          <p>{t("frequently used sercive description")}</p>
        </div>
      </Grid>
      {typeof props != "undefined" ? (
        props?.boxGridApi?.list?.map((item) => (
          <Grid
            item
            xs={12}
            sm={6}
            md={3}
            lg={3}
            className="grid-boxes"
            key={item.id}
            id={item.id}
          >
            <div className="outer-box">
              {/* <div className="top-img">
                {loading ? (
                 
                  <CircularProgress />
                ) : (
                
                  <img src={`${process.env.REACT_APP_HOST_URL}${process.env.REACT_APP_IMAGE_URL}${item.icon}`} alt={item.name} />
                )}
              </div> */}
              <div className="inner-box">
                <h6>{item.name}</h6>
                {/* <p>{item.description}</p> */}
                <div
                style={{ cursor: 'pointer' }}
                  onClick={() => navigate(`/detail/services/${fisrtCatgory(item.category_name)}/${replaceSpaceWithDash(item.name)}`, { state: { anonymous_user: item.anonymous_user, } })}
                >
                  <img src={rightArrow} alt="Proceed" />
                </div>
              </div>
            </div>
          </Grid>
        ))
      ) : (
          <p>{t("No Data Found")}</p>
      )}
    </Grid>
  );
};
export default PopularServiceBoxGrid;
