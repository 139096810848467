import React, { useContext } from "react";
import user from "../../../assets/Img/user.png";
import PowerSettingsNewIcon from "@mui/icons-material/PowerSettingsNew";
import AuthContext from "../../../context/AuthContext";
import { APP_LOGOUT } from "../../../utils/api-constant";
import axios from "axios";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

const AsideUser = ({ userDet }) => {
  const { t } = useTranslation();
  const authCtx = useContext(AuthContext);
  const apiToken = JSON.parse(localStorage.getItem("apiToken"));
  const navigate = useNavigate();

  const handleLogOut = async () => {
    const params = {
      headers: {
        needJson: 1,
        apiToken: apiToken,
      },
    };
    await axios
      .get(process.env.REACT_APP_HOST_URL + APP_LOGOUT, params)
      .then((response) => {
        if (response.data.success) {
          localStorage.removeItem("apiToken");
          localStorage.removeItem("userDetails");
          authCtx.authLogout();
          navigate("/");
        }
      });
  };
  return (
    <div className="aside-user">
      <div className="left">
        <div className="img">
          <img src={user} alt="user" />
        </div>
        <div className="text">
          <h3>{t("welcome text")} {userDet.fname}</h3>
          <span>{userDet.email}</span>
        </div>
      </div>
      <div className="icon" onClick={handleLogOut}>
        <PowerSettingsNewIcon />
      </div>
    </div>
  );
};
export default AsideUser;
