export const countries = [
    { label: "AFGHANISTAN", value: "1", code: "+93" },
    { label: "ALASKA (USA)", value: "2", code: "+1-907" },
    { label: "ALBANIA", value: "3", code: "+355" },
    { label: "ALGERIA", value: "4", code: "+213" },
    { label: "AMERICAN SAMOA", value: "5", code: "+1-684" },
    { label: "ANDORRA", value: "6", code: "+376" },
    { label: "ANGOLA", value: "7", code: "+244" },
    { label: "ANGUILLA", value: "8", code: "+1-264" },
    { label: "ANTIGUA & BARBUDA", value: "9", code: "+1-268" },
    { label: "ARGENTINA", value: "10", code: "+54" },
    { label: "ARMENIA", value: "11", code: "+374" },
    { label: "ARUBA", value: "12", code: "+297" },
    { label: "ASCENSION", value: "13", code: "+247" },
    { label: "AUSTRALIA", value: "14", code: "+61" },
    { label: "AUSTRIA", value: "15", code: "+43" },
    { label: "AZERBAIJAN", value: "16", code: "+994" },
    { label: "BAHAMAS", value: "17", code: "+1-242" },
    { label: "BAHRAIN", value: "18", code: "+973" },
    { label: "BANGLADESH", value: "19", code: "+880" },
    { label: "BARBADOS", value: "20", code: "+1-246" },
    { label: "BELARUS", value: "21", code: "+375" },
    { label: "BELGIUM", value: "22", code: "+32" },
    { label: "BELIZE", value: "23", code: "+501" },
    { label: "BENIN", value: "24", code: "+229" },
    { label: "BERMUDA", value: "25", code: "+1-441" },
    { label: "BHUTAN", value: "26", code: "+975" },
    { label: "BOLIVIA", value: "27", code: "+591" },
    { label: "BOSNIA / HERZEGOVINA", value: "28", code: "+387" },
    { label: "BOTSWANA", value: "29", code: "+267" },
    { label: "BRAZIL", value: "30", code: "+55" },
    { label: "BRITISH VIRGINISLANDS", value: "31", code: "+1-284" },
    { label: "BULGARIA", value: "32", code: "+359" },
    { label: "BURKINA FASO", value: "33", code: "+226" },
    { label: "BURUNDI", value: "34", code: "+257" },
    { label: "CAMBODIA", value: "35", code: "+855" },
    { label: "CAMEROON", value: "36", code: "+237" },
    { label: "CANADA", value: "37", code: "+1" },
    { label: "CAPE VERDE", value: "38", code: "+238" },
    { label: "CAYMAN ISLANDS", value: "39", code: "+1-345" },
    { label: "CENTRAL AFRICANREPUBLIC", value: "40", code: "+236" },
    { label: "CHAD", value: "41", code: "+235" },
    { label: "CHILE", value: "42", code: "+56" },
    { label: "CHINA", value: "43", code: "+86" },
    { label: "COLOMBIA", value: "44", code: "+57" },
    { label: "COMOROS", value: "45", code: "+269" },
    { label: "CONGO", value: "46", code: "+242" },
    { label: "CONGO DEM. REP.(ZAIRE)", value: "47", code: "+243" },
    { label: "COOK ISLAND", value: "48", code: "+682" },
    { label: "COSTA RICA", value: "49", code: "+506" },
    { label: "CROATIA", value: "50", code: "+385" },
    { label: "CUBA", value: "51", code: "+53" },
    { label: "BRUNEI", value: "52", code: "+673" },
    { label: "CYPRUS", value: "53", code: "+357" },
    { label: "CZECH REPUBLIC", value: "54", code: "+420" },
    { label: "DENMARK", value: "55", code: "+45" },
    { label: "DIEGO GARCIA", value: "56", code: "+246" },
    { label: "DJIBOUTI", value: "57", code: "+253" },
    { label: "DOMINICA", value: "58", code: "+1-767" },
    { label: "DOMINICAN REPUBLIC", value: "59", code: "1-809" },
    { label: "EAST TIMOR", value: "60", code: "+670" },
    { label: "ECUADOR", value: "61", code: "+593" },
    { label: "EGYPT", value: "62", code: "+20" },
    { label: "EL SALVADOR", value: "63", code: "+503" },
    { label: "EQUATORIAL GUINEA", value: "64", code: "+240" },
    { label: "ERITREA", value: "65", code: "+291" },
    { label: "ESTONIA", value: "66", code: "+372" },
    { label: "ETHIOPIA", value: "67", code: "+251" },
    { label: "FALKLAND ISLANDS", value: "68", code: "+630" },
    { label: "FAROE ISLANDS", value: "69", code: "+298" },
    { label: "FIJI", value: "70", code: "+679" },
    { label: "FINLAND", value: "71", code: "+358" },
    { label: "FRANCE", value: "72", code: "+33" },
    { label: "FRENCH GUIANA", value: "73", code: "+594" },
    { label: "FRENCH POLYNESIA", value: "74", code: "+689" },
    { label: "GABON", value: "75", code: "+241" },
    { label: "GAMBIA", value: "76", code: "+220" },
    { label: "GEORGIA", value: "77", code: "+995" },
    { label: "GERMANY", value: "78", code: "+49" },
    { label: "GHANA", value: "79", code: "+233" },
    { label: "GIBRALTAR", value: "80", code: "+381" },
    { label: "GREECE", value: "82", code: "+30" },
    { label: "GREENLAND", value: "83", code: "+299" },
    { label: "GRENADA", value: "84", code: "+1-473" },
    { label: "GUADALOUPE", value: "85", code: "+590" },
    { label: "GUAM", value: "86", code: "+1-671" },
    { label: "GUATEMALA", value: "87", code: "+882" },
    { label: "GUINEA", value: "88", code: "+224" },
    { label: "GUINEA BISSAU", value: "89", code: "+245" },
    { label: "GUYANA", value: "90", code: "+592" },
    { label: "HAITI", value: "91", code: "+929" },
    { label: "HAWAII (USA)", value: "92", code: "+1-808" },
    { label: "HONDURAS", value: "93", code: "+504" },
    { label: "HONG KONG", value: "94", code: "+852" },
    { label: "HUNGARY", value: "95", code: "+36" },
    { label: "ICELAND", value: "96", code: "+354" },
    { label: "INDIA", value: "97", code: "+91" },
    { label: "INDONESIA", value: "98", code: "+62" },
    { label: "IRAN", value: "99", code: "+964" },
    { label: "IRAQ", value: "100", code: "+353" },
    { label: "IRELAND", value: "101", code: "+385" },
    { label: "ISRAEL", value: "102", code: "+972" },
    { label: "ITALY", value: "103", code: "+39" },
    { label: "IVORY COAST", value: "104", code: "+225" },
    { label: "JAMAICA", value: "105", code: "+1-876" },
    { label: "JAPAN", value: "106", code: "+81" },
    { label: "JORDAN", value: "107", code: "+962" },
    { label: "KAZAKHSTAN", value: "108", code: "+7" },
    { label: "KENYA", value: "109", code: "+254" },
    { label: "KIRIBATI", value: "110", code: "+686" },
    { label: "KOREA (NORTH)", value: "111", code: "+8112" },
    { label: "KOREA SOUTH", value: "112", code: "+82" },
    { label: "KUWAIT", value: "113", code: "+965" },
    { label: "KYRGHYZSTAN", value: "114", code: "+996" },
    { label: "LAOS", value: "115", code: "+856" },
    { label: "LATVIA", value: "116", code: "+371" },
    { label: "LEBANON", value: "117", code: "+961" },
    { label: "LESOTHO", value: "118", code: "+266" },
    { label: "LIBERIA", value: "119", code: "+231" },
    { label: "LIBYA", value: "120", code: "+218" },
    { label: "LIECHTENSTEIN", value: "121", code: "+423" },
    { label: "LITHUANIA", value: "122", code: "+370" },
    { label: "LUXEMBOURG", value: "123", code: "+352" },
    { label: "MACAU", value: "124", code: "+853" },
    { label: "MACEDONIA", value: "125", code: "+389" },
    { label: "MADAGASCAR", value: "126", code: "+261" },
    { label: "MALAYSIA", value: "128", code: "+60" },
    { label: "MALDIVES", value: "129", code: "+960" },
    { label: "MALI", value: "130", code: "+223" },
    { label: "MALTA", value: "131", code: "+356" },
    { label: "MARIANA IS.(SAIPAN)", value: "132", code: "+1-670" },
    { label: "MARSHALL ISLANDS", value: "133", code: "+692" },
    { label: "MARTINIQUE(FRENCHANTILLES)", value: "134", code: "+596" },
    { label: "MAURITANIA", value: "135", code: "+222" },
    { label: "MAURITIUS", value: "136", code: "+230" },
    { label: "MAYOTTE", value: "137", code: "+269" },
    { label: "MEXICO", value: "138", code: "+52" },
    { label: "MICRONESIA", value: "139", code: "+691" },
    { label: "MOLDOVA", value: "140", code: "+373" },
    { label: "MONACO", value: "141", code: "+377" },
    { label: "MONGOLIA", value: "142", code: "+976" },
    { label: "MONTSERRAT", value: "143", code: "+1-664" },
    { label: "MOROCCO", value: "144", code: "+212" },
    { label: "MOZAMBIQUE", value: "145", code: "+258" },
    { label: "MYANMAR", value: "146", code: "+95" },
    { label: "NAMIBIA", value: "147", code: "+264" },
    { label: "NAURU", value: "148", code: "+674" },
    { label: "NEPAL", value: "149", code: "+977" },
    { label: "NETHERLANDS", value: "150", code: "+31" },
    { label: "NETHERLANDS ANTILLES", value: "151", code: "+599" },
    { label: "NEW CALEDONIA", value: "152", code: "+687" },
    { label: "NEW ZEALAND", value: "153", code: "+64" },
    { label: "NICARAGUA", value: "154", code: "+1555" },
    { label: "NIGER", value: "155", code: "+227" },
    { label: "NIGERIA", value: "156", code: "+234" },
    { label: "NIUE ISLAND", value: "157", code: "+683" },
    { label: "NORWAY", value: "158", code: "+47" },
    { label: "OMAN", value: "159", code: "+968" },
    { label: "PAKISTAN", value: "160", code: "+92" },
    { label: "PALAU", value: "161", code: "+680" },
    { label: "PALESTINE", value: "162", code: "+970" },
    { label: "PANAMA", value: "163", code: "+1647" },
    { label: "PAPUA NEW GUINEA", value: "164", code: "+675" },
    { label: "PARAGUAY", value: "165", code: "+595" },
    { label: "PERU", value: "166", code: "+51" },
    { label: "PHILIPPINES", value: "167", code: "+63" },
    { label: "POLAND", value: "168", code: "+48" },
    { label: "PORTUGAL", value: "169", code: "+351" },
    { label: "PUERTO RICO (I) (USA)", value: "170", code: "+1-787" },
    { label: "PUERTO RICO (II)(USA)", value: "171", code: "+1-939" },
    { label: "QATAR", value: "172", code: "+974" },
    { label: "REUNION", value: "173", code: "+262" },
    { label: "ROMANIA", value: "174", code: "+40" },
    { label: "RUSSIA", value: "175", code: "+7" },
    { label: "RWANDA", value: "176", code: "+250" },
    { label: "SAMOA WESTERN", value: "177", code: "+685" },
    { label: "SAN MARINO", value: "178", code: "+378" },
    { label: "SAO TOME &PRINCIPE", value: "179", code: "+239" },
    { label: "SAUDI ARABIA", value: "180", code: "+966" },
    { label: "SENEGAL", value: "181", code: "+221" },
    { label: "SEYCHELLES", value: "182", code: "+248" },
    { label: "SIERRA LEONE", value: "183", code: "+232" },
    { label: "SINGAPORE", value: "184", code: "+65" },
    { label: "SLOVAKIA", value: "185", code: "+421" },
    { label: "SLOVENIA", value: "186", code: "+386" },
    { label: "SOLOMON ISLANDS", value: "187", code: "+677" },
    { label: "SOMALIA", value: "188", code: "+252" },
    { label: "SOUTH AFRICA", value: "189", code: "+27" },
    { label: "SPAIN", value: "190", code: "+34" },
    { label: "SRI LANKA", value: "191", code: "+94" },
    { label: "ST HELENA", value: "192", code: "+290" },
    { label: "ST KITTS & NEVIS", value: "193", code: "+1-869" },
    { label: "ST LUCIA", value: "194", code: "+1-758" },
    { label: "ST VINCENT &GRENADINES", value: "195", code: "+1-784" },
    { label: "ST. PIERRE &MIQUELON", value: "196", code: "+508" },
    { label: "SUDAN", value: "197", code: "+249" },
    { label: "SURINAM", value: "198", code: "+597" },
    { label: "SWAZILAND", value: "199", code: "+268" },
    { label: "SWEDEN", value: "200", code: "+46" },
    { label: "SWITZERLAND", value: "201", code: "+41" },
    { label: "SYRIA", value: "202", code: "+963" },
    { label: "TAIWAN", value: "203", code: "+886" },
    { label: "TAJIKISTAN", value: "204", code: "+992" },
    { label: "TANZANIA", value: "205", code: "+255" },
    { label: "THAILAND", value: "206", code: "+66" },
    { label: "TOGO", value: "207", code: "+228" },
    { label: "TOKELAU", value: "208", code: "+690" },
    { label: "TONGA", value: "209", code: "+676" },
    { label: "TRINIDAD & TOBAGO", value: "210", code: "+1-868" },
    { label: "TUNISIA", value: "211", code: "+216" },
    { label: "TURKEY", value: "212", code: "+90" },
    { label: "TURKMENISTAN", value: "213", code: "+993" },
    { label: "TURKS & CAICOSISLANDS", value: "214", code: "+1-649" },
    { label: "TUVALU", value: "215", code: "+688" },
    { label: "UGANDA", value: "216", code: "+256" },
    { label: "UKRAINE", value: "217", code: "+380" },
    { label: "UNITED ARAB EMIRATES", value: "218", code: "+971" },
    { label: "UNITED KINGDOM", value: "219", code: "+44" },
    { label: "URUGUAY", value: "220", code: "+598" },
    { label: "UZBEKISTAN", value: "221", code: "+998" },
    { label: "VANUATU", value: "222", code: "+678" },
    { label: "VATICAN CITY", value: "223", code: "+39" },
    { label: "VENEZUELA", value: "224", code: "+58" },
    { label: "VIETNAM", value: "225", code: "+84" },
    { label: "VIRGIN ISLAND (USA)", value: "226", code: "+1-340" },
    { label: "WALLIS & FUTUNA", value: "227", code: "+681" },
    { label: "YEMEN", value: "228", code: "+967" },
    { label: "YUGOSLAVIA (SERBIA)", value: "229", code: "+381" },
    { label: "ZAMBIA", value: "230", code: "+260" },
    { label: "ZANZIBAR", value: "231", code: "+255" },
    { label: "ZIMBABWE", value: "232", code: "+263" },
  ];
  