import React from 'react';
import { Container } from '@mui/material';
import {Link} from 'react-router-dom';
import { Grid } from '@mui/material';
import redArrow from "../../../assets/Img/red-arrow.png";
import { MDA_LISTALL } from '../../../utils/api-constant';
import './ServiceSchemeMda.css';
import SchemeMdaBoxGrid from '../SchemeMdaBoxGrid/SchemeMdaBoxGrid';
import LoadingPage from '../../SeviceScreen/LoadingPage';
import useApiFetch from "../../../hooks/useApiFetch";
import { useTranslation } from 'react-i18next';
function ServiceSchemeMda() {
  const { t } = useTranslation();
  const { data, loading, error } = useApiFetch(
    process.env.REACT_APP_HOST_URL + MDA_LISTALL +'?limit=6'
  );
  if (loading) return <LoadingPage/>;
  const mda = data;
  
  return (
    <section className='Services-Scheme-Section'>
      
        <Container maxWidth="lg">
                <Grid container spacing={2}>
                        <Grid item xs={12} sm={12} md={8} lg={8}>
                            <div className="sec-head">
                               <h2>{t("recommended mdas title")}</h2>
                               <p>{t("mdas description")}</p>
                            </div>
                        </Grid>
                        <Grid item xs={12} sm={12} md={4} lg={4}>
                            <div className="arrowIcons">      
                              <div >                                
                                <Link to='/scheme-list' className='view-btn'><span>{t("view all mdas")}</span><img src={redArrow} alt="Proceed"/></Link>
                              </div>                                                        
                            </div>
                        </Grid>

                </Grid>
                <SchemeMdaBoxGrid  boxGridApi={mda} type="mda" detail={true}/>               
          </Container>
          <Container maxWidth="lg">

          </Container>       
      
    </section>
  );
}

export default ServiceSchemeMda;