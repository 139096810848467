import { Container } from "@mui/material";
import React from "react";
import "./PaymentSuccessFailed.css";
import { useTranslation } from "react-i18next";
import CancelIcon from '@mui/icons-material/Cancel';

const PaymentFailed = ({ data, handleclose }) => {
  console.log(data);
  const { t } = useTranslation();

  return (
    <>
      <div className="service-header">
        <Container>
          <div className="service-header-inner"></div>
        </Container>
      </div>
      <Container maxWidth="sm">
        <div className="service-page-detail success-section payFailed">
          <div className="content-wrapper payment-page">
            <div className="pay-head">
              <p>{t("failed alert")} {data.data.service_name}{t("declined")}</p>
            </div>
            <div className="pay-icon close-icon">
              {/* <img src={FailedIcon} alt="Success" className="success-gif" /> */}
              <CancelIcon style={{ fontSize: 45 }}/>
              <h2 className="failed-title">{t("payment failed")}</h2>
              <p>MWK {data.data.amount}</p>
            </div>
            <div className="payment-details failed">
              <p className="reason-txt">{t("reason")} :</p>
              <p> {data.data.status_msg}</p>
            </div>
            <button onClick={handleclose} className="print-button close">{t("close text")}</button>
          </div>
        </div>
      </Container>
    </>
  );
};

export default PaymentFailed;